
import { Component, Vue, Prop } from "vue-property-decorator"
import BoxIndicator from "@/components/BoxIndicator.vue";
import RootView from "./RootView";
import TagsElements from "@/components/TagsElements.vue";

@Component({
    components: {
        BoxIndicator,
        TagsElements
    }
})
export default class DeviceSummary extends RootView {
}
