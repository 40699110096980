
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

import DeviceModel from "@/models/DeviceModel";
import { SYSTEM_TAG_IGNORE_LICENSE } from "@/data";

import AdminListLayout from "@/components/AdminListLayout.vue";
import Checkbox from "@/components/Checkbox.vue";
import DeviceSummary from "@/components/DeviceSummary.vue";
import LastUpdateInfo from "@/components/LastUpdateInfo.vue";
import LicenseTag from "@/components/LicenseTag.vue";
import BackButton from "@/components/BackButton.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import AdminLicenseCommands from "@/components/AdminLicenseCommands.vue";
import OrganisationSearchDropdown from "@/components/OrganisationSearchDropdown.vue";

@Component({
    components: {
        AdminListLayout,
        Checkbox,
        DeviceSummary,
        LastUpdateInfo,
        LicenseTag,
        BackButton,
        SelectDropdown,
        AdminLicenseCommands,
        OrganisationSearchDropdown
    }
}) 
export default class AdminExpiredLicenseView extends RootView {
    checkboxAll = false;
    onDeviceCheckboxChanged(value: boolean) {
      this.$store.commit("admin_expired_licenses/setSearchedDevicesChecked", value);
    }

    onCheckboxChange() {
        this.$store.dispatch("admin_expired_licenses/load");
    }

    isIgnoredDevice(device: DeviceModel): boolean {
        return device.local != undefined && device.local.content.tags.length > 0 &&
            device.local.content.tags.some((value: string) => {
                if (value.includes(SYSTEM_TAG_IGNORE_LICENSE)) {
                    return true;
                }
                return false;
            })
    }
}
