
import {Vue, Component, Prop} from "vue-property-decorator";
import { Command, FileUpload, genUUID } from "@/Utils";
import SelectDropdown from "@/components/SelectDropdown.vue";

const selectionBin: Command = {
    id: "bin",
    name: "Binaries"
};
const selectionHex: Command = {
    id: "hex",
    name: "Hex"
};
const selectionElf: Command = {
    id: "elf",
    name: "Elf"
};

@Component({
    components: {
        SelectDropdown
    }
})
export default class FileUploader extends Vue {

    items: FileUpload[] = [];
    commands: Command[] = [];
    $refs!:{
        file: HTMLInputElement
    }

    mounted() {
        this.items = [];
        this.commands = [selectionBin, selectionElf, selectionHex];
    }

    clear() {
        this.items = [];
        this.$refs.file.value = "";
    }

    removeUpload(item: FileUpload) {
        this.items = this.items.filter((value: FileUpload, index: number): boolean => {
            if (value.id == item.id) {
                return false;
            }
            return true;
        })

        this.$refs.file.value = "";
        this.$emit("input", this.items);
    }

    typeExtension(fileName: string): Command {
        const extension = fileName.split('.').pop();
        switch(extension) {
            case "hex":
                return selectionHex;
            case "elf":
                return selectionElf;
            default:
                return selectionBin;    
        }
    }

    onFileChange(event: any) {
        if (this.$refs.file?.files != null && this.$refs.file.files.length > 0) {
            const id: string = genUUID();
            const type: Command = this.typeExtension(this.$refs.file!.files[0].name);
            this.items.push({
                id: id,
                type: type, 
                file: this.$refs.file?.files[0]
            });
            const ref: any = this.$refs;
            setTimeout(() => {
                ref[`dropdown_${id}`][0].forceValue(type);
            }, 100);
        }

        this.$emit("input", this.items);
    }
}
