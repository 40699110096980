
import { Component, Vue, Prop } from 'vue-property-decorator';
import { capitalize } from "@/Utils";
import RootView from './RootView';

@Component
export default class DataBox extends RootView {
    @Prop({default: ""}) icon!: string;
    @Prop({default: "1x"}) iconSize!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) boxClass!: string;
    @Prop({default: ""}) titleClass!: string;

    get nameVal(): string {
        return capitalize(this.name.toLowerCase().trim());
    }

    get boxStyle(): string {
        if (this.isMobile) {
            return "width: 128px; cursor: pointer";
        }

        console.log(this.isMobile);

        return "width: 196px; cursor: pointer";
    }
}

