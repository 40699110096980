
import { Vue, Prop, Component } from "vue-property-decorator"
import RootView from "@/components/RootView";

@Component({
    components: {
    }
})
export default class DeviceInfoLicenseSheet extends RootView {
    get calendar() {
        if (this.chooseDevice && this.chooseDevice.license && !this.hasLicenseExpired(this.chooseDevice.license.content)) {
            return "text-success";
        }

        if (this.chooseDevice && this.chooseDevice.licenserequest && !this.hasLicenseExpired(this.chooseDevice.licenserequest.content)) {
            return "text-warning";
        }

        return "text-danger";
    }
}
