
import { Vue, Component, Prop } from "vue-property-decorator";
import PopupModal from "@/components/PopupModal.vue";
import RootView from "@/components/RootView";
import { SimplifiedDevice } from "@/models/DeviceModel";
import { ADMIN_OP_SYNC } from "@/data";

@Component({
    components: {
        PopupModal
    }
})
export default class AdminOperationSuccessModalView extends RootView {

    adminOp = "";
    ids: SimplifiedDevice[] = [];
    idTypes = "";

    show(data: any) {
        this.ids = [];
        let status = false;
        let hasFailure = false;
        let hasSuccess = false;
        let reason: string | undefined = undefined;

        for (let i = 0; i < data.length; i++) {
            reason = undefined;
            if (data[i].success == undefined || (data[i].success != undefined && data[i].success)) {
                status = true;
                hasSuccess = true;
            } else {
                status = false;
                hasFailure = true;
                switch (this.adminOp) {
                    case ADMIN_OP_SYNC:
                        reason = "Not found";
                        break;
                    default:
                        break;
                }
            }

            this.ids.push({
                id: data[i].device_id,
                sn: data[i].serialnumber || "",
                success: status,
                reason: reason
            });
        }

        if (hasFailure && hasSuccess) {
            this.idTypes = "both";
        } else if (hasSuccess) {
            this.idTypes = "success";
        } else {
            this.idTypes = "failed";
        }
    }
}

