
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import Dropdown from "@/components/Dropdown.vue";

@Component({
    components: {
        Dropdown
    }
})
export default class DeviceOptionsDropdown extends RootView {}
