
import { Component, Vue } from 'vue-property-decorator';

import LanguageChooser from '@/components/LanguageChooser.vue';
import PopupModal from '@/components/PopupModal.vue';
import LoginBox from '@/components/LoginBox.vue';
import BoxInfo from '@/components/BoxInfo.vue';
import PasswordField from '@/components/PasswordField.vue';

import { AuthRequest, AuthRequestStatus } from "@/store/modules/AuthModule";
import { capitalize } from '@/Utils';
import RootView from '@/components/RootView';

@Component({
    components: {
        LanguageChooser,
        PopupModal,
        LoginBox,
        BoxInfo,
        PasswordField
    },
})
export default class LoginView extends RootView {

    STATE_LOGIN = "login";
    STATE_NEW_PW = "new_pw";
    STATE_FORGOT = "forgot";
    STATE_BACK = "back";

    loading = false;
    showLoginError = false;
    showConfirmPasswordError = false;
    showForgotPasswordError = false;
    email = "";
    password = "";
    state = this.STATE_LOGIN;
    newPassword = "";
    newPasswordConfirm = "";
    forgotPasswordEmail = "";
    renderComponent = true;
    rememberMe = false;
    oldRememberMe = "";
    invite = false;

    mounted() {
        this.defaultState();

        const rememberedEmail = localStorage.getItem("EMFCONNECT_LOGIN_REMEMBER_ME");
        if (rememberedEmail) {
            this.oldRememberMe = rememberedEmail;
            this.email = rememberedEmail;
            this.rememberMe = true;
        }

        this.invite = this.$store.state.invite.hasInvitation;
        if (this.invite) {
            this.email = this.$store.state.invite.email;
        }
    }

    async submitLogin() {

        this.loading = true;

        if (this.rememberMe && this.oldRememberMe != this.email) {
            localStorage.setItem("EMFCONNECT_LOGIN_REMEMBER_ME", this.email);
        }

        if (this.oldRememberMe.trim().length > 0 && !this.rememberMe) {
            localStorage.removeItem("EMFCONNECT_LOGIN_REMEMBER_ME");
        }

        let response: AuthRequest = await this.$store.dispatch("auth/login", {
            username: this.email,
            password: this.password
        })

        this.loading = false;
        
        switch(response.status) {
            case AuthRequestStatus.FAILURE:
                this.showLoginError = true;
            break;
            case AuthRequestStatus.NEW_PASSWORD:
                this.showLoginError = false;
                this.showConfirmPasswordError = false;
                this.state = this.STATE_NEW_PW;
                this.newPassword = "";
                this.newPasswordConfirm = "";
            break;
            case AuthRequestStatus.SUCCESS:
            default:
                this.goto({ path: '/dashboard'});
            break;
        }
    }

    cancelForgotPassword() {
        this.showForgotPasswordError = false;
        this.state = this.STATE_LOGIN;
    }

    async forgotPassword() {
        this.state = this.STATE_FORGOT;
        this.forgotPasswordEmail = "";
        this.showForgotPasswordError = false;
        this.showConfirmPasswordError = false;
        this.showLoginError = false;
    }

    async confirmEmailForgotPassword() {
        if (this.forgotPasswordEmail.length <= 0) {
            this.showForgotPasswordError = true;
            return
        }

        this.showForgotPasswordError = false;

        this.loading = true;
        await this.$store.dispatch("auth/forgotPassword", {
            username: this.forgotPasswordEmail
        }).then(() => {
            this.state = this.STATE_BACK;
        }).catch(() => {
            this.showForgotPasswordError = true;
        })
        this.loading = false;
    }

    defaultState() {
        this.showLoginError = false;
        this.showConfirmPasswordError = false;
        this.showForgotPasswordError = false;
        this.email = "";
        this.password = "";
        this.state = this.STATE_LOGIN;
        this.newPassword = "";
        this.newPasswordConfirm = "";
        this.renderComponent = true;
    }

    async submitNewPassword() {
        if (this.newPassword != this.newPasswordConfirm) {
            this.showConfirmPasswordError = true;
            return;
        }

        await this.$store.dispatch("auth/setNewPassword", {
            username: this.email,
            password: this.newPassword
        }).then((data: boolean) => {
            if (data) {
                this.goAtBase();
            }            
        }) 
    }

}
