import { VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import { AxiosResponse } from "axios";
import { network } from "@/network";
import { adminModule, authModule, organisationUsersModule, userModule, adminExpiredLicensesModule } from "..";
import { QUERY_PARAM_ORG, QUERY_PARAM_TAGS, QUERY_PARAM_TREATMENT, genUUID, hasProperty, toArray, wrapSearchItems } from "@/Utils";

import {
    QUERY_PARAM_LICENSE_EXPIRED, QUERY_PARAM_TRANSDUCER_DISCONNECTED, QUERY_PARAM_FIRMWARE, 
    QUERY_PARAM_LABEL, QUERY_PARAM_SN, QUERY_PARAM_IMEI, 
    QUERY_PARAM_LAST_UPDATE_TYPE
  } from "@/Utils";

import DeviceModel from "@/models/DeviceModel";
import {SearchItemModel} from '@/models/SearchItemModel';
import OrganisationModel from "@/models/OrganisationModel";
import FirmwareModel from "@/models/FirmwareModel";
import { searchDevices } from '@/search/AdminDeviceSearch';
import { DeviceSelected, DeviceUpdateContent } from "@/models/DeviceSelection";
import JobModel from "@/models/JobModel";

const trimOutDeviceSelected = (selected: DeviceSelected[]): {org_id: string, device_id: string}[] => {
    const results: {org_id: string, device_id: string}[] = [];
    for (let i = 0; i < selected.length; i++) {
        results.push({
            org_id: selected[i].org_id,
            device_id: selected[i].device_id,
        });
    }
    return results;
}

const requestDeviceUpdate = (payload: {options: any, action: string, selected: DeviceSelected[], handle: (device: DeviceModel) => void}): Promise<any> => {
    return network().put("/op/admin/devices", JSON.stringify({
        options: payload.options,
        action: payload.action,
        selected: trimOutDeviceSelected(payload.selected)
    })).then(() => {
        const devices: DeviceSelected[] = payload.selected;

        for (let i = 0; i < devices.length; i++) {
            payload.handle(adminModule.idDevices.get(devices[i].device_id)!)
        }

        return true;
    })
}

@Module
export default class AdminModule extends VuexModule {

    organisation: OrganisationModel | undefined;
    organisations: OrganisationModel[] = [];
    
    firmwares: FirmwareModel[] = [];
    idOrganisations: Map<string, OrganisationModel> = new Map();

    devices: DeviceModel[] = [];
    idDevices: Map<string, DeviceModel> = new Map();
    searchedDevices: SearchItemModel<DeviceModel>[] = [];
    
    leftOrgHash = genUUID();
    searchedLicenseExpiredOnly = false;
    searchedTransducerDisconnectedOnly = false;
    searchedLastUpdateType?: any;
    searchedTreatmentType?: any;
    searchedDeviceName = "";
    searchedDeviceSN = "";
    searchedDeviceIMEI = "";
    searchedDeviceFW = "";
    searchedDeviceTags: string[] = [];
    searchedOrganisation?: OrganisationModel;

    @Mutation
    clear() {
        this.organisation = undefined;
        this.organisations = [];
        this.firmwares = [];
        this.idOrganisations.clear();
        this.devices = [];
        this.idDevices.clear();
        this.searchedLicenseExpiredOnly = false;
        this.searchedTransducerDisconnectedOnly = false;
        this.searchedLastUpdateType = undefined;
        this.searchedTreatmentType = undefined;
        this.searchedDeviceName = "";
        this.searchedDeviceIMEI = "";
        this.searchedDeviceFW = "";
        this.searchedDeviceTags = [];
        this.searchedOrganisation = undefined;
    }
    
    @Mutation 
    setQuery(baseQuery: any) {

        const query = JSON.parse(JSON.stringify(baseQuery));

        if (hasProperty(query, QUERY_PARAM_SN)) {
            this.searchedDeviceSN = query[QUERY_PARAM_SN].trim();
        }

        if (hasProperty(query, QUERY_PARAM_IMEI)) {
            this.searchedDeviceIMEI = query[QUERY_PARAM_IMEI].trim();
        }

        if (hasProperty(query, QUERY_PARAM_FIRMWARE)) {
            this.searchedDeviceFW = query[QUERY_PARAM_FIRMWARE].trim();
        }

        if (hasProperty(query, QUERY_PARAM_LABEL)) {
            this.searchedDeviceName = query[QUERY_PARAM_LABEL].trim();
        }

        if (hasProperty(query, QUERY_PARAM_LAST_UPDATE_TYPE)) {
            this.searchedLastUpdateType = query[QUERY_PARAM_LAST_UPDATE_TYPE].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TREATMENT)) {
            this.searchedTreatmentType = query[QUERY_PARAM_TREATMENT].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TRANSDUCER_DISCONNECTED)) {
            this.searchedTransducerDisconnectedOnly = query[QUERY_PARAM_TRANSDUCER_DISCONNECTED];
        }

        if (hasProperty(query, QUERY_PARAM_LICENSE_EXPIRED)) {
            this.searchedLicenseExpiredOnly = query[QUERY_PARAM_LICENSE_EXPIRED];
        }
        
        if (hasProperty(query, QUERY_PARAM_TAGS)) {
            this.searchedDeviceTags = query[QUERY_PARAM_TAGS].split(",");
        }

        if (hasProperty(query, QUERY_PARAM_ORG)) {
            this.searchedOrganisation = this.organisations!.find((value) => {
                return value.org_id == query[QUERY_PARAM_ORG]
            });
        }
    }

    @Mutation
    setDevices(devices: DeviceModel[]) {
        this.devices = devices;

        for (let i = 0; i < devices.length; i++) {
            this.idDevices.set(devices[i].device_id, devices[i]);
        }
    }

    @Mutation
    sortDeviceByLastUpdate(data: {direction: string}) {
        this.searchedDevices.sort((a: SearchItemModel<DeviceModel>, b: SearchItemModel<DeviceModel>) => {
            if (a.model.ts == b.model.ts) {
                return 0;
            }

            if (a.model.ts > b.model.ts) {
                return data.direction == "up" ? -1: 1;
            } else {
                return data.direction == "up" ? 1: -1;
            }
        })
    }

    @Mutation
    setSearchedDevices(devices: DeviceModel[]) {
        this.searchedDevices = wrapSearchItems(devices);
    }

    @Mutation 
    setFirmwares(firmwares: FirmwareModel[]) {
        this.firmwares = firmwares;
    }

    @Mutation
    setSearchedDeviceTags(tags: string[]) {
        this.searchedDeviceTags = tags;
    }

    @Mutation
    setOrganisations(organisations: OrganisationModel[]) {
        this.organisations = organisations;
        for (let i = 0; i < organisations.length; i++) {
            this.idOrganisations.set(organisations[i].org_id, organisations[i]);
        }
    }

    @Mutation
    setSearchedDevicesChecked(value: boolean) {
        for (let i = 0; i < this.searchedDevices.length; i++) {
            this.searchedDevices[i].checked = value
        }
    }

    @Mutation
    setSearchedOrganisations(organisation: OrganisationModel) {
        this.searchedOrganisation = organisation;
    }

    @Mutation
    setSearchedLastUpdateTypes(updateType: any) {
        this.searchedLastUpdateType = updateType;
    }

    @Mutation
    setSearchedTreatmentType(treatmentType: any) {
        this.searchedTreatmentType = treatmentType;
    }

    @Action
    searchDevices() {
        this.setSearchedDevices(
                searchDevices(
                    this.devices, {
                        label: this.searchedDeviceName,
                        sn: this.searchedDeviceSN,
                        imei: this.searchedDeviceIMEI,
                        tags: this.searchedDeviceTags,
                        orgs: this.searchedOrganisation?.org_id ?? "ALL",
                        transducer_disconnected: this.searchedTransducerDisconnectedOnly,
                        license_expired: this.searchedLicenseExpiredOnly,
                        last_update_type: this.searchedLastUpdateType?.value ?? "ALL",
                        fw: this.searchedDeviceFW,
                        treatment_type: this.searchedTreatmentType?.value ?? "ALL",
                        ignore: []
                    }
                )
        );
    }

    @Action
    onPageRefresh() {
        if (
            this.searchedDeviceName.trim() != "" ||
            this.searchedDeviceSN.trim() != "" ||
            this.searchedDeviceIMEI.trim() != "" ||
            this.searchedDeviceTags.length > 0 ||
            this.searchedDeviceFW.trim() != "" ||
            this.searchedLastUpdateType != null ||
            this.searchedTreatmentType != null ||
            this.searchedOrganisation != null ||
            this.searchedTransducerDisconnectedOnly ||
            this.searchedLicenseExpiredOnly 
        ) {
            this.setSearchedDevices(
                    searchDevices(
                        this.devices, {
                            label: this.searchedDeviceName,
                            sn: this.searchedDeviceSN,
                            imei: this.searchedDeviceIMEI,
                            tags: this.searchedDeviceTags,
                            orgs: this.searchedOrganisation?.org_id ?? "ALL",
                            transducer_disconnected: this.searchedTransducerDisconnectedOnly,
                            license_expired: this.searchedLicenseExpiredOnly,
                            last_update_type: this.searchedLastUpdateType?.value ?? "ALL",
                            fw: this.searchedDeviceFW,
                            treatment_type: this.searchedTreatmentType?.value ?? "ALL",
                            ignore: []
                        }
                    )
            );
        }
    }

    @Action
    updateLicenses(payload: {selected: {device_id: string, id: string, expiry: number}[]}) {
        return network().post("/op/admin/licenses", JSON.stringify({selected: payload.selected}));
    }

    @Action
    async fetchFirmwares() {
        let raws:any =  JSON.parse((await network().get("/d/admin/firmwares")).data).body;
        
        if (raws) {
            if (!Array.isArray(raws)) {
                raws = [raws];
            }
        } else {
            raws = [];
        }

        this.setFirmwares(raws);
    }

    @Action
    sync(payload: {selected: DeviceSelected[]}) {
        const deviceIds: string[] = [];

        for (let i = 0; i < payload.selected.length; i++) {
            deviceIds.push(payload.selected[i].device_id);
        }

        if (deviceIds.length > 0) {
            return network().post("/op/admin/devices/sync", JSON.stringify({
                devices: deviceIds
            }));
        }
        return;
    }

    @Action
    async fetchDevices() {
        let raws: any[] = JSON.parse((await network().get("/d/admin/devices")).data).body;
        const devices: DeviceModel[] = [];

        if (raws) {
            if (!Array.isArray(raws)) {
                raws = [raws];
            }
        } else {
            raws = [];
        }

        for (let i = 0; i < raws.length; i++) {
            const raw: any = raws[i];
            let tagValue = [];

            if (raw.local && raw.local.content.tags.trim() != "") {
                tagValue  = toArray(raw.local.content.tags.trim().split(","));
            }

            const deviceId: string =  raw.pk.replace("DEV#", "");

            const newDevice = {
                org_id: raw.sk.replace("ORG#", ""),
                device_id: deviceId,
                ts: raw.ts,
                src: raw.src ?? "UNKNOWN",
                info: raw.info,
                sensors: raw.sensors,
                roam: raw.roam,
                transducer: raw.transducer,
                license: raw.license,
                position: raw.position,
                local: raw.local ? {
                    content: {
                        label: raw.local.content.label?.trim() ?? "",
                        tags: tagValue,
                        storage: raw.local.content.storage
                    },
                    timestamp: raw.local.timestamp
                } : undefined,
                userconnection: raw.userconnection,
                serverconnection: raw.serverconnection,
                configuration: raw.configuration,
                desiredconfiguration: raw.desiredconfiguration,
                job: raw.job,
                licenserequest: raw.licenserequest
            };
            devices.push(newDevice as DeviceModel);
        }

        this.setDevices(devices);
        this.setSearchedDevices(devices);
        this.sortDeviceByLastUpdate({direction: "up"});
    }

    @Action
    async fetchOrganisations() {
        let raws: any[] = JSON.parse((await network().get("/d/admin/organisations")).data).body;
        const organisations: OrganisationModel[] = [];

        if (raws) {
            if (!Array.isArray(raws)) {
                raws = [raws];
            }
        } else {
            raws = [];
        }
        
        for (let i = 0; i < raws.length; i++) {
            const raw: any = raws[i];
            organisations.push({
                org_id: raw.pk.replace("ORG#", ""),
                name: raw.name,
                email: raw.email,
                phone: raw.phone,
                website: raw.website,
                address: raw.address
            });
        }

        this.setOrganisations(organisations);
    }

    @Action
    assignOrg(payload: DeviceUpdateContent<{org_id: string}>) {
        return requestDeviceUpdate({
            action: "assign",
            options: {
                interaction: true,
                org_id: payload.options!.org_id
            },
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                device.org_id = payload.options!.org_id;
            }
        });
    }

    @Action
    createEmptyDevices(data: {id: string, sn: string, label?: string}[]) {
        return network().post("/op/admin/devices", JSON.stringify({
            ids: data
        })).then((response: AxiosResponse) => {
            return JSON.parse(response.data);
        });
    }

    @Action
    createOrganisation(organisation: OrganisationModel) {
        return network().post("/op/admin/organisations", JSON.stringify({
            "name": organisation.name,
            "address": organisation.address,
            "email": organisation.email,
            "website": organisation.website,
            "phone": organisation.phone
        }));
    }

    @Action
    setStorage(payload: DeviceUpdateContent<{interaction: boolean}>) {
        return requestDeviceUpdate({
            action: "storage",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const interaction: boolean = payload.options!.interaction;
                if (!device.local) {
                    device.local = {
                        content: {
                            label: "",
                            tags: [],
                            storage: interaction,
                        },
                        timestamp: Date.now()
                    };
                }

                device.local!.content.storage = interaction;
                device.local!.timestamp = Date.now();
            }
        });
    }

    @Mutation 
    changeOrgStatus() {
        this.leftOrgHash = genUUID();
    }

    @Action
    async joinOrg(organisation_id: string) {
        await network().post(`/op/admin/organisations/${organisation_id}/self`);
        const usr_id = authModule.user!.id!;
        if (!userModule.user) {
            await userModule.getUser();
        }
        organisationUsersModule.addUser({usr_id: usr_id, org_id: organisation_id, role: "superadmin", info: userModule.user!});
        await authModule.updateToken();
        this.changeOrgStatus();
        return true;
    }

    @Action
    async leaveOrg(organisation_id: string) {
        await network().delete(`/op/admin/organisations/${organisation_id}/self`);
        const usr_id = authModule.user!.id!;
        organisationUsersModule.removeUser(usr_id);
        await authModule.updateToken();
        this.changeOrgStatus();
        return true; 
    }

    @Action
    setTags(payload: DeviceUpdateContent<{interaction: boolean, tags: string[]}>) {
        return requestDeviceUpdate({
            action: "tags",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const interaction: boolean = payload.options!.interaction;
                const tagTransactions: string[] = payload.options!.tags;

                if (!device.local) {
                    device.local = {
                        content: {
                            label: "",
                            tags: [],
                            storage: false,
                        },
                        timestamp: Date.now()
                    };
                }

                const tags: Set<string> = new Set(device.local!.content.tags);
                for (let i = 0; i < tagTransactions.length; i++) {
                    if (interaction) {
                        tags.add(tagTransactions[i]);
                    } else {
                        tags.delete(tagTransactions[i]);
                    }
                }

                device.local!.content.tags = Array.from(tags);
            }
        })
    }

    @Action
    cancelJob(payload: JobModel) {
        return network().put(`/op/admin/devices/${payload.device_id}/jobs/${payload.iot_ref}`);
    }

    @Action
    disable(payload: DeviceUpdateContent<unknown>) {
        return requestDeviceUpdate({
            action: "disable",
            options: {},
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                device.org_id = "";
            }
        })
    }

    @Action
    updateFirmware(payload: DeviceUpdateContent<{firmware: FirmwareModel}>) {
        return requestDeviceUpdate({
            action: "firmware",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                // todo
            }
        })
    }

    @Action
    generateLicense(payload: {name: string, date: string}): Promise<string> {
        const name = payload.name;
        const date = new Date(payload.date).getTime();
        return network().post("op/admin/licenses/gen", JSON.stringify({name: name, date: date})).then((data) => {
            return JSON.parse(data.data).result;
        });
    }


}