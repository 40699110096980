import { render, staticRenderFns } from "./Searchbar.vue?vue&type=template&id=74f04696&"
import script from "./Searchbar.vue?vue&type=script&lang=ts&"
export * from "./Searchbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports