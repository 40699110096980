
import { Vue, Prop, Component } from "vue-property-decorator";
import OrganisationModel from "@/models/OrganisationModel";
import DataBox from "@/components/DataBox.vue";
import RootView from "@/components/RootView";

@Component({
    components: {
        DataBox
    }
})
export default class OrganisationBox extends RootView {
    @Prop({default: undefined}) model!: OrganisationModel;
    @Prop({default: ""}) role!: string;

    goToOrgPage() {
        this.goto({ path: `/organisation/${this.model.org_id}`});
    }

}
