
import { Component } from "vue-property-decorator";
import { MODAL_EDIT_ORGANISATION } from "@/data";

import DialogPopupModal from "@/components/DialogPopupModal.vue";
import EditInfo from "@/components/EditInfo.vue";
import OrganisationModel from "@/models/OrganisationModel";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        EditInfo
    }
})
export default class EditOrganisationModalView extends RootModal {
    name = "";
    phone = "";
    email = "";
    address = "";
    website = "";

    override setup(): ModalSetup {
        return {
            name: MODAL_EDIT_ORGANISATION
        };
    }

    reset() {
        this.name = (this.$store.state.organisations.organisation.name ?? "");
        this.phone = (this.$store.state.organisations.organisation.phone ?? "");
        this.email = (this.$store.state.organisations.organisation.email ?? "");
        this.address = (this.$store.state.organisations.organisation.address ?? "");
        this.website = (this.$store.state.organisations.organisation.website ?? "");

        this.$refs.editInfoName.stop();
        this.$refs.editInfoPhone.stop();
        this.$refs.editInfoEmail.stop();
        this.$refs.editInfoAddress.stop();
        this.$refs.editInfoWebsite.stop();
    }

    override process(): Promise<any> {
        const organisation: OrganisationModel = {
            org_id: this.$store.state.organisations.organisation.org_id,
            name: this.name,
            phone: this.phone,
            email: this.email,
            website: this.website,
            address: this.address
        };

        return this.$store.dispatch("organisations/updateOrganisation", {
            organisation: organisation
        });
    }
}
