
import {Vue, Prop, Component} from "vue-property-decorator";
import RootView from "@/components/RootView";
import AccessForbiddenView from "@/views/AccessForbiddenView.vue";

@Component({
    components: {
        AccessForbiddenView
    }
})
export default class ClassicMainContainerView extends RootView {
    @Prop({default: null}) auth?: (() => boolean) | null;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) barCss!: string;

    get authCheck(): boolean {
        if (this.auth == null) return true;
        return this.auth();
    }
}

