
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PlusButton extends Vue {
    onClick() {
        this.$emit("click");
    }
}
