
import { Vue, Prop, Component } from "vue-property-decorator";
import { Command, selection } from "@/Utils";
import { toast, ToastType, modal } from "@/Toast";
import { MODAL_ADMIN_IGNORE_LIC, MODAL_ADMIN_UPDATE_LICENSE, MODAL_ADMIN_REINSTATE_LIC } from "@/data";

import DeviceModel from "@/models/DeviceModel";

import Dropdown from "@/components/Dropdown.vue";
import RootView from "@/components/RootView";

@Component({
    components: {
        Dropdown
    }
})
export default class AdminLicenseCommands extends RootView {
    commands: Command[] = [];

    beforeMount() {
      this.commands = [{
          id: "ignore",
          name: "Ignore"
      },
      {
        id: "license",
        name: "Update license"
      }, 
      {
        id: 'reinstate',
        name: 'Reinstate'
      }];
    }

    onCommandClicked(item: Command) {
        const checkedDevices = selection<DeviceModel>(this.$store.state.admin_expired_licenses.searchedDevices);

        if (checkedDevices.length <= 0) {
            toast({
                message: this.$t("toast_admin_missing_selection").toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(item.id) {
            case "ignore":
                modal(MODAL_ADMIN_IGNORE_LIC);
                break;
            case "reinstate":
                modal(MODAL_ADMIN_REINSTATE_LIC);
                break;
            case "license": 
                modal(MODAL_ADMIN_UPDATE_LICENSE);
                break;
            default:
                break;
        }
    }
}
