
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_ADMIN_STORAGE } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
  components: {
    DialogPopupModal
  }
})
export default class AdminSetStorageModeModalView extends RootModal {

    storageSwitch = true;

    override setup(): ModalSetup {
      return {
        name: MODAL_ADMIN_STORAGE
      }
    }

    onOpen() {
      this.storageSwitch = true;
    }

    override process(): Promise<any> {
      return this.$store.dispatch("admin/setStorage", {options: {interaction: this.storageSwitch}, selected: this.selectedDevices });
    }
}
