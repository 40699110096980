
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class Dropdown extends Vue {
    @Prop({default: ""}) text?: string;
    @Prop({default: () => { return []; }}) items?: any[];
    @Prop({default: ""}) refKey?: string;
    @Prop({default: ""}) refValue?: string
    @Prop({default: "btn-outline-primary"}) bg?: string;
    @Prop({default: ""}) btnStyle?: string;
    @Prop({default: ""}) direction?: string;
    @Prop({default: ""}) id!: string;

    onclick(item: any) {
        this.$emit('click', item);
    }

    optionId(item: any) {
        return `dropdown-options-${item[this.refKey!]}`
            .replaceAll("_", "-")
            .toLowerCase()
            .trim();
    }

    get dropdownId() {
        if (this.id.length > 0) {
            return `dropdown-menu-${this.id.toLowerCase().trim()}`;
        }
        return `dropdown-menu`;
    }
    
    get idVal() {
        if (this.id.length > 0) {
            return `btn-dropdown-${this.id.toLowerCase().trim()}`;
        }
        return `btn-dropdown-menu`
    }

}
