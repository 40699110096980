
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class MissingData extends Vue {
    @Prop({default: ""}) text!: string;
    @Prop({default: 196}) size!: number;

    get imgStyle() {
        return `max-width: ${this.size}px; height: auto;`;
    }
}
