
import { Component } from "vue-property-decorator";
import ErrorList from "@/components/ErrorList.vue";
import PasswordField from "@/components/PasswordField.vue";
import { validatePassword } from "@/Utils";
import { MODAL_EDIT_PASSWORD } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";
import DialogPopupModal from "@/components/DialogPopupModal.vue";

@Component({
    components: {
        ErrorList,
        PasswordField,
        DialogPopupModal
    }
})
export default class EditPasswordModalView extends RootModal {

    errors: string[] = [];
    changePasswordOldPassword = "";
    changePasswordNewPassword = "";
    changePasswordConfirmPassword = "";

    override setup(): ModalSetup {
        return {
            name: MODAL_EDIT_PASSWORD
        }
    }

    reset() {
        this.errors = [];
        this.changePasswordOldPassword = "";
        this.changePasswordNewPassword = "";
        this.changePasswordConfirmPassword = "";

        this.$refs.oldPw.hide();
        this.$refs.newPw.hide();
        this.$refs.confirmNewPw.hide();
    }

    override validations(): Promise<boolean> {
        this.errors = [];
        let errors: string[] = validatePassword(this.changePasswordNewPassword, this.changePasswordConfirmPassword);
        if (errors.length > 0) {
            this.errors = errors;
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    override async process(): Promise<any> {
        try {
            await this.$store.dispatch("user/changePassword", {
                old_password: this.changePasswordOldPassword,
                new_password: this.changePasswordNewPassword
            })
            return Promise.resolve(true);
        } catch (error) {
            this.errors.push("Old password does not match");
            return Promise.resolve(false);
        }
    }
}
