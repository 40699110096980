

import { Component, Watch } from 'vue-property-decorator';
import { UserRole } from "@/store/modules/AuthModule";
import { MODAL_EDIT_ORGANISATION, MODAL_INVITE_ORGANISATION, 
    MODAL_KICK_ORGANISATION_USER, MODAL_EDIT_ORGANISATION_USER } from '@/data';
import UserOrganisationModel from "@/models/UserOrganisationModel";
import { Command, RoleOption, selection } from '@/Utils';
import { modal, ToastType, toast } from '@/Toast';

import BackButton from "@/components/BackButton.vue";
import RootView from '@/components/RootView';
import SettingsDropdown from '@/components/SettingsDropdown.vue';
import SuperAdminButton from '@/components/SuperAdminButton.vue';
import SummaryCard from '@/components/SummaryCard.vue';
import SummaryDetailsLayout from "@/components/SummaryDetailsLayout.vue";
import Searchbar from "@/components/Searchbar.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import Dropdown from "@/components/Dropdown.vue";
import DetailsCard from "@/components/DetailsCard.vue";
import Checkbox from "@/components/Checkbox.vue";
import PlusButton from "@/components/PlusButton.vue";

import ClassicMainContainerView from "@/views/ClassicMainContainerView.vue";

@Component({
    components: {
        ClassicMainContainerView,
        BackButton,
        SettingsDropdown,
        SuperAdminButton,
        SummaryCard,
        SummaryDetailsLayout,
        Searchbar,
        SelectDropdown,
        Dropdown,
        DetailsCard,
        Checkbox,
        PlusButton
    }
})
export default class OrganisationDetailView extends RootView {
    role: UserRole = UserRole.GUEST;
    UserRole = UserRole
    orgId = "";
    checkboxAll = false;

    adminCommands: Command[] = [
        {
            name: this.$t("organisation_command_edit").toString(),
            id: "edit"
        }
    ];
    
    commands: Command[] = [
        {
          id: "edit",
          name: this.$t("organisation_command_edit").toString()
        },
        {
            id: "remove",
            name: this.$t("organisation_command_remove").toString()
        }
    ];

    get organisationBoxStyle(): string {
        if (this.isMobile) {
            return "height: 480px;"
        }
        return "height: 100%";
    }

    get classDisplay(): string {
        if (this.isMobile) {
            return "d-inline-block";
        }
        return "";
    }

    get plusButtonStyle(): string {
        if (this.isMobile) {
            return "z-index: 1000; right: 12px; bottom: 12px;";
        }
        return "z-index: 1000; right: 24px; bottom: 12px;";
    }

    async beforeMount() {
        this.orgId = this.$route.params.id;
        this.role = (this.$store.state as any).auth.user.memberships.get(this.orgId);
    }

    authPageCondition(): boolean {
        return this.$store.state.auth.user.memberships.has(this.$route.params.id) 
             || this.$store.state.auth.user.internals.includes("ADMIN");
    }

    @Watch("$store.state.admin.leftOrgHash")
    onValueChange() {
        this.role = (this.$store.state as any).auth.user.memberships.get(this.orgId);
    }

    onRoleChange(role: RoleOption | undefined) {
        let searchRole = "";

        if (role != undefined) {
            this.selectRole = role.id as UserRole;
            searchRole = role.id;
        } 

        this.$store.commit("organisation_users/setSearchRole", searchRole);
        this.onSearch();
    }

    onRoleClear () {
        this.$store.commit("organisation_users/setSearchRole", "");
        this.onSearch();
    }

    openInvite() {
        modal(MODAL_INVITE_ORGANISATION);
    }

    onSearch() {
        this.$store.dispatch("organisation_users/search");
    }

    onCheckboxChanged(value: boolean) {
        this.$store.commit("organisation_users/setSearchedChecked", value);
    }

    onAdminCommandClicked(command: Command) {
        switch(command.id) {
            case "edit": 
                modal(MODAL_EDIT_ORGANISATION);
                break;
            default:
                break;
        }
    }

    hasPriviledgeToEditOrganisation(org_id: string) : boolean {
        if (!this.$store.state.auth.user) return false;
        const role: UserRole =  this.$store.state.auth.user.memberships.get(org_id);
        return role == UserRole.ADMIN || role == UserRole.OWNER || this.$store.state.auth.user.internals.includes("ADMIN");
    }

    onCommandClicked(command: Command) {
        const selected = selection<UserOrganisationModel>(this.$store.state.organisation_users.searchedUsers);
        if (selected.length <= 0) {
            toast({
                message: this.$t("toast_org_missing_selection_command").toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(command.id) {
            case "remove":
                modal(MODAL_KICK_ORGANISATION_USER, {
                    org_id: this.$store.state.organisations.organisation.org_id,
                    users: selected
                });
                break;
            default:
            case "edit":
                modal(MODAL_EDIT_ORGANISATION_USER, {
                    org_id: this.$store.state.organisations.organisation.org_id,
                    users: selected
                });
                break;
        }
    }


}
