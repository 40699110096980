
import { Component, Vue, Watch } from 'vue-property-decorator';
import { eventBus } from "./main";

import Offcanvas from "bootstrap/js/dist/offcanvas"
import Modal from "bootstrap/js/dist/modal";

import Modals from "@/views/Modals.vue";
import LanguageChooser from '@/components/LanguageChooser.vue';
import PopupModal from "@/components/PopupModal.vue";
import AppLink from "@/components/AppLink.vue";
import Branding from "@/components/Branding.vue";
import PopupToast from "@/components/PopupToast.vue";
import ActionLoadingOverlay from '@/components/ActionLoadingOverlay.vue';
import AppLoadingView from "@/views/AppLoadingView.vue";

/* ModalsComponent */


import { ToastMessage, ModalRegisterMessage, ModalSrc, modal, GotoMessage, GotoMessageType } from "@/Toast";
import RootView from '@/components/RootView';
import { MODAL_LOGOUT } from './data';
import { RawLocation } from 'vue-router';
import { pausify } from './Utils';

@Component({
  components: {
    LanguageChooser,
    PopupModal,
    PopupToast,
    AppLink,
    Branding,
    ActionLoadingOverlay,
    Modals,
    AppLoadingView
  }
})
export default class App extends RootView {

  $refs!: {
    sidebar: HTMLElement,
    toast: PopupToast,
    loading: ActionLoadingOverlay
  }
  
  canvas: Offcanvas | undefined;
  currentModal?: Modal;
  modals: Map<string, ModalSrc> = new Map();
  routeHasLoaded = false;
  allowLoadingAnimation = false;
  activeLoadingWatcher = true;

  doesRouteRequireLoading(name: string): boolean {
    switch(name.toLowerCase()) {
      case 'login':
      case 'invite':
      case 'reset':
        return false;
      default:
        return true;
    }
  }

  onMobileAppBtnClick() {
    window.open(process.env.VUE_APP_MOBILE_APP_DOWNLOAD_URL!)
  }

  get showNavbar(): boolean {
    switch(this.$route.name?.toLowerCase()) {
      case 'login':
      case 'invite':
      case 'reset':
        return false;
      default:
        return true;
    }
  }
  

  get overflowStatus(): string {
    switch(this.$route.name?.toLowerCase()) {
      case 'invite':
        return "";
      default:
        return "overflow-hidden";
    }
  }

  setupLoading() {
    if (!this.doesRouteRequireLoading(this.$route.name!)) {
        this.allowLoadingAnimation = false;
        this.routeHasLoaded = true;
        this.$forceUpdate();
      } else {
        this.allowLoadingAnimation = true;
        setTimeout(() => {
            this.routeHasLoaded = true;
            this.$forceUpdate();
          }, 2000);
      }
  }

  beforeMount() {
   
    if (this.$route.path != "/") {
      this.setupLoading();
    }
    
    eventBus.$on('loading-hide', () => {
      this.$refs.loading.forceHide();
    });

    eventBus.$on('toast-hide', () => {
      this.$refs.toast.hide();
    });
    
    eventBus.$on('toast', (message: ToastMessage) => {
        this.$refs.toast.show(message);
    });

    eventBus.$on('go', async (message: GotoMessage) => {
      switch(message.type) {
          case GotoMessageType.GOTO_GO:
            if (message.path == -1) {
              if (window.history.state != null) {
                this.$router.back();
              } else {
                if (this.isAdmin) {
                  this.$router.push("/admin");
                } else {
                  this.$router.push("/dashboard");
                }
              }
            } else {
              this.$router.go(message.path as number);
            }
            break;
          default:
          case GotoMessageType.GOTO_PUSH:
            if (message.noLoading == undefined || !message.noLoading) {
              this.$refs.loading.showFor(500);
            }
            
            await pausify(new Promise<boolean>((resolve, reject) => {
              this.$router.push(message.path as RawLocation).then(() => {
                  resolve(true);
              });
            }), 500);

            break;
      }
    })

    eventBus.$on('modal-register', (message: ModalRegisterMessage) => {
      this.modals.set(message.name, {
        modal: message.modal,
        callback: message.callback
      });
    });

    eventBus.$on('modal-hide', () => {
      this.currentModal?.hide();
    });

    eventBus.$on('modal', (payload: {name: string, data?: any, retry: boolean}) => {
      if (!this.modals.has(payload.name)) {
        if (payload.retry) {
          setTimeout(() => {
            eventBus.$emit('modal', {name: payload.name, data: payload.data});
          })
        } else {
          console.error(`Modal not found : ${payload.name}`);
        }
        
        return;
      }
      const src: ModalSrc = this.modals.get(payload.name)!;
      if (src.callback) {
        src.callback(structuredClone(payload.data));
      }
      src.modal.show();
    });

    this.$store.commit("global/setQuery",JSON.parse(JSON.stringify(this.$route.query)));

    this.$store.dispatch("auth/updateToken");
    setInterval(() => {
      this.$store.dispatch("auth/updateToken");
    }, 60 * 1000);
  }

  onSignoutClicked() {
    modal(MODAL_LOGOUT);
  }

  showSidebar() {
      this.canvas = new Offcanvas(this.$refs.sidebar)
      this.canvas.show();
  }

  hideSidebar() {
    this.canvas?.hide();
  }

  @Watch("$route.path")
  onRouteChange(newValue: string, oldValue: string) {
    if (this.activeLoadingWatcher && oldValue == "/") {
      this.setupLoading();
      this.activeLoadingWatcher = false;
    }

    if (this.isMobile) {
      this.hideSidebar();
    }

    this.$store.commit("global/setQuery",JSON.parse(JSON.stringify(this.$route.query)));
  }

}

