import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import LangModel from "@/models/LangModel";

@Module
export default class LanguagesModule extends VuexModule {
    langs : LangModel[] = [{
        id: "en",
        tag: "English"
    },{
        id: "fr",
        tag: "Français"
    }];
}