
import {Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import { SYSTEM_TAG } from "@/data";

@Component
export default class TagsElements extends RootView {
    get hasTags(): boolean {
        return this.chooseDevice != null && 
            this.chooseDevice.local != undefined && 
            this.chooseDevice?.local.content.tags.length > 0 &&
            this.chooseDevice?.local.content.tags.some((value) => {
                if (value.includes(SYSTEM_TAG)) {
                    return false;
                }
                return true;
            })
    }

    isValidTag(tag: string): boolean {
        return !tag.includes(SYSTEM_TAG);
    }
}

