
import { Component } from "vue-property-decorator";
import PopupModal from "@/components/PopupModal.vue";
import { MODAL_SIGNUP } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        PopupModal
    }
})
export default class SignupModalView extends RootModal {
    override setup(): ModalSetup {
        return {
            name: MODAL_SIGNUP
        };
    }
}
