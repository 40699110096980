import { render, staticRenderFns } from "./DataBox.vue?vue&type=template&id=247bc8ce&scoped=true&"
import script from "./DataBox.vue?vue&type=script&lang=ts&"
export * from "./DataBox.vue?vue&type=script&lang=ts&"
import style0 from "./DataBox.vue?vue&type=style&index=0&id=247bc8ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247bc8ce",
  null
  
)

export default component.exports