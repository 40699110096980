
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import Searchbar from "@/components/Searchbar.vue";
import MissingData from "@/components/MissingData.vue";
import DeviceInfoBlock from "@/components/DeviceInfoBlock.vue";
import TreatmentTypesSearchDropdown from "@/components/TreatmentTypesSearchDropdown.vue";
import OrganisationSearchDropdown from "@/components/OrganisationSearchDropdown.vue";

import DeviceModel from "@/models/DeviceModel";
@Component({
    components: {
        Searchbar,
        MissingData,
        DeviceInfoBlock,
        TreatmentTypesSearchDropdown,
        OrganisationSearchDropdown
    }
})
export default class DeviceSearchHomeView extends RootView {

    get styleValue(): string {
      if (this.$store.state.organisation_devices.searchedDevices.length == 0) {
        return "overflow-y: hidden";
      }

      return "overflow-y: scroll;"
    }

    get searchBarWidth(): string {
      if (this.isMobile) {
        return "w-100";
      }
      return "flex-grow-1";
    }

    get otherSearchStyle(): string {
      if (this.isMobile) {
        return "width: 100%";
      }
      return "width: 196px;"
    }

    get paddingSize() {
      if (this.isMobile) {
        return "p-1";
      }
      return "p-3";
    }

    close() {
        this.$emit("close");
    }

    onDeviceListItemClicked(device: DeviceModel, i: number) {
        this.$emit("selected", {
            device: device,
            index: i
        });
    }

    onSearch() {
        this.$emit("search");
    }
}
