
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import { JobStatusRange, getJobStatusFromStr } from "@/Utils";

@Component({})
export default class JobStatus extends Vue {
    @Prop({default: ""}) status!: string; 
    jobStatus: JobStatusRange = JobStatusRange.IN_PROGRESS;

    @Watch('status')
    onStatusChanged(val: string) {
        this.jobStatus = getJobStatusFromStr(val);
    }

    mounted() {
        this.jobStatus = getJobStatusFromStr(this.status);
    }

    get jobAlert(): string {
        switch(this.jobStatus) {
            case JobStatusRange.COMPLETED:
            case JobStatusRange.SUCCEEDED:
                return "alert-success";
            case JobStatusRange.CANCELED:
            case JobStatusRange.DELETED:
            case JobStatusRange.REMOVED:
                return "alert-secondary"
            case JobStatusRange.CANCELLATION_IN_PROGRESS:
            case JobStatusRange.DELETION_IN_PROGRESS:
                return "alert-warning";
            case JobStatusRange.FAILED:
            case JobStatusRange.TIMED_OUT:
            case JobStatusRange.REJECTED:
                return "alert-danger";
            case JobStatusRange.QUEUED:
            case JobStatusRange.IN_PROGRESS:
            default:
                return "alert-primary";
        }
    }

}
