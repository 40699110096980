import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import '@/plugins/fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'leaflet/dist/leaflet.css';
import PortalVue from 'portal-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '@mdi/font/css/materialdesignicons.css';
import "@/MapExtensions";

export const eventBus = new Vue();

Vue.use(PortalVue);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
