import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import LanguagesModule from '@/store/modules/LanguagesModule'
import AuthModule from '@/store/modules/AuthModule'
import OrganisationsModule from '@/store/modules/OrganisationsModule'
import OrganisationDevicesModule from '@/store/modules/OrganisationDevicesModule'
import DeviceModule from "@/store/modules/DeviceModule"
import OrganisationUsersModule from "@/store/modules/OrganisationUsersModule"
import UserModule from '@/store/modules/UserModule'
import AdminModule from '@/store/modules/AdminModule'
import AdminExpiredLicensesModule from '@/store/modules/AdminExpiredLicensesModule'
import FirmwareModule from "@/store/modules/FirmwareModule"
import InviteModule from '@/store/modules/InviteModule'
import GlobalModule from "@/store/modules/GlobalModule"

const store = new Vuex.Store({});

export const langModule = new LanguagesModule({store: store, name: "languages"});
export const authModule = new AuthModule({store: store, name: "auth"});
export const organisationModule = new OrganisationsModule({store: store, name: "organisations"});
export const organisationDevicesModule = new OrganisationDevicesModule({store: store, name: "organisation_devices"});
export const deviceModule = new DeviceModule({store: store, name: "device"});
export const organisationUsersModule = new OrganisationUsersModule({store: store, name: "organisation_users"});
export const userModule = new UserModule({store: store, name: "user"});
export const adminExpiredLicensesModule = new AdminExpiredLicensesModule({store: store, name: "admin_expired_licenses"});
export const adminModule = new AdminModule({store: store, name: "admin"});
export const firmwareModule = new FirmwareModule({store: store, name: "firmwares"});
export const inviteModule = new InviteModule({store: store, name: "invite"});
export const globalModule = new GlobalModule({store: store, name: "global"});
export default store;