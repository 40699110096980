
import { Vue, Component, Prop } from "vue-property-decorator";
import RootModal, { ModalSetup } from "@/components/RootModal";
import DeviceSummary from "@/components/DeviceSummary.vue";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import DeviceModel from "@/models/DeviceModel";
import { MODAL_ADMIN_REINSTATE_LIC, SYSTEM_TAG_IGNORE_LICENSE } from "@/data";
import { selection } from "@/Utils";

@Component({
    components: {
        DialogPopupModal,
        DeviceSummary
    }
})
export default class AdminIgnoreDeviceLicenseModalView extends RootModal {
    specificDevices: DeviceModel[] = [];

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_REINSTATE_LIC,
        }
    }

    reset() {
        this.specificDevices = selection<DeviceModel>(this.$store.state.admin_expired_licenses.searchedDevices);
    }

    override process(): Promise<any> { 
        return this.$store.dispatch("admin/setTags", {
            options: {
                interaction: false,
                tags: [SYSTEM_TAG_IGNORE_LICENSE]
            },
            selected: this.specificDevices
        }).then(() => {
            return this.$store.dispatch("admin_expired_licenses/load");
        })
    }
}
