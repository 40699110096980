import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { network } from "@/network"
import UserOrganisationModel from "@/models/UserOrganisationModel";
import { InternalRole, UserRole } from "./AuthModule";
import { SearchItemModel } from "@/models/SearchItemModel";
import { wrapSearchItems } from "@/Utils";
import { authModule } from "..";

const findUserById = (id: string, models: UserOrganisationModel[]): UserOrganisationModel | undefined => {
    for (let i = 0; i < models.length; i++) {
        if (models[i].usr_id == id) return models[i];
    }
    return undefined;
}

@Module
export default class OrganisationUsersModule extends VuexModule {

    users: UserOrganisationModel[] = [];
    searchedUsers: SearchItemModel<UserOrganisationModel>[] = [];
    searchName = ""
    searchRole = ""
    loaded = false;

    @Mutation
    clear() {
        this.users = [];
        this.searchedUsers = [];
        this.searchName = ""
        this.searchRole = ""
        this.loaded = false;
    }

    @Mutation
    setSearchRole(role: string) {
        this.searchRole = role;
    }

    @Mutation
    setUsers(users: UserOrganisationModel[]) {
        this.users = users;
        this.searchedUsers = wrapSearchItems(users);
        this.loaded = true;
    }

    @Mutation
    setSearchedChecked(value: boolean) {
        for (let i = 0; i < this.searchedUsers.length; i++) {
            this.searchedUsers[i].checked = value;
        }
    }

    @Mutation
    setSearchedUsers(searchedUsers: SearchItemModel<UserOrganisationModel>[]) {
        this.searchedUsers = searchedUsers;
    }

    @Mutation
    addUser(user: UserOrganisationModel) {
        this.users.push(user);
        this.searchedUsers = wrapSearchItems(this.users);
    }

    @Mutation
    updateUser(data: {usrId: string, role: string}) {
        const userModel: UserOrganisationModel | undefined = findUserById(data.usrId, this.users);

        if (userModel) {
            userModel.role = data.role;
        }
    }


    @Mutation
    removeUser(usr_id: string) {
        const arrCopy = Array.from(this.users);
        const objWithIdIndex = arrCopy.findIndex((obj) => obj.usr_id === usr_id);
        arrCopy.splice(objWithIdIndex, 1);
        this.users = arrCopy;
        this.searchedUsers = wrapSearchItems(this.users);
    }

    @Action
    search() {
        const results: UserOrganisationModel[] = [];

        const role: string = this.searchRole.trim().toUpperCase();

        for (let i = 0; i < this.users.length; i++) {
            if (this.searchName.trim().length > 0 && !this.users[i].info?.name.startsWith(this.searchName.trim())) {
                continue
            }

            const userRole: string = this.users[i].role.trim().toUpperCase();
            if (role && userRole != role) {
                continue;
            }

            results.push(this.users[i]);
        }

        this.setSearchedUsers(wrapSearchItems(results));
    }

    @Action
    async getUsers(data: {org_id: string}) {
        const rawUsers: UserOrganisationModel[] = JSON.parse((await network().get(`/op/user/organisations/${data.org_id}/users`)).data);
        
        if (authModule.user && authModule.user.internals.includes(InternalRole.ADMIN)) {
            this.setUsers(rawUsers);
            return;
        }

        const users: UserOrganisationModel[] = [];
        for (let i = 0; i < rawUsers.length; i++) {
            if (rawUsers[i].role.toLowerCase() != "superadmin") {
                users.push(rawUsers[i]);
            }
        }
        this.setUsers(users);
    }

    @Action
    async invite(data: {org_id: string, email: string, role: UserRole}): Promise<boolean> {
        const responseData: any = JSON.parse((await network().post(`/op/user/organisations/${data.org_id}/users`, JSON.stringify({
            email: data.email,
            role: data.role
        }))).data);

        if (responseData.error) {
            return false;
        }

        return true;
    }

    @Action
    async update(data: {org_id: string, usr_id: string, role: UserRole}): Promise<boolean> {
        const responseData: any = await network().put(`/op/user/organisations/${data.org_id}/users/${data.usr_id}`, JSON.stringify({
            role: data.role
        }));

        if (responseData.error) {
            return false;
        }


        this.updateUser({usrId: data.usr_id, role: data.role});
        return true;
    }

    @Action
    async remove(data: {org_id: string, usr_id: string}): Promise<boolean> {
        const responseData: any = await network().delete(`/op/user/organisations/${data.org_id}/users/${data.usr_id}`);

        if (responseData.error) {
            return false;
        }

        this.removeUser(data.usr_id);
        return true;
    }
}