
import { Vue, Component, Prop } from "vue-property-decorator";
import RootView from "./RootView";

@Component
export default class AppLink extends RootView {
    @Prop({default: false}) outside!: boolean;
    @Prop({default: ""}) name?: string;
    @Prop({default: ""}) link?: string;
    @Prop({default: ""}) icon?: string;
    @Prop({default: ""}) title?: string;

    menu(name: string, path: string) {
        if (this.outside) {
            window.open(this.link);
            return;
        }

        if (this.$route.name == name) return;
        this.goto({path: path});
    }

    get id() {
        return `menu-link-${this.name?.toLowerCase()}`;
    }
}
