
import {Vue, Component} from "vue-property-decorator";

@Component
export default class FilterSort extends Vue {
    direction = true;

    onIconClicked() {
        this.direction = !this.direction;
        this.$emit("direction", !this.direction ? "down" : "up");
    }
}
