
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_LOGOUT } from "@/data";
import RootModal, { ModalSetup, ModalType } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class LogoutModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_LOGOUT,
            type: ModalType.VIEW_ONLY
        }
    }   

    logout() {
        this.$store.dispatch("auth/logout").then(() => {
            this.$store.commit("user/clear");
            this._modal.hide();
            this.goto({ path: '/login'}, true);
        });
    }
}
