
import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import SectionAvatar from "@/components/SectionAvatar.vue";
import InfoRow from "@/components/InfoRow.vue";

@Component({
    components: {
        SectionAvatar,
        InfoRow
    }
})
export default class SummaryCard extends RootView {
    @Prop({default: ""}) phone!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) email!: string;
    @Prop({default: ""}) website!: string;
    @Prop({default: ""}) address!: string;
    @Prop({default: ""}) icon!: string;

    showSection(value: string) {
        if (value == undefined) return false;
        return value.trim().length > 0;
    }
}

