
import { Vue, Component, Prop } from "vue-property-decorator";
import { isStringEmpty, isValidSerialNumber } from "@/Utils";
import { DeviceSelected } from "@/models/DeviceSelection";

import DialogPopupModal from "@/components/DialogPopupModal.vue";
import Editbar from "@/components/Editbar.vue";
import BoxIndicator from "@/components/BoxIndicator.vue";
import Checkbox from "@/components/Checkbox.vue";

import { MODAL_ADMIN_UPDATE_LICENSE } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        Editbar,
        BoxIndicator,
        Checkbox
    }
})
export default class AdminUpdateLicenseModalView extends RootModal {
    reverseLicenseValue = false;
    choiceLicenseDate = (new Date()).toISOString().substr(0, 10);
    choiceLicenseDateError = false;
    choiceLicenseSerialNumberError = false;
    missingSerialNumbers = false;
    licenses: {device_id: string, id: string, expiry: number}[] = [];
    inputSerialNumber = "";

    licenseUpdateRules = {
      serialnumber: (value: string): any => isValidSerialNumber(value) || "Invalid serial number format."
    }

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_UPDATE_LICENSE
        };
    }
    
    onDeviceLicenseSubmitSn(device: DeviceSelected, data: string) {
      if (!isValidSerialNumber(data)) {
        return;
      }
      device.serialnumber = data;
    }
    
    onAdminLicenseModalOpened() {
        this.choiceLicenseDateError = false;
        this.choiceLicenseSerialNumberError = false;
        this.inputSerialNumber = this.hasSerialNumber ? this.serialNumber! : "";
        this.choiceLicenseDate = (new Date()).toISOString().substr(0, 10);
        this.missingSerialNumbers = false;
        this.reverseLicenseValue = false;
    }

    override validations(): Promise<boolean> {
        this.licenses = [];
        let expiryLicenseChoice = new Date(this.choiceLicenseDate);
        this.choiceLicenseDateError = false;

        let currentDate = new Date();
        if (!this.reverseLicenseValue && expiryLicenseChoice.getTime() < currentDate.getTime()) {
            this.choiceLicenseDateError = true;
            return Promise.resolve(false);
        }

        let expiry: number = expiryLicenseChoice.getTime();
        this.missingSerialNumbers = false;
        this.choiceLicenseSerialNumberError = false;

        const devices: DeviceSelected[] = this.selectedDevices;
        for (let i = 0; i < devices.length; i++) {
            if (!this.isOnDevicePage) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                let selectedDeviceSN: string | undefined = devices[i]!.serialnumber;
                if (!selectedDeviceSN || selectedDeviceSN.trim() == '') {
                    this.missingSerialNumbers = true
                    return Promise.resolve(false);
                }

                this.licenses.push({
                    id: devices[i].serialnumber! ,
                    device_id: devices[i].device_id,
                    expiry: expiry
                });
            } else {
                if (isStringEmpty(this.inputSerialNumber)) {
                    this.choiceLicenseSerialNumberError = true;
                    return Promise.resolve(false);
                }

                this.licenses.push({
                    id: this.inputSerialNumber.trim(),
                    device_id: devices[i].device_id,
                    expiry: expiry
                });
            }
        }

        return Promise.resolve(true);
    }

    override process(): Promise<any> {
      return this.$store.dispatch("admin/updateLicenses", {selected: this.licenses}).then(async (data) => {
        await this.$store.dispatch("admin_expired_licenses/load");
        return data;
      })
    }

}
