
import { Component } from "vue-property-decorator";
import PopupModal from "@/components/PopupModal.vue";
import { MODAL_USER_INVITATION } from "@/data";
import RootModal, { ModalSetup, ModalType } from "@/components/RootModal";

@Component({
    components: {
        PopupModal
    }
})
export default class UserInvitationModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_USER_INVITATION,
            type: ModalType.VIEW_ONLY
        };
    }

    override onClose() {
      this.$store.commit("invite/clear");
    }
}
