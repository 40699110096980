
import { Component } from "vue-property-decorator";

import { capitalize, validatePassword } from "@/Utils";
import PasswordField from "@/components/PasswordField.vue";
import RootView from "@/components/RootView";
import ErrorList from "@/components/ErrorList.vue";

@Component({
    components: {
        PasswordField,
        ErrorList
    }
})
export default class InvitationView extends RootView {
    errors: string[] = [];
    name = "";
    password = "";
    confirmPassword = "";

    $refs!: {
        end: HTMLElement
    }

    get getRoleText(): string {
        let role: string = this.$store.state.invite.role;
        if (role == "member") {
            return ".";
        }

        if (role == "admin") {
            return ` as an ${capitalize(role)}.`;
        }
        return ` as a ${capitalize(role)}.`;
    }

    async confirm() {
        this.errors = [];
        
        let name: string = this.name.trim();
        let password: string = this.password.trim();
        let errors: string[] = validatePassword(password, this.confirmPassword);

        if (name.length <= 3) {
            errors.push("Name needs to be 3 characters or more.");
        }

        if (errors.length > 0) {
            this.errors = errors;
            setTimeout(() => {
                this.$refs.end.scrollIntoView({
                    behavior: "smooth"
                });
            }, 200);
            return;
        }

        const result: boolean = await this.$store.dispatch("invite/signup", {
            name: name,
            password: password
        });

        if (!result) {
            return;
        }

        await this.$store.dispatch("auth/login", {
            username: this.$store.state.invite.email,
            password: this.password
        });

        this.goto({ path: '/dashboard'});
    }
}
