
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import Toast from "bootstrap/js/dist/toast";
import { ToastMessage, ToastType } from "@/Toast";

@Component
export default class ToastComponent extends RootView {
    toast!: Toast;

    $refs!: {
        toast: HTMLElement
    }

    headerClass = "";
    icon = "";
    message = "";
    category = "";

    mounted() {
        this.toast = new Toast(this.$refs.toast, {
            animation: true,
            autohide: true,
            delay: 3000
        });
    }
    
    show(message: ToastMessage) {
        this.message = message.message;
        switch (message.type) {
          case ToastType.TOAST_SUCCESS:
            this.category = "Success";
            this.icon = "fa-regular fa-circle-check";
            this.headerClass = "bg-success";
            break;
          case ToastType.TOAST_ERROR:
            this.category = "Error";
            this.icon = "fa-regular fa-circle-xmark";
            this.headerClass = "bg-danger";
            break;
          case ToastType.TOAST_INFO:
          default:
            this.category = "Info";
            this.icon = "fa-regular fa-circle-question";
            this.headerClass = "bg-info";
            break;
        }
        
        this.toast.show();
    }

    hide() {
        this.toast.hide();
    }
}
