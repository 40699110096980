
import { Component } from "vue-property-decorator";
import PopupModal from "@/components/PopupModal.vue";
import { MODAL_USER_INVITATION_FAILED } from "@/data";
import RootModal, { ModalSetup, ModalType } from "@/components/RootModal";

@Component({
    components: {
        PopupModal
    }
})
export default class UserInvitationFailedModalView extends RootModal {
    
  override setup(): ModalSetup {
    return {
      name: MODAL_USER_INVITATION_FAILED,
      type: ModalType.VIEW_ONLY
    }
  }

  override onClose() {
    this.$store.commit("invite/clear");
  }
}
