
import { Prop, Component } from "vue-property-decorator";
import SelectDropdown from "@/components/SelectDropdown.vue";
import RootView from "@/components/RootView";
import OrganisationModel from "@/models/OrganisationModel";
import { QUERY_PARAM_ORG } from "@/Utils";

@Component({
    components: {
        SelectDropdown
    }
})
export default class OrganisationSearchDropdown extends RootView {

    @Prop({default: false}) allowQuery!: boolean;
    @Prop({default: () => {return undefined}}) base!: OrganisationModel | undefined;
    @Prop({default: () => {return []}}) src!: OrganisationModel[];
    @Prop({default: "admin/setSearchedOrganisations"}) commitTo!: string;
    @Prop({default: "admin/searchDevices"}) search!: string;

    $refs!: {
      organisationDropdown: SelectDropdown
    }

    mounted() {
        if (!this.base) return;
        const organisations = this.wrapOrganisations(this.src);
        let result = undefined;

        for (let i = 0; i < organisations.length; i++) {
            if (organisations[i].org_id == this.base.org_id)  {
                result = organisations[i];
            }
        }

        if (result) {
            this.commitOrganisationChange(result);
            this.$refs.organisationDropdown.forceValue(result);
        }
    }

    onOrganisationClear() {
        this.$store.commit(this.commitTo, undefined);
        this.$store.dispatch(this.search);

        if (this.allowQuery) {
            this.removeFromQuery(QUERY_PARAM_ORG);
        }
        
    }

    commitOrganisationChange(organisation: OrganisationModel) {
        let org: OrganisationModel | undefined = organisation;
        if (organisation.org_id == "all") {
            org = undefined;
        }

        this.$store.commit(this.commitTo, org);
    }

    onOrganisationChange(organisation: OrganisationModel) {
        this.commitOrganisationChange(organisation);
        this.$store.dispatch(this.search);
        if (this.allowQuery) {
            this.setQuery(QUERY_PARAM_ORG, organisation?.org_id ?? "all");
        }
    }
}
