import { Coordinates } from "@/Coordinates";
import { v4 as uuid } from "uuid";
import { SearchItemModel } from "./models/SearchItemModel";

export const QUERY_PARAM_LICENSE_EXPIRED = "license_expired";
export const QUERY_PARAM_TRANSDUCER_DISCONNECTED = "transducer_disconnected";
export const QUERY_PARAM_FIRMWARE = "fw";
export const QUERY_PARAM_LABEL = "label";
export const QUERY_PARAM_SN = "sn";
export const QUERY_PARAM_IMEI = "imei";
export const QUERY_PARAM_TAGS = "tags";
export const QUERY_PARAM_LAST_UPDATE_TYPE = "last_update_type";
export const QUERY_PARAM_TREATMENT = "treatment";
export const QUERY_PARAM_ORG = "org";

export interface RoleOption {
    id: string,
    name: string
}

export interface FileUpload {
    id: string,
    file: File, 
    type: string | {id: string, name: string},
}

export enum JobStatusRange {
    QUEUED = "queued",
    REJECTED = "rejected",
    REMOVED = "removed",
    TIMED_OUT = "timed_out",
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
    CANCELED = "canceled",
    SUCCEEDED = "succeeded",
    COMPLETED = "completed",
    DELETED = "deleted",
    CANCELLATION_IN_PROGRESS = "cancellation_in_progress",
    DELETION_IN_PROGRESS = "deletion_in_progress"
}

export const getJobStatusFromStr = (str: string): JobStatusRange => {
    switch (str) {
        case "queued":
            return JobStatusRange.QUEUED;
        case "rejected":
            return JobStatusRange.REJECTED;
        case "removed":
            return JobStatusRange.REMOVED;
        case "timed_out":
            return JobStatusRange.TIMED_OUT;
        case "failed":
            return JobStatusRange.FAILED;
        case "canceled":
            return JobStatusRange.CANCELED;
        case "succeeded":
            return JobStatusRange.SUCCEEDED;
        case "completed":
            return JobStatusRange.COMPLETED;
        case "deleted":
            return JobStatusRange.DELETED;
        case "cancellation_in_progress":
            return JobStatusRange.CANCELLATION_IN_PROGRESS;
        case "deletion_in_progress":
            return JobStatusRange.DELETION_IN_PROGRESS;
        case "in_progress":
        default:
            return JobStatusRange.IN_PROGRESS;
    }
}
export interface Tab {
    id: string,
    label: string
}

export const fileReader = (file: File): Promise<ArrayBuffer | null | string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(reader.result)
      };

      reader.readAsArrayBuffer(file)
    })
  }


export const capitalize = (value: string) => {
    value = value.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export interface Command {
    id: string,
    name: string
}

export const isValidSerialNumber = (value: string): boolean => {
    if (!value.startsWith("EMF1201-")) {
        return false;
    }
    const separator = value.indexOf("-") + 1;
    const id = value.substring(separator, value.length);

    if (id.length < 4) {
        return false;
    }
    return true;
}

export const validatePhone = (phone: string): string[] => {
    const errors: string[] = [];

    if (!/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(phone)) {
        errors.push("phone_rule_international");
    }

    return errors;
}

export const validatePassword = (password: string, confirmPassword: string): string[] => {
    const errors: string[] = [];

    if (password.length < 8) {
        errors.push("password_rule_min_char");
    }

    if (!/[A-Z]/.test(password)) {
        errors.push("password_rule_uppercase");
    }

    if (!/[0-9]/.test(password)) {
        errors.push("password_rule_number");
    }

    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) {
        errors.push("password_rule_special")
    }

    if (password != confirmPassword) {
        errors.push("password_match");
    }

    return errors;
}

export const isValidImei = (value: string): boolean => {
    return /^\d+$/.test(value) && value.length == 15;
}

export const isValidVersion = (value: string): boolean => {
    return /^\d+\.\d+\.\d+$/.test(value);
}

export const genUUID = (): string => {
    return uuid();
}

export const toArray = (item: any | any[]): any[] => {
    if (item == undefined) {
        return [];
    }
    if (Array.isArray(item)) {
        return item;
    }
    return [item];
}

export enum UnitDateType {
    YEAR = "year",
    MONTH = "month",
    DAY = "day",
    HOUR = "hour",
    WEEK = "week",
    MINUTE = "minute"
}

export const isString = (x: any): boolean => {
    return typeof x === 'string' || Object.prototype.toString.call(x) === "[object String]"
}

export const dayToString = (value: number): string => {
    return (new Date(value)).toISOString().substr(0, 10);
}

export const dayToPreciseString = (value: number): string => {
    return (new Date(value)).toISOString();
}

export const activatedTime = (time: number): {days: number, minutes: number, hours: number}=> {
    const days = Math.round(time / (24 * 60));
    const rawMinutes = Math.round(time % (24* 60));
    const hours = Math.round(rawMinutes / 60);
    const minutes = rawMinutes % 60;

    return {
        days: days,
        minutes: minutes,
        hours: hours
    };
}

export const betweenDaysConversion = (fromDate: Date, toDate: Date, allowSmaller = false, allowWeeks = false): {value: number, unit: UnitDateType } => {

    const days = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24));

    if (days > 365) {
        return {
            value: Math.floor(days / 365),
            unit: UnitDateType.YEAR
        }
    }

    const weeks = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24 * 7));
    if (weeks > 0 && allowWeeks) {
        return {
            value: weeks,
            unit: UnitDateType.WEEK
        }
    } 

    if (days > 3 || !allowSmaller) {
        return {
            value: days,
            unit: UnitDateType.DAY
        }
    }

    const hours = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600));
    if (hours >= 2) {
        return {
            value: hours,
            unit: UnitDateType.HOUR
        }
    }

    return {
        value: Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 60)),
        unit: UnitDateType.MINUTE
    }
}

export const isDefaultCoordinates = (coord: Coordinates): boolean => {
    return coord.longitude == 0 && coord.latitude == 0 && coord.accuracy == 0;
}

export const wrapSearchItems = <T>(items: T[]): SearchItemModel<T>[] => {
    const results: SearchItemModel<T>[] = [];

    for (let i = 0; i < items.length; i++) {
        results.push({
            checked: false,
            model: items[i]
        })
    }

    return results;
}

export const hasProperty =(object: Record<string, unknown>, key: string): boolean => {
    return Object.prototype.hasOwnProperty.call(object, key);
}

export const isObjectEmpty = (object: any): boolean => {
    return (
      object &&
      Object.keys(object).length === 0 &&
      object.constructor === Object
    );
};

export const isStringEmpty = (str: string): boolean => {
    return (!str || str.length === 0 );
}

export const getUserTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const localIsoDateString = (ts: number) : string => {
    const tzoffset: number = (new Date()).getTimezoneOffset() * 60000;
    return (new Date(ts - tzoffset)).toISOString().slice(0, -8);
}

export const localIsoDateWithoutDaytimeString = (ts: number) : string => {
    const tzoffset: number = (new Date()).getTimezoneOffset() * 60000;
    return (new Date(ts - tzoffset)).toISOString().split('T')[0];
}

export const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };
  
export const dateStrFormat = (timestamp: number): string => {
    const date: Date = new Date(timestamp);
    const monthFormatter = new Intl.DateTimeFormat('en',  { month: 'short', timeZone: "America/New_York"});
    const strMonth = monthFormatter.format(date);
    const dayOfTheMonth = date.getDate();
    const year = date.getFullYear();
    return `${strMonth} ${dayOfTheMonth}${nth(dayOfTheMonth)} ${year}`
}

export const getDayOfTheWeekFromNumber = (day: number): string => {
    switch (day) {
        case 0:
        default:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4: 
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
}

export const dateStrFormatWithTime = (timestamp: number): string => {
    const date: Date = new Date(timestamp);
    const monthFormatter = new Intl.DateTimeFormat('en',  { month: 'short', timeZone: "America/New_York"});
    const strMonth = monthFormatter.format(date);
    const dayOfTheMonth = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const dayOfTheWeek = getDayOfTheWeekFromNumber(date.getDay());
    return `${dayOfTheWeek} ${strMonth} ${dayOfTheMonth}${nth(dayOfTheMonth)} ${year} ${hours}:${minutes}`
}

export const getExtendedVersionNumber = (version: string): number => {
    if (version.trim() == "") {
      return 0;
    }
    if (version.includes("+")) {
      version = version.substring(0, version.indexOf("+"));
    }
    const versionCellsString: string[] = version.split('.');
    const versionCells: number[] = versionCellsString.map((i) => Number.parseInt(i));
    return versionCells[0] * 100000 + versionCells[1] * 1000 + versionCells[2];
}

export const selection = <Y>(models: SearchItemModel<Y>[], checked = true): Y[] => {
    const results: Y[] = [];

    for (let i = 0; i < models.length; i++) {
        if (models[i].checked == checked) {
            results.push(models[i].model);
        }
    }

    return results;
}

export function promiseWithTimeout<T>(payload: {promise: Promise<T>, defaultValue: T, timeout: number}): Promise<T> {
    return Promise.race<T>([
        payload.promise,
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(payload.defaultValue);
            }, payload.timeout)
        })
    ]);
}

export const promiseWithTimeoutBool = (payload: {promise: Promise<boolean>, timeout?: number}): Promise<boolean> => {
    const timeout = payload.timeout ?? 500;
    return promiseWithTimeout<boolean>({promise: payload.promise, defaultValue: false, timeout: timeout});
}

export class Pause {
    take: Promise<any>;
    constructor(val: number) {
        this.take = new Promise((resolve, reject) => {
            setTimeout(() => {resolve(true)}, val);
        });
    }
}

export const setPause = (time: number): Pause => {
    return new Pause(time);
}

export const pausify = async (promise: Promise<any>, time: number): Promise<any> => {
    const pause: Pause = setPause(time);
    let result: any;

    try {
        result = await promise;
    } catch (exception) {
        await pause.take;
        throw exception;
    }

    await pause.take;
    return result;
}