
import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import SelectDropdown from "@/components/SelectDropdown.vue";
import { QUERY_PARAM_TREATMENT } from "@/Utils";

@Component({
    components: {
        SelectDropdown
    }
})
export default class TreatmentTypesSearchDropdown extends RootView {

    @Prop({default: false}) allowQuery!: boolean;
    @Prop({default: "admin/setSearchedTreatmentType"}) commitTo!: string;
    @Prop({default: "admin/searchDevices"}) search!: string;

    $refs!: {
      treatmentTypeDropdown: SelectDropdown,
    }

    mounted() {
        if (!this.$store.state.admin.searchedTreatmentType) return;
        const treatmentTypes = this.treatmentTypes;
        let result = undefined;

        for (let i = 0; i < treatmentTypes.length; i++) {
          if (treatmentTypes[i].value == this.$store.state.admin.searchedTreatmentType)  {
            result = treatmentTypes[i];
          }
        }

        if (result) {
          this.commitTreatmentTypeChange(result);
          this.$refs.treatmentTypeDropdown.forceValue(result);
        }
    }

    commitTreatmentTypeChange(treatmentType: any) {
        let elem: any | undefined = treatmentType;
        if (elem.key == "all") {
            elem = undefined;
        }

        this.$store.commit(this.commitTo, elem);
    }

    onTreatmentTypeChange(treatmentType: any) {
        this.commitTreatmentTypeChange(treatmentType);
        this.$store.dispatch(this.search);

        if (this.allowQuery) {
            this.setQuery(QUERY_PARAM_TREATMENT, treatmentType.value.toLowerCase());
        }
        
    }

    onTreatmentTypeClear() {
        this.$store.commit(this.commitTo, undefined);
        this.$store.dispatch(this.search);
        if (this.allowQuery) {
            this.removeFromQuery(QUERY_PARAM_TREATMENT);
        }
    }

}

