
import {Vue, Component, Prop} from "vue-property-decorator"
import { onClickOutside } from '@vueuse/core'

@Component
export default class Searchbar extends Vue {
    @Prop({default: ""}) label?: string; 
    @Prop({default: ""}) value?: any;

    $refs!: {
        field: HTMLElement
    }

    content = this.value;
    selected = false;

    mounted() {
        onClickOutside(this.$refs.field, this.onClickOutside);
    }

    onClick() {
        this.selected = true;
    }

    onSearchPressedEnter(event: any) {
        if (event.key == 'Enter') {
            this.$emit("search");
        }
    }

    onSearchButtonSubmit() {
        this.$emit('search');
    }

    updateValue(event: any) {
        this.$emit('input', event)
    }

    onClickOutside() {
        if (this.selected) {
            this.selected = false;
            this.$emit('search');
        }
    }
}

