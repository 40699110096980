
import { Vue, Component, Prop } from "vue-property-decorator";

import AdminListLayout from "@/components/AdminListLayout.vue";
import PlusButton from "@/components/PlusButton.vue";
import AdminFirmwareCommands from "@/components/AdminFirmareCommands.vue";
import Searchbar from "@/components/Searchbar.vue";
import MissingData from "@/components/MissingData.vue";
import ViewButton from "@/components/ViewButton.vue";

import FirmwareModel from "@/models/FirmwareModel";
import { isStringEmpty,  Command } from "@/Utils";
import { modal } from "@/Toast";
import { MODAL_ADMIN_VIEW_FW, MODAL_ADMIN_CREATE_FW } from "@/data";

@Component({
    components: {
        AdminListLayout,
        AdminFirmwareCommands,
        Searchbar,
        PlusButton,
        MissingData,
        ViewButton
    }
})
export default class AdminFirmwareView extends Vue {

    commands: Command[] = [
      {
        id: "create",
        name: this.$t("firmware_command_create").toString()
      }
    ]

    chosenFirmware: FirmwareModel | null = null;

    viewFirmwareFile() {
      window.open(process.env.VUE_APP_ADMIN_MOBILE_VERSION_FILE, "_blank");
    }

    onViewButtonClicked(firmware: FirmwareModel) {
        const firmware_id = firmware.firmware_id;
        const version = firmware.version;
        const name = firmware.name;
        const description = isStringEmpty(firmware.description) ? this.$t("not_available").toString() : firmware.description ;
        const release = new Date(firmware.release).toLocaleDateString("en-CA");
        const firmwareFiles = firmware.files;
        const latest = firmware.latest ?? false;
        modal(MODAL_ADMIN_VIEW_FW, {
            firmware_id: firmware_id,
            version: version,
            name: name,
            description: description,
            release: release,
            firmwareFiles: firmwareFiles,
            latest: latest
        })
    }

    onButtonCreateClicked() {
        modal(MODAL_ADMIN_CREATE_FW);
    }

    onSearch() {
      this.$store.dispatch("firmwares/search");
    }

}

