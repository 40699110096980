
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import FirmwareModel from "@/models/FirmwareModel";
import { MODAL_ADMIN_ASSIGN_FW } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class AdminAssignFirmwareLatestModalView extends RootModal {

    chosen: FirmwareModel | null = null;
    inputText = "";
    showError = false;

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_ASSIGN_FW,
        }
    }

    reset() {
        this.inputText = "";
        this.showError = false;
    }
    
    override validations(): Promise<boolean> {
        if (this.inputText != "LATEST") {
            this.showError = true;
            return Promise.resolve(false);
        }
        this.showError = false;
        return Promise.resolve(true);
    }

    override process(): Promise<any> {
        return this.$store.dispatch("firmwares/setLatest", this.chosen);
    }

}
