import { Command } from "./Utils";

export const ADMIN_OP_CREATE = "create";
export const ADMIN_OP_LICENSE = "license";
export const ADMIN_OP_FIRMWARE = "firmware";
export const ADMIN_OP_DESIREDCOORDS = "desiredcoords";
export const ADMIN_OP_ASSIGNMENT = "assignement";
export const ADMIN_OP_TAGS = "tags";
export const ADMIN_OP_SYNC = "sync";

export const CODE_UNAUTHORIZED = 403;
export const CODE_VALID = 1;
export const CODE_INVALID = 0;

export const UPDATE_TYPES: string[] = ["unknown", "mobile", "iot", "admin", "web"];
export const TREATMENT_TYPES: string[] = ["treatment_on", "treatment_off", "treatment_in_transition", "in_storage"];
export const ORGANISATION_ROLES: string[] = ["guest", "member", "admin", "owner"];

export const MODAL_EDIT_DEVICE_LABEL = "modal_edit_device_label";
export const MODAL_STORAGE = "modal_storage";
export const MODAL_STORAGE_REMOVE = "modal_storage_remove";
export const MODAL_LOGOUT = "modal_logout";
export const MODAL_TREATMENT = "modal_treatment";
export const MODAL_TREATMENT_CANCEL = "modal_treatment_cancel";
export const MODAL_EDIT_PROFILE = "modal_edit_profile";
export const MODAL_EDIT_PASSWORD = "modal_edit_password";
export const MODAL_SIGNUP = "modal_signup";

export const MODAL_ADMIN_STORAGE = "modal_admin_storage";
export const MODAL_ADMIN_UPDATE_TAGS = "modal_admin_update_tags";
export const MODAL_ADMIN_UPDATE_LICENSE = "modal_admin_update_license";
export const MODAL_ADMIN_UPDATE_COORDINATES = "modal_admin_update_coordinates";
export const MODAL_ADMIN_CREATE_FW = "modal_admin_create_fw";
export const MODAL_ADMIN_UPDATE_FW = "modal_admin_update_fw";
export const MODAL_ADMIN_ASSIGN_ORG = "modal_admin_assign_org";
export const MODAL_ADMIN_DISABLE = "modal_admin_disable";
export const MODAL_ADMIN_ASSIGN_FW = "modal_admin_assign_fw";
export const MODAL_ADMIN_VIEW_FW = "modal_admin_view_fw";
export const MODAL_ADMIN_CREATE_ORG = "modal_create_org";
export const MODAL_ADMIN_OP_SUCCESS = "modal_op_success";
export const MODAL_ADMIN_CREATE_EMPTY = "modal_admin_create_empty";
export const MODAL_ADMIN_CANCEL_JOB = "modal_admin_cancel_job";
export const MODAL_ADMIN_GEN_LIC = "modal_admin_gen_lic";
export const MODAL_ADMIN_IGNORE_LIC = "modal_admin_ignore_lic";
export const MODAL_ADMIN_REINSTATE_LIC = "modal_admin_reinstate_lic";

export const MODAL_USER_INVITATION = "modal_user_invitation";
export const MODAL_USER_INVITATION_FAILED = "modal_user_invitation_failed";

export const MODAL_EDIT_ORGANISATION_USER = "modal_edit_organisation_user";
export const MODAL_EDIT_ORGANISATION = "modal_edit_organisation";
export const MODAL_INVITE_ORGANISATION = "modal_invite_organisation";
export const MODAL_KICK_ORGANISATION_USER = "modal_kick_organisation_user";

export const EVENTS: Command[] = [
    {
      id: "USER_ACTIVATE",
      name: "USER_ACTIVATE"
    },
    {
      id: "USER_DEACTIVATE",
      name: "USER_DEACTIVATE"
    },
    {
      id: "DEVICE_ACTIVE",
      name: "DEVICE_ACTIVE"
    },
    {
      id: "DEVICE_INACTIVE",
      name: "DEVICE_INACTIVE"
    },
    {
      id: "USER_LABEL_UPDATE",
      name: "USER_LABEL_UPDATE"
    },
    {
      id: "CHANGE_TAGS",
      name: "CHANGE_TAGS"
    },
    {
      id: "DISABLE",
      name: "DISABLE"
    },
    {
      id: "DEVICE_ORGANISATION_CHANGE",
      name: "DEVICE_ORGANISATION_CHANGE"
    },
    {
      id: "MANUAL_LICENSE",
      name: "MANUAL_LICENSE"
    },
    {
      id: "LICENSE_CFG_UPDATE",
      name: "LICENSE_CFG_UPDATE"
    },
    {
      id: "LICENSE_UPDATE",
      name: "LICENSE_UPDATE"
    },
    {
      id: "NEW_LICENSE_REQ",
      name: "NEW_LICENSE_REQ"
    },
    {
      id: "FW_UPDATE",
      name: "FW_UPDATE"
    },
    {
      id: "REMOTE_FW_UPDATE_REQ",
      name: "REMOTE_FW_UPDATE_REQ"
    },
    {
      id: "REMOTE_FW_UPDATE_FAIL",
      name: "REMOTE_FW_UPDATE_FAIL"
    },
    {
      id: "MANUAL_FW_UPDATE",
      name: "MANUAL_FW_UPDATE"
    },
    {
      id: "CLOUD_CONNECT",
      name: "CLOUD_CONNECT"
    },
    {
      id: "CLOUD_DISCONNECT",
      name: "CLOUD_DISCONNECT"
    },
    {
      id: "TRANSDUCER_PAIRING",
      name: "TRANSDUCER_PAIRING"
    },
    {
      id: "TRANSDUCER_PAIRED",
      name: "TRANSDUCER_PAIRED"
    },
    {
      id: "TRANSDUCER_PAIRING_FAILED",
      name: "TRANSDUCER_PAIRING_FAILED"
    },
    {
      id: "TRANSDUCER_DISCONNECTED",
      name: "TRANSDUCER_DISCONNECTED"
    },
    {
      id: "TRANSDUCER_CONNECTED",
      name: "TRANSDUCER_CONNECTED"
    },
    {
      id: "TRANSDUCER_DRIVER_0_ON",
      name: "TRANSDUCER_DRIVER_0_ON"
    },
    {
      id: "TRANSDUCER_DRIVER_0_OFF",
      name: "TRANSDUCER_DRIVER_0_OFF"
    },
    {
      id: "TRANSDUCER_DRIVER_1_ON",
      name: "TRANSDUCER_DRIVER_1_ON"
    },
    {
      id: "TRANSDUCER_DRIVER_1_OFF",
      name: "TRANSDUCER_DRIVER_1_OFF"
    },
    {
      id: "TRANSDUCER_IN_WATER",
      name: "TRANSDUCER_IN_WATER"
    },
    {
      id: "TRANSDUCER_OUT_WATER",
      name: "TRANSDUCER_OUT_WATER"
    },
    {
      id: "TRANSDUCER_FW_UPD",
      name: "TRANSDUCER_FW_UPD"
    },
    {
      id: "GNSS_FIX",
      name: "GNSS_FIX"
    },
    {
      id: "DEPLOYED",
      name: "DEPLOYED"
    },
    {
      id: "IN_STORAGE",
      name: "IN_STORAGE"
    },
    {
      id: "OUT_OF_STORAGE",
      name: "OUT_OF_STORAGE"
    },
    {
      id: "MANUAL_OUT_OF_STORAGE",
      name: "MANUAL_OUT_OF_STORAGE"
    },
    {
      id: "LTE_CONNECT",
      name: "LTE_CONNECT"
    },
    {
      id: "LTE_DISCONNECT",
      name: "LTE_DISCONNECT"
    },
    {
      id: "BATTERY_LOW",
      name: "BATTERY_LOW"
    },
    {
      id: "MOBILE_CONNECTION",
      name: "MOBILE_CONNECTION"
    },
    {
      id: "GEOFENCE_IN",
      name: "GEOFENCE_IN"
    },
    {
      id: "GEOFENCE_OUT",
      name: "GEOFENCE_OUT"
    }
];

export const SYSTEM_TAG = "___SYSTEM";
export const SYSTEM_TAG_IGNORE_LICENSE = "___SYSTEM_IGNORE_LICENSE";

export const SYSTEM_TAGS = [
  SYSTEM_TAG_IGNORE_LICENSE
]

export enum LastUpdateLengthType {
  NONE = "NONE",
  STORAGE = "STORAGE",
  GOOD = "GOOD",
  POOR = "POOR",
  DISCONNECTED = "DISCONNECTED"
}