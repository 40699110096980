
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_ADMIN_DISABLE } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class AdminDisableModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_DISABLE
        }
    }

    override async process(): Promise<any> {
        return this.$store.dispatch("admin/disable", {selected: this.selectedDevices });
    }

}
