
import { Component, Prop } from "vue-property-decorator";
import { Command } from "@/Utils";
import { DeviceSelected } from "@/models/DeviceSelection";
import { toast, ToastType, modal } from "@/Toast";
import RootView from "@/components/RootView";
import Dropdown from "@/components/Dropdown.vue";

import { 
    MODAL_ADMIN_UPDATE_FW, MODAL_ADMIN_ASSIGN_ORG, 
    MODAL_ADMIN_DISABLE, MODAL_ADMIN_UPDATE_COORDINATES, 
    MODAL_ADMIN_UPDATE_LICENSE, MODAL_ADMIN_UPDATE_TAGS, 
MODAL_ADMIN_STORAGE
} from "@/data";

@Component({
    components: {
        Dropdown,
    }
})
export default class AdminDeviceCommands extends RootView {

    @Prop({default: false}) showLabel?: boolean;
    @Prop({default: false}) stack?: boolean;

    commands: Command[] = [];

    beforeMount() {
      this.commands = [{
          id: "firmware",
          name: this.$t("admin_command_firmware").toString()
        }, {
          id: "license",
          name: this.$t("admin_command_license").toString()
        }, {
          id: "coordinates",
          name: this.$t("admin_command_coordinates").toString()
        }, {
          id: "assignment",
          name: this.$t("admin_command_assignment").toString()
        }, {
          id: "sync",
          name: this.$t("admin_command_sync").toString()
        }, {
          id: "tags",
          name: this.$t("admin_command_tags").toString()
        }, {
          id: "storage",
          name: this.$t("admin_command_storage").toString()
        }];
    }

    goToShadowPage() {
        window.open(`${process.env.VUE_APP_SHADOW_URL}/thing/${this.selectedDevices[0].device_id}/namedShadow/Classic%20Shadow`, "_blank");
    }

    onDisableButtonClicked() {
      if (this.device == null && this.selectedDevices.length <= 0) {
        toast({
          message: this.$t("toast_admin_missing_selection").toString(),
          type: ToastType.TOAST_ERROR
        });
        return;  
      }

      modal(MODAL_ADMIN_DISABLE);
    }

    onCommandClicked(command: Command) {
      if (this.device == null && this.selectedDevices.length <= 0) {
        toast({
          message: this.$t("toast_admin_missing_selection").toString(),
          type: ToastType.TOAST_ERROR
        });
        return;  
      }

      switch(command.id) {
        case "firmware": 
          modal(MODAL_ADMIN_UPDATE_FW);
          break;
        case "license":
          modal(MODAL_ADMIN_UPDATE_LICENSE);
          break;
        case "assignment":
          modal(MODAL_ADMIN_ASSIGN_ORG);
          break;
        case "tags":
          modal(MODAL_ADMIN_UPDATE_TAGS);
          break;
        case "coordinates":
          modal(MODAL_ADMIN_UPDATE_COORDINATES);
          break
        case "sync":
          this.commitSync();
          break;
        case "storage":
          modal(MODAL_ADMIN_STORAGE);
          break;  
        default:
          break;
      }
    }

    async commitSync() {
      const selected: DeviceSelected[] = this.selectedDevices;
      toast({
            message: "Syncing device(s).. Please wait..",
            type: ToastType.TOAST_INFO
          });
      let result = await this.$store.dispatch("admin/sync", {selected: selected});
      let body = JSON.parse(result.data).body;
      let failures: string[] = body.failures;
      
      for (let i = 0; i < selected.length; i++) {
        if (failures && failures.includes(selected[i].device_id)) {
          selected[i].success = false;
          continue;
        } 
        selected[i].success = true;
      }
    }

    
    
}
