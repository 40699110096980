
import { Component } from 'vue-property-decorator';

import DeviceModel from "@/models/DeviceModel";
import { DeviceSelected } from '@/models/DeviceSelection';

import RootView from '@/components/RootView';
import AdminViewSearchView from '@/views/AdminViewSearchView.vue';

import Checkbox from "@/components/Checkbox.vue";
import AdminListLayout from "@/components/AdminListLayout.vue";
import AdminDeviceCommands from "@/components/AdminDeviceCommands.vue";
import BoxIndicator from "@/components/BoxIndicator.vue";
import DeviceSummary from "@/components/DeviceSummary.vue";
import LastUpdateInfo from "@/components/LastUpdateInfo.vue";
import LicenseTag from "@/components/LicenseTag.vue";
import TreatmentTag from "@/components/TreatmentTag.vue";
import TransducerTag from "@/components/TransducerTag.vue";
import FilterSort from "@/components/FilterSort.vue";
import PopupModal from '@/components/PopupModal.vue';
import RegisterBar from '@/components/RegisterBar.vue';
import ConnectivityTag from "@/components/ConnectivityTag.vue";
import PlusButton from '@/components/PlusButton.vue';
import MissingData from '@/components/MissingData.vue';
import ViewButton from '@/components/ViewButton.vue';

import { MODAL_ADMIN_CREATE_EMPTY, MODAL_ADMIN_OP_SUCCESS, MODAL_ADMIN_GEN_LIC } from '@/data';
import { modal } from '@/Toast';

@Component({
    components: {
        AdminViewSearchView,
        AdminListLayout,
        Checkbox,
        AdminDeviceCommands,
        BoxIndicator,
        DeviceSummary,
        LastUpdateInfo,
        LicenseTag,
        TreatmentTag,
        FilterSort,
        PopupModal,
        RegisterBar,
        ConnectivityTag,
        TransducerTag,
        PlusButton,
        MissingData,
        ViewButton
    }
})
export default class AdminView extends RootView {

    checkboxAll = false;

    onButtonCreateClicked() {
      modal(MODAL_ADMIN_CREATE_EMPTY);
    }

    onWarnLicenseBtnClicked() {
      this.goto("/admin/licenses");
    }

    showAdminSuccessModel(data: DeviceSelected[]) {
      modal(MODAL_ADMIN_OP_SUCCESS, data);
    }

    onGenerateLicenseBtnClicked() {
      modal(MODAL_ADMIN_GEN_LIC);
    }

    onDeviceCheckboxChanged(value: boolean) {
      this.$store.commit("admin/setSearchedDevicesChecked", value);
    }

    onDeviceFilterByUpdate(direction: string) {
      this.$store.commit("admin/sortDeviceByLastUpdate", {direction: direction});
    }

    onDeviceDetailsButtonClicked(device: DeviceModel) {
      this.goto({ path: `/organisation/${device.org_id}/devices/${device.device_id}`});
    }
  
}
