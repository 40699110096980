export enum FirmwareUploadType {
    BIN = "bin",
    HEX = "hex",
    ELF = "elf"
}

export interface FirmwareUpload {
    path: string,
    type: FirmwareUploadType
}

export default interface FirmwareModel {
    firmware_id: string,
    release: number,
    version: string,
    name: string,
    path: string,
    files: FirmwareUpload[]
    description: string,
    latest?: boolean
}

