

import { Component, Prop } from "vue-property-decorator";
import Dropdown from "@/components/Dropdown.vue";
import RootView from "@/components/RootView";
import PopupModal from "@/components/PopupModal.vue";
import { Command } from "@/Utils";
import FirmwareModel from "@/models/FirmwareModel";
import Modal from "bootstrap/js/dist/modal";
import { ToastType, toast } from "@/Toast";

@Component({
    components: {
        Dropdown,
        PopupModal
    }
})
export default class AdminFirmwareCommands extends RootView {

    @Prop({default: null}) chosen: FirmwareModel | undefined;
    commands: Command[] = [];
    inputText = "";
    showError = false;

    modalAssignLatest?: Modal;

    beforeMount() {
      this.commands = [{
          id: "latest",
          name: "Assign latest firmware"
        }];
    }

    mounted() {
        this.modalAssignLatest = new Modal("#modalAssignLatest");
        this.showError = false;
    }

    triggerAssignLatestView() {
        this.inputText = "";
        this.modalAssignLatest?.show();
    }

   

    onCommandClicked(item: Command) {

        if (!this.chosen) {
            toast({
                type: ToastType.TOAST_ERROR,
                message: "Please select one of the following firmware, before proceeding.."
            })
            return;
        }

        switch(item.id) {
            case "latest":
                this.triggerAssignLatestView();
                break;
            default:
                break;
        }
    }
}
