
import {Component, Prop} from 'vue-property-decorator';
import RootView from './RootView';

@Component
export default class PasswordField extends RootView {
    @Prop({default: ""}) placeholder!: string;
    @Prop({default: ""}) id!: string;
    @Prop({default: ""}) value!: string;

    show = false;

    onDataChanged(event: any) {
        this.$emit("input", event.target.value);
        this.$emit('change', event.target.value);
    }

    toggle() {
        this.show = !this.show;
    }

    mounted() {
        this.show = false;
    }


    hide() {
        this.show = false;
    }
    
    get button(): string {
        if (this.show) return "fa-solid fa-eye-slash";
        return "fa-solid fa-eye";
    }

    get showType(): string {
        if (this.show) {
            return "text";
        }
        return "password";
    }
}
