
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ChipSearch extends Vue {

    @Prop({}) value?: any[];
    @Prop({}) label?: string;

    get items(): any[] {
        return this.value!
    }

    set items(elements: any[]) {
        this.elements = elements;
    }

    elements: any[] = this.value ?? [];

    mounted() {
        this.elements =  this.value ?? [];
    }

    onTagRemoved(item: any) {
        this.elements.splice(this.elements.indexOf(item), 1);
        this.$emit('input', this.elements);
        this.$emit('search', this.elements);
    }

    onTagChanged(items: string[]) {
        this.$emit('input', items);
        this.$emit('search', items);
    }

}
