import { Coordinates } from "../Coordinates";
import JobModel from "./JobModel";

export interface DeviceProperties<Model> {
    content: Model,
    timestamp: number
}

export interface DeviceInfo {
    imei: string,
    iccid: string,
    modV: string,
    brdV: string,
    appV: string,
    serialnumber: string,
    deployed: boolean,
    deployedCoordinates?: Coordinates
}

export interface DeviceLicense {
    expiry: number,
    id: string,
    latest?: boolean,
    creationDate?: number
}

export interface DeviceWaveform {
    calibration: number,
    isCalibrationManual: boolean,
    driver: number,
    cut: number
}

export interface DeviceTransducer {
    temperature: number,
    battery: number,
    inWater: boolean,
    inReset: boolean,
    activated: boolean,
    activatedTime: number,
    resetCount: number,
    startOpFailed: boolean,
    stopOpFailed: boolean,
    serialnumber: string,
    firmwareversion: string,
    productnumber: number,
    disabled: boolean,
    disabledReason: number,
    infoReady: boolean,
    licenseRetries: number,
    paired: boolean,
    temperatureAccurate: boolean,
    waveform: DeviceWaveform[],
    connected: boolean
}

export interface DeviceSensor {
    battery: number,
    uptime: number,
    time?: number,
    timeVal?: boolean,
}

export interface DeviceConfiguration {
    geofence?: number,
    license?: string,
    active?: boolean,
    updateInterval?: number
}

export interface DeviceRoam {
    band: number,
    nw: string,
    rsrp: number,
    area: number,
    mccmnc: number,
    cell: number,
    ip: string,
    connected?: boolean
}

export interface DeviceLocal {
    storage: boolean,
    label: string,
    tags: string[],
}

export interface DeviceCoordinates extends Coordinates {
    state?: number,
    fix?: boolean,
    accuracy: number,
    distance: number,
    latitude: number,
    longitude: number
}

export interface DeviceFlags {
    wrong_time: boolean,
    license_expired: boolean
}

export interface DeviceUserConnection {
    hasInternet: boolean,
    ip: string,
    location: string,
    phone: TemplateStringsArray,
    submissionDate: number,
    ts: number
}

export interface DeviceServerConnection {
    connected: boolean,
    raw: string,
    disconnectReason?: string,
    ip?: string,
}

export default interface DeviceModel {
    device_id: string,
    org_id: string,
    ts: number,
    src: string,
    info: DeviceProperties<DeviceInfo>,
    sensors: DeviceProperties<DeviceSensor>,
    transducer: DeviceProperties<DeviceTransducer>,
    configuration: DeviceProperties<DeviceConfiguration>,
    desiredconfiguration: DeviceProperties<DeviceConfiguration>,
    position: DeviceProperties<DeviceCoordinates>,
    roam: DeviceProperties<DeviceRoam>,
    local?: DeviceProperties<DeviceLocal>,
    license: DeviceProperties<DeviceLicense>,
    flags?: DeviceProperties<DeviceFlags>,
    userconnection?:  DeviceProperties<DeviceUserConnection>,
    serverconnection?: DeviceProperties<DeviceServerConnection>,
    licenserequest: DeviceProperties<DeviceLicense>,
    job: DeviceProperties<JobModel>,
}

export interface SimplifiedDevice {
    id: string, 
    sn: string, 
    label?: string,
    success?: boolean,
    reason?: string
}

export const deviceHasFixSingle = (device: DeviceModel): boolean => {
    if (!device.position) return false;
    return device.position?.content.fix || (!device.position.content.fix && 
    (device.position.content.latitude != undefined && device.position.content.longitude != undefined && device.position.content.latitude != 0 && device.position.content.longitude != 0));
}

export const getDevicePosition = (device: DeviceModel): any => {
    if (deviceHasFixSingle(device)) {
        return [device.position.content.latitude,device.position.content.longitude];
    }
    
    if (device.userconnection != undefined && device.userconnection?.content.location.trim().length !== 0 && 
        device.userconnection.content.location != "none") {
        return JSON.parse(device.userconnection.content.location);
    }
    return null;
}

export const checkIfCanShowInStorage = (device: DeviceModel, showInStorage: boolean) => {
    const deviceIsInStorage = device?.local?.content.storage ?? false;
    if (!showInStorage && deviceIsInStorage) return false;
    return true;
}

export const isDisplayedOnMap = (device: DeviceModel, showInStorage: boolean) => {
    return getDevicePosition(device) != null && checkIfCanShowInStorage(device, showInStorage);
}