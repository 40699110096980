
import { Component, Vue } from 'vue-property-decorator';
import { QUERY_PARAM_FIRMWARE, QUERY_PARAM_IMEI, QUERY_PARAM_LABEL, QUERY_PARAM_LICENSE_EXPIRED, QUERY_PARAM_SN, QUERY_PARAM_TAGS, QUERY_PARAM_TRANSDUCER_DISCONNECTED } from '@/Utils';
import RootView from '@/components/RootView';

import SelectDropdown from '@/components/SelectDropdown.vue';
import Searchbar from '@/components/Searchbar.vue';
import ChipSearch from '@/components/ChipSearch.vue';
import Checkbox from '@/components/Checkbox.vue';
import LastUpdateTypeSearchDropdown from "@/components/LastUpdateTypeSearchDropdown.vue";
import OrganisationSearchDropdown from "@/components/OrganisationSearchDropdown.vue";
import TreatmentTypesSearchDropdown from "@/components/TreatmentTypesSearchDropdown.vue";

@Component({components: {
    SelectDropdown,
    Searchbar,
    ChipSearch,
    Checkbox,
    LastUpdateTypeSearchDropdown,
    OrganisationSearchDropdown,
    TreatmentTypesSearchDropdown
}})
export default class AdminViewSearchView extends RootView {

    mounted() {
      this.$store.dispatch("admin/onPageRefresh");
    }

    onLabelSearch() {
      this.$store.dispatch("admin/searchDevices");

      if (this.$store.state.admin.searchedDeviceName.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_LABEL);
      } else {
        this.setQuery(QUERY_PARAM_LABEL, this.$store.state.admin.searchedDeviceName.trim());
      }
    }

    onFWSearch() {
      this.$store.dispatch("admin/searchDevices");
      if (this.$store.state.admin.searchedDeviceFW.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_FIRMWARE);
      } else {
        this.setQuery(QUERY_PARAM_FIRMWARE, this.$store.state.admin.searchedDeviceFW.trim());
      }
    }

    onSNSearch() {
      this.$store.dispatch("admin/searchDevices");

      if (this.$store.state.admin.searchedDeviceSN.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_SN);
      } else {
        this.setQuery(QUERY_PARAM_SN, this.$store.state.admin.searchedDeviceSN.trim());
      }
    }

    onIMEISearch() {
      this.$store.dispatch("admin/searchDevices");

      if (this.$store.state.admin.searchedDeviceIMEI.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_IMEI);
      } else {
        this.setQuery(QUERY_PARAM_IMEI, this.$store.state.admin.searchedDeviceIMEI.trim());
      }
    }

    onTagChanged(tags: string[]) {
      this.$store.commit("admin/setSearchedDeviceTags", tags);
      this.$store.dispatch("admin/searchDevices");

      if (tags.length > 0) {
        this.setQuery(QUERY_PARAM_TAGS, tags.join(","));
      } else {
        this.removeFromQuery(QUERY_PARAM_TAGS);
      }
    }    

    onDeviceFilterByLicenseExpired() {
      this.$store.dispatch("admin/searchDevices");

      if (this.$store.state.admin.searchedLicenseExpiredOnly) {
        this.setQuery(QUERY_PARAM_LICENSE_EXPIRED, "true");
      } else {
        this.removeFromQuery(QUERY_PARAM_LICENSE_EXPIRED);
      }
    }

    onDeviceFilterByTransducerDisconnected() {
      this.$store.dispatch("admin/searchDevices");

      if (this.$store.state.admin.searchedTransducerDisconnectedOnly) {
        this.setQuery(QUERY_PARAM_TRANSDUCER_DISCONNECTED, "true");
      } else {
        this.removeFromQuery(QUERY_PARAM_TRANSDUCER_DISCONNECTED);
      }
    }

}

