

import { Component } from 'vue-property-decorator';
import OrganisationModel from '@/models/OrganisationModel';

import RootView from "@/components/RootView";
import MissingData from '@/components/MissingData.vue';
import SettingsDropdown from '@/components/SettingsDropdown.vue';
import PopupModal from "@/components/PopupModal.vue";
import OrganisationBox from "@/components/OrganisationBox.vue";
import Searchbar from '@/components/Searchbar.vue';
import SelectDropdown from '@/components/SelectDropdown.vue';
import SummaryCard from "@/components/SummaryCard.vue";
import SummaryDetailsLayout from "@/components/SummaryDetailsLayout.vue";
import DetailsCard from "@/components/DetailsCard.vue";

import { modal } from "@/Toast";
import { Command, RoleOption } from '@/Utils';
import { MODAL_EDIT_PASSWORD, MODAL_EDIT_PROFILE } from "@/data";
import ClassicMainContainerView from '@/views/ClassicMainContainerView.vue';

@Component({
    components: {
        ClassicMainContainerView,
        MissingData,
        SettingsDropdown,
        PopupModal,
        OrganisationBox,
        Searchbar,
        SelectDropdown,
        SummaryCard,
        SummaryDetailsLayout,
        DetailsCard
    }
})
export default class ProfileView extends RootView {

    commands: Command[] = [];

    onCommandClicked(command: Command) {
        switch (command.id) {
            case "edit_password":
                modal(MODAL_EDIT_PASSWORD);
                break;
            case "edit_profile":
                modal(MODAL_EDIT_PROFILE);
                break;
            default:
                break;
        }
    }

    get organisationBoxStyle(): string {
        if (this.isMobile) {
            return "height: 480px;"
        }
        return "height: 100%";
    }

    viewOrganisation(organisation: OrganisationModel) {
        this.goto({
            path: `/organisation/${organisation.org_id}`,
        })
    }

    beforeMount() {
        this.commands = [{
          id: "edit_password",
          name: this.$t("profile_command_changepassword").toString()
        }, {
          id: "edit_profile",
          name: this.$t("profile_command_editprofile").toString()
        }];
    }

    commitRoleChange(role: any) {
        let elem: any | undefined = role;
        if (elem.id == "all") {
            elem = undefined;
        }

        this.$store.commit("organisations/setSearchedOrganisationRole", elem);
    }

    onSearchRoleChange(role: RoleOption | undefined) {
        this.commitRoleChange(role);
        this.onSearch();
    }

    onSearchRoleClear() {
        this.$store.commit("organisations/setSearchedOrganisationRole", undefined);
        this.onSearch();
    }

    onSearch() {
        this.$store.dispatch("organisations/search");
    }

}
