
import { Component, Prop } from "vue-property-decorator"
import RootView from "@/components/RootView";
import { Tab } from "@/Utils";

@Component
export default class TabBar extends RootView {
    @Prop({default: () => {
        return [];
    }}) items!: Tab[] 
    currentTabId = "";

    mounted() {
        if (this.items.length > 0) {
            this.currentTabId = this.items[0].id;
        }
    }

    chooseTab(tab: Tab) {
        this.currentTabId = tab.id;
        this.$emit("click", tab.id);
    }

    isActive(tab: Tab): string {
        if (tab.id == this.currentTabId) {
            return "active";
        }
        return "";
    }
}
