
import { Component} from "vue-property-decorator";
import SelectDropdown from "@/components/SelectDropdown.vue";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_ADMIN_UPDATE_FW } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        SelectDropdown
    }
})
export default class AdminUpdateFirmwareModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_UPDATE_FW
        }
    }
    override process(): Promise<any> {
      return this.$store.dispatch("admin/updateFirmware", {options: {firmware: this.$refs.firmwareDropdown.value.firmware_id}, selected: this.selectedDevices});
    }
}
