export const emptyString = (val: string) : boolean => {
    return val.trim() == "";
}

export const emptyArray = (val: any[]): boolean => {
    return val.length <= 0;
}

export const falseBoolean = (val: boolean): boolean => {
    return !val;
}

export const specificString = (data: string): (val: string) => boolean => {
    return (val: string) => {
        return val.trim() == data.trim();
    };
}

export interface FieldSearchRequirement<T, V> {
    default: (val: V) => boolean,
    validation: (val: V, item: T) => boolean
}

export const searchItems = <T, L extends object>(fields: Map<string, FieldSearchRequirement<any, any>>): (items: T[], search: L) => T[] => {
    return (items: T[], search: L) => {
        let isDefault = false;
        let defaults = 0; 
        for (const [key, value] of Object.entries(search)) {
            if (fields.has(key) && fields.get(key)?.default(value)) {
                defaults++;
            }
        }

        if (defaults >= fields.size) {
            isDefault = true;
        }

        const results: T[] = [];

        let skip = false;
        for (let x = 0; x < items.length; x++) {
            const item: T = items[x];
            skip = false;

            for (const [key, value] of Object.entries(search)) {
                if (fields.has(key) && fields.get(key)?.validation(value, item)) {
                    skip = true;
                    break;
                }
            }

            if (!isDefault && skip) {
                continue;
            }

            results.push(item);
        }
          
        return results;
    }
}