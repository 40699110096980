
import {Vue, Component, Prop} from "vue-property-decorator";
import { hideToast } from "../Toast";
import RootView from "./RootView";

@Component
export default class BackButton extends RootView {
    @Prop() text?: string
    @Prop({default: null}) link!: string | null; 

    onGoBackClicked() {
        hideToast();
        if (this.link != null) {
            this.goto({path: this.link}, true);
            return;
        }
        this.goBack();
    }
}
