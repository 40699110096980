import Modal from "bootstrap/js/dist/modal";
import {eventBus} from "./main";
import { RawLocation } from "vue-router";

export enum ToastType {
  TOAST_ERROR,
  TOAST_SUCCESS,
  TOAST_INFO
}

export interface ToastMessage {
    message: string,
    type: ToastType
}

export interface ModalRegisterMessage {
    name: string,
    modal: Modal,
    callback?: (data: any) => void,
}

export enum GotoMessageType {
    GOTO_PUSH,
    GOTO_GO
}

export interface GotoMessage {
    type: GotoMessageType,
    path: RawLocation | number,
    noLoading?: boolean,
}

export interface ModalSrc {
    modal: Modal,
    callback?: (data: any) => void,
}

export const goTo = (message: GotoMessage) => {
    eventBus.$emit("go", message);
}

export const toast = (message: ToastMessage) => {
    eventBus.$emit("toast", message);
}

export const hideToast = () => {
    eventBus.$emit("toast-hide");
}

export const hideLoading = () => {
    eventBus.$emit("loading-hide");
}

export const modal = (name: string, data: any | undefined = undefined, retry = false) => {
    eventBus.$emit("modal", {
        name: name,
        data: data,
        retry: retry
    });
}

export const hideModal = () => {
    eventBus.$emit("modal-hide");
}

export const registerModal = (message: ModalRegisterMessage) => {
    eventBus.$emit("modal-register", message);
}