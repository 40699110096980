
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DangerButton extends Vue {
    @Prop({default: false}) disabled!: boolean;

    onBtnClick() {
        this.$emit("click");
    }
}
