
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import SelectDropdown from "@/components/SelectDropdown.vue";
import { QUERY_PARAM_LAST_UPDATE_TYPE } from "@/Utils";

@Component({
    components: {
        SelectDropdown
    }
})
export default class LastUpdateTypeSearchDropdown extends RootView {
    
    @Prop({default: "admin/setSearchedLastUpdateTypes"}) commitTo!: string;
    @Prop({default: "admin/searchDevices"}) search!: string;
    
    $refs!: {
      updateTypeDropdown: SelectDropdown,
    }

    mounted() {
        if (!this.$store.state.admin.searchedLastUpdateType) return;
        const updateTypes = this.updateTypes;
        let result = undefined;

        for (let i = 0; i < updateTypes.length; i++) {
          if (updateTypes[i].value == this.$store.state.admin.searchedLastUpdateType)  {
            result = updateTypes[i];
          }
        }

        if (result) {
          this.commitLastUpdateTypeChange(result);
          this.$refs.updateTypeDropdown.forceValue(result);
        }
    }

    commitLastUpdateTypeChange(updateType: any) {
      let elem: any | undefined = updateType;
      if (elem.key == "all") {
        elem = undefined;
      }

      this.$store.commit(this.commitTo, elem);
    }

    onLastUpdateTypeChange(updateType: any) {
      this.commitLastUpdateTypeChange(updateType);
      this.$store.dispatch(this.search);
      this.setQuery(QUERY_PARAM_LAST_UPDATE_TYPE, updateType.value.toLowerCase());
    }

    onLastUpdateTypeClear() {
      this.$store.commit(this.commitTo, undefined);
      this.$store.dispatch(this.search);
      this.removeFromQuery(QUERY_PARAM_LAST_UPDATE_TYPE);
    }
}
