
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_ADMIN_CREATE_ORG } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class AdminCreateOrganisationModalView extends RootModal {
    name = "";
    phone = "";
    email = "";
    website = "";
    address = "";
    showError = false;
    
    reset() {
        this.name = "";
        this.email = "";
        this.address = "";
        this.phone = "";
        this.website = "";
        this.showError = false;
    }

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_CREATE_ORG
        };
    }

    override validations(): Promise<boolean> {
        if (this.name.trim() == "" || this.email.trim() == "" || 
            this.address.trim() == "" || this.phone.trim() == "" || this.website.trim() == "") {
                this.showError = true;
                return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    override process(): Promise<any> {
        return this.$store.dispatch("admin/createOrganisation", {
            name: this.name,
            email: this.email,
            address: this.address,
            phone: this.phone,
            website: this.website
        }).then(() => {
            return this.$store.dispatch("organisations/fetchOrganisations", {admin: true})
        });
    }
}
