import { VuexModule, Mutation, Module } from "vuex-class-modules";
import { hasProperty } from "@/Utils";

@Module
export default class GlobalModule extends VuexModule {

    query: any;

    @Mutation
    setQuery(query: any) {
        this.query = query;
    }

    @Mutation
    addToQuery(payload: {key: string, value: string}) {
        if (hasProperty(this.query, payload.key) && this.query[payload.key] == payload.value) {
            return;
        }
        this.query[payload.key] = payload.value;
    }
    
    @Mutation
    removeFromQuery(key: string) {
        if (hasProperty(this.query, key)) {
            delete this.query[key];
        }
    }
}