
import RootView from "@/components/RootView";
import { Prop, Component } from "vue-property-decorator";

import MissingData from "@/components/MissingData.vue";

@Component({
    components: {
        MissingData
    }
})
export default class DetailsCard extends RootView {
    @Prop({default: ""}) title!: string;
    @Prop({default: false}) condition!: boolean;
    @Prop({default: ""}) missing_title!: string;
    @Prop({default: false}) search!: boolean;
}
