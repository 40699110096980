
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import EditInfo from "@/components/EditInfo.vue";
import ErrorList from "@/components/ErrorList.vue";
import { MODAL_EDIT_PROFILE } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";
import { validatePhone } from "@/Utils";

@Component({
    components: {
        DialogPopupModal,
        EditInfo,
        ErrorList
    }
})
export default class EditProfileModalView extends RootModal {
    name = "";
    phone = "";
    website = "";
    errors: string[] = [];

    override setup(): ModalSetup {
        return {
            name: MODAL_EDIT_PROFILE
        }
    }
    
    reset() {
        this.errors = [];
        this.name = this.$store.state.user.user.name;
        this.phone = this.$store.state.user.user.phone;
        this.website = this.$store.state.user.user.website;

        this.$refs.editInfoName.stop();
        this.$refs.editInfoPhone.stop();
        this.$refs.editInfoWebsite.stop();
    }

    override validations(): Promise<boolean> {
        this.errors = [];
        let errors: string[] = validatePhone(this.phone);
        if (errors.length > 0) {
            this.errors = errors;
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    override process(): Promise<any> {
        return this.$store.dispatch('user/edit', {
            phone: this.phone,
            name: this.name,
            website: this.website
        })
    }
}
