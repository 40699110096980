
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import FileUploader from "@/components/FileUploader.vue";
import { MODAL_ADMIN_CREATE_FW } from "@/data";
import { isValidVersion, FileUpload } from "@/Utils";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        FileUploader
    }
})
export default class AdminCreateFirmwareModalView extends RootModal {
    version = "";
    name = "";
    description = "";
    showError = false;
    files: FileUpload[] = [];
    
    rules = {
      version: (value: string): any => isValidVersion(value) || "Invalid version format.",
      name: (value: string): any => value.trim().length > 3 || "Name should be minimum more than 3 characters."
    }

    override setup(): ModalSetup {
      return {
          name: MODAL_ADMIN_CREATE_FW
      }
    }
    
    reset() {
        (this.$refs.uploader as any).clear();
        this.version = "";
        this.name = "";
        this.description = "";
        this.showError = false;
        this.files = [];
    }

    override validations(): Promise<boolean> {
        if (!this.hasAtleastOneBinFile(this.files)) {
            this.showError = true;
            return Promise.resolve(false);
        }

        if (!(
            this.rules.name(this.name) === true &&
            this.rules.version(this.version) === true
        )) {
          this.showError = true;
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }
    
    override process(): Promise<any> {
        return this.$store.dispatch("firmwares/createFirmware", {
          version: this.version,
          name: this.name,
          files: this.files,
          description: this.description
        });
    }

    hasAtleastOneBinFile(files: FileUpload[]): boolean {
        for (let i = 0; i < files.length; i++) {
            if (typeof files[i].type !== "string" && (files[i].type as any).id == 'bin') {
              return true;
            }
        }
        return false;
    }

}
