
import { genUUID } from "@/Utils";
import { Vue, Prop, Component } from "vue-property-decorator";
import PopupModal from "@/components/PopupModal.vue";

@Component({
    components: {
        PopupModal
    }
})
export default class DialogPopupModal extends Vue {
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) subtitle!: string;
    @Prop({default: false}) fullscreen!: boolean;
    @Prop({default: false}) titlestart!: boolean;
    @Prop({default: ""}) size!: string;

    id = genUUID();

    get modalId() {
        return `modal_${this.id}`;
    }

  mounted() {
    // eslint-disable-next-line
    setTimeout(() => {
      // eslint-disable-next-line
      let that = this;
      document.querySelector(`#${this.modalId}`)!.addEventListener('show.bs.modal', function () {
        that.$emit("open");
      });
    }, 200)
    
  }

  get classValue() {
    let val = "";

    if (this.fullscreen) {
      val += "modal-fullscreen"
    }

    let size = this.sizeValue;

    if (size.length > 0) {
      val += ` ${size}`;
    }

    return val.trim();
  }

  get sizeValue() {
    if (this.size?.trim().length <= 0) return "";
    return `modal-${this.size}`;
  }

  confirm() {
        this.$emit("confirm");
  }
}
