import { Vue, Prop, Component } from "vue-property-decorator";
import DeviceModel, { deviceHasFixSingle, DeviceLicense, DeviceProperties, DeviceUserConnection, DeviceWaveform, getDevicePosition, isDisplayedOnMap } from "@/models/DeviceModel";
import { LatLng } from "leaflet";
import OrganisationModel from "@/models/OrganisationModel";
import { LastUpdateLengthType, TREATMENT_TYPES, UPDATE_TYPES } from "@/data";
import { UserRole } from "@/store/modules/AuthModule";
import { DeviceSelected } from "@/models/DeviceSelection";

import { 
    UnitDateType, betweenDaysConversion, localIsoDateString, 
    dateStrFormat, isStringEmpty, hasProperty, JobStatusRange, 
    getJobStatusFromStr, RoleOption, capitalize, activatedTime,
    selection, dateStrFormatWithTime
} from "@/Utils";
import { RawLocation } from "vue-router";
import { GotoMessageType, goTo } from "@/Toast";
import { isMobile } from "mobile-device-detect";

import BUOY_DEFAULT_ICON from "@/assets/default_buoy_marker.svg";
import BUOY_STORAGE_ICON from "@/assets/EMF-STORAGE.svg";
import BUOY_OFF_GOOD_ICON from "@/assets/EMF-OFF-GOOD.svg";
import BUOY_OFF_POOR_ICON from "@/assets/EMF-OFF-POOR.svg";
import BUOY_OFF_DISCONNECTED_ICON from "@/assets/EMF-OFF-DISCONNECTED.svg";
import BUOY_ON_GOOD_ICON from "@/assets/EMF-ON-GOOD.svg";
import BUOY_ON_POOR_ICON from "@/assets/EMF-ON-POOR.svg";
import BUOY_ON_DISCONNECTED_ICON from "@/assets/EMF-ON-DISCONNECTED.svg";

@Component
export default class RootView extends Vue {

    @Prop({default: null}) device!: DeviceModel | null;
    @Prop({default: 0}) index!: number;    

    selectRole?: UserRole;
    selectRoles: RoleOption[] = [
        {
            id: UserRole.GUEST,
            name: this.$t("role_guest").toString()
        },
        {
            id: UserRole.ADMIN,
            name: this.$t("role_admin").toString()
        },
        {
            id: UserRole.MEMBER,
            name: this.$t("role_member").toString()
        },
        {
            id: UserRole.OWNER,
            name: this.$t("role_owner").toString()
        }
    ]
    
    get isMobile(): boolean {
        return isMobile;
    }

    get chooseDevice(): DeviceModel | null {
        if (this.device == null) {
            return this.$store.state.device.device;
        }
        return this.device;
    }

    get inStorage(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        return device.local?.content.storage ?? false;
    }

    get updateTypeStr(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        return `update_src_${device.src.toLowerCase()}`;
    }

    get treatmentStatus(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        return device?.transducer?.content.activated;
    }

    get buoybatterypercent(): string {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return this.batteryText(0);
        if (!device.sensors) return this.batteryText(0);
        return this.batteryText(device.sensors.content.battery, this.isAdmin);
    }

    get transducerbatterypercent(): string {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return this.batteryText(0);
        if (!device.transducer) return this.batteryText(0);
        return this.batteryText(device.transducer.content.battery, this.isAdmin);
    }

    get activatedTime(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        const time = device.transducer?.content.activatedTime ?? 0;
        return this.fromZeroTimeStr(time);
    }

    get temperature(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (this.isAdmin && !(device.transducer?.content.temperatureAccurate ?? true)) {
            return `${device.transducer?.content.temperature} [NOT_ACCURATE]`;
        }
        return `${device.transducer?.content.temperature} °C`;
    }

    get location(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        const position: LatLng = this.position;
        return `[${position.lat}, ${position.lng}]`
    }

    get imei(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        return device.device_id;
    }

    get armSN(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.transducer) return undefined;
        if (this.isAdmin) {
            return `${device.transducer.content.serialnumber} (${device.transducer.content.productnumber})`
        }
        return `${device.transducer.content.serialnumber}`;
    }

    get signalstate(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;

        if (!device.roam?.content.connected ?? true) {
            return "disconnected";
        }

        const rsrp: number = device?.roam.content.rsrp ?? -1;

        if (rsrp > -90) {
            return "excellent";
        }

        if (rsrp >= -105) {
            return "good";
        }

        if (rsrp >= -106 && rsrp <= -120) {
            return "fair";
        }

        return "poor";
    }

    get rsrp(): number | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        return device.roam.content.rsrp ?? -1;
    }

    get uptime(): string {
        const uptime = this.chooseDevice?.sensors?.content.uptime ?? 0;
        return this.fromZeroTimeStr(Math.round(uptime / 1000 / 60));
    }

    get hasLicenseUpdatePending(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.licenserequest || !device.licenserequest.content) return false;
        const targetExpTs: number = device.licenserequest.content.expiry;
        let currentExpTs = 0;

        if (device.license && device.license.content) {
            currentExpTs = device.license.content.expiry;
        }
        return targetExpTs > currentExpTs;
    }

    get isTreatmentInTransition(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.transducer || !device.transducer.content || device.transducer.content.activated == undefined) return false;
        if ((!device.configuration || !device.configuration.content || device.configuration.content.active == undefined) && 
            (!device.desiredconfiguration || !device.desiredconfiguration.content ||  device.desiredconfiguration.content.active == undefined)) return false;
        let configActive = false;
        if (device.configuration && device.configuration.content && device.configuration.content.active != undefined) {
            configActive = device.configuration.content.active!;
        }
        if (device.desiredconfiguration && device.desiredconfiguration.content && device.desiredconfiguration.content.active != undefined) {
            configActive = device.desiredconfiguration.content.active!;
        }

        return configActive != device.transducer.content.activated;
    }

    get isInWater(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        return device.transducer.content.inWater;
    }

    get hasPriviledgeToEditDevice() : boolean {
        if (!this.$store.state.auth.user) return false;
        const role: UserRole =  this.$store.state.auth.user.memberships.get(this.chooseDevice?.org_id);
        return role == UserRole.ADMIN || role == UserRole.OWNER || role == UserRole.MEMBER || this.$store.state.auth.user.internals.includes("ADMIN");
    }
    
    get treatmentDesiredState(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.transducer || !device.transducer.content || device.transducer.content.activated == undefined) return false;
        if ((!device.configuration || !device.configuration.content || device.configuration.content.active == undefined) && 
            (!device.desiredconfiguration || !device.desiredconfiguration.content ||  device.desiredconfiguration.content.active == undefined)) return false;
        let configActive = false;
        if (device.configuration && device.configuration.content && device.configuration.content.active != undefined) {
            configActive = device.configuration.content.active!;
        }
        if (device.desiredconfiguration && device.desiredconfiguration.content && device.desiredconfiguration.content.active != undefined) {
            configActive = device.desiredconfiguration.content.active!;
        }
        return configActive;
    }

    get isAdmin(): boolean {
        return this.$store.state.auth.user && this.$store.state.auth.user.internals.includes("ADMIN");
    }

    get isTreatmentOn() {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.configuration || !device.transducer) return false;
        return device.configuration.content.active && device.transducer.content.activated;
    }

    get isCloudConnected(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.serverconnection) return false;
        return device.serverconnection.content.connected;
    }

    get isOnDevicePage(): boolean {
        return (!this.$route.fullPath.includes("admin")) && this.chooseDevice != null;
    }

    get isOnDeviceDetailsPage(): boolean {
        return this.$route.fullPath.includes("organisation") && this.$route.fullPath.includes("devices");
    }

    get hasInvitation(): boolean {
        return this.$store.state.invite.hasInvitation;
    }

    get label(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.local) return undefined;
        if (!device.local.content.label) return undefined;
        if (device.local.content.label.trim().length <= 0) return undefined;
        return device.local.content.label;
    }

    get serialNumber(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.info) return undefined;
        if (!device.info.content.serialnumber) return undefined;
        if (device.info.content.serialnumber.trim().length <= 0) return undefined;
        return device.info.content.serialnumber;
    }
    
    get selectedDevices(): DeviceSelected[] {
        const format = (value: DeviceModel) => {
            return {
                org_id: value.org_id,
                device_id: value.device_id ?? value.info?.content.imei ?? "",
                serialnumber: value.info?.content.serialnumber ?? "",
                data: value,
            }
          }

        if (this.isOnDevicePage && this.chooseDevice != null) {
          return selection<DeviceModel>([{
              checked: true,
              model: this.chooseDevice
          }]).map(format);
        }

        return selection<DeviceModel>(this.$store.state.admin.searchedDevices).map(format);
    }
      
    get hasFix(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.position) return false;
        return device.position?.content.fix || (!device.position.content.fix && 
        (device.position.content.latitude != undefined && device.position.content.longitude != undefined && device.position.content.latitude != 0 && device.position.content.longitude != 0));
    }

    get hasBeenDeployed(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.info) return false;
        return device.info.content.deployed;
    }

    get isTransducerLicenseGoingToExpire() {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.transducer) return false;
        return this.isLicenseAboutToExpire(device.license.content);
    }

    get isTransducerConnected(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.transducer) return true;
        return device.transducer.content.connected;
    }

    get isTransducerPairing(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.transducer || !device.transducer.content) return false;
        if (!device.transducer.content.connected) return false;
        return !device.transducer.content.paired;
    }

    get isTransducerLicenseExpired(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.license || !device.license.content) return false;
        return this.hasLicenseExpired(device.license.content);
    }

    get position(): LatLng {
        const device: DeviceModel | null =  this.chooseDevice;
        if (device == null) return new LatLng(0, 0);
        if (!device.position) return new LatLng(0, 0);
        if (this.hasFix) {
            return new LatLng(device.position.content.latitude, device.position.content.longitude);
        }
        return new LatLng(device.info.content.deployedCoordinates?.latitude ?? 0, device.info.content.deployedCoordinates?.longitude ?? 0);
    }

    get roleName(): string {
        return  this.$store.state.organisation_users.searchRole.trim().length > 0 ? capitalize(this.$store.state.organisation_users.searchRole.toLowerCase()) : this.$t("all").toString();
    }
  
    get isDoingFwUpdate(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.job) return false;
        return getJobStatusFromStr(device.job.content.status!) == JobStatusRange.IN_PROGRESS;
    }
  
    get hasFwUpdateReady(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.job) return false;
        return getJobStatusFromStr(device.job.content.status!) == JobStatusRange.QUEUED;
    }
  
    get fwText(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.info) return undefined;
        return `v${device.info.content.appV}`;
    }

    get armFwText(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.transducer) return undefined;
        return `v${device.transducer.content.firmwareversion}`
    }

    get currentJobFwText(): string {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return "";
        if (device.job) {
            return device.job.content.path?.substring(0, device.job.content.path.indexOf("/")) ?? "";
        }
        return "";
    }
      
    get deployedText(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.info) return undefined;
        
        if (this.hasBeenDeployed) {
            const position = this.position;
            return `deployed at (${position.lat},${position.lng})`;
        }
        return "not deployed";
    }

    get deviceTimeText(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.sensors) return undefined;
        if (!device.sensors.content.time) return undefined;
        return `${this.toCurrentTime(device.sensors.content.time)} [${device.sensors.content.time}]`
    }

    get deviceIpText(): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.roam) return undefined;
        if (device.roam.content.ip.trim().length == 0) return undefined;
        return device.roam.content.ip;
    }

    get treatmentTypes() {
        const treatmentTypes = TREATMENT_TYPES;
        const results: any[] = [];
        results.push({value: "all", name: this.$t("all")});
        for (let i = 0; i < treatmentTypes.length; i++) {
            results.push({value: treatmentTypes[i], name: this.$t(treatmentTypes[i])});
        }
        return results;
    }
  
    get roles() {
        const results: any[] = [];
        results.push({id: "all", name: this.$t("all")});
        for (let i = 0; i < this.selectRoles.length; i++) {
          results.push(this.selectRoles[i]);
        }
        return results;
    }

    get rolesOnlySingle() {
        const results: any[] = [];
        for (let i = 0; i < this.selectRoles.length; i++) {
          results.push(this.selectRoles[i]);
        }
        return results;
    }

    get updateTypes() {
        const updateTypes = UPDATE_TYPES;
        const results: any[] = [];
        results.push({value: "all", name: this.$t("all")});
        for (let i = 0; i < updateTypes.length; i++) {
        results.push({value: updateTypes[i], name: this.$t(`update_type_${updateTypes[i]}`)});
        }
        return results;
    }

    get hasSerialNumber(): boolean {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return false;
        if (!device.info) return false;
        return !isStringEmpty(device.info.content.serialnumber.trim());
    }

    get signalstateValue(): number | undefined {
        if (!this.chooseDevice?.roam?.content.connected ?? true) {
            return undefined;
        }

        return this.chooseDevice?.roam.content.rsrp ?? -1;        
    }

    isTreatmentOnForDevice(device: DeviceModel): boolean {
        if (!device.configuration || !device.transducer) return false;
        return device.configuration.content.active! && device.transducer.content.activated;
    }

    isTransducerPairingForDevice(device: DeviceModel): boolean {
        if (!device.transducer || !device.transducer.content) return false;
        if (!device.transducer.content.connected) return false;
        return !device.transducer.content.paired;
    }

    isTransducerConnectedForDevice(device: DeviceModel): boolean {
        if (!device.transducer) return true;
        return device.transducer.content.connected;
    }

    hasLicenseUpdatePendingForDevice(device: DeviceModel): boolean {
        if (!device.licenserequest || !device.licenserequest.content) return false;
        const targetExpTs: number = device.licenserequest.content.expiry;
        let currentExpTs = 0;

        if (device.license && device.license.content) {
            currentExpTs = device.license.content.expiry;
        }
        return targetExpTs > currentExpTs;
    }

    isTreatmentInTransitionForDevice(device: DeviceModel): boolean {
        if (!device.transducer || !device.transducer.content || device.transducer.content.activated == undefined) return false;
        if ((!device.configuration || !device.configuration.content || device.configuration.content.active == undefined) && 
            (!device.desiredconfiguration || !device.desiredconfiguration.content ||  device.desiredconfiguration.content.active == undefined)) return false;
        let configActive = false;
        if (device.configuration && device.configuration.content && device.configuration.content.active != undefined) {
            configActive = device.configuration.content.active!;
        }
        if (device.desiredconfiguration && device.desiredconfiguration.content && device.desiredconfiguration.content.active != undefined) {
            configActive = device.desiredconfiguration.content.active!;
        }

        return configActive != device.transducer.content.activated;
    }

    treatmentDesiredStateForDevice(device: DeviceModel) {
        if (!device.transducer || !device.transducer.content || device.transducer.content.activated == undefined) return false;
        if ((!device.configuration || !device.configuration.content || device.configuration.content.active == undefined) && 
            (!device.desiredconfiguration || !device.desiredconfiguration.content ||  device.desiredconfiguration.content.active == undefined)) return false;
        let configActive = false;
        if (device.configuration && device.configuration.content && device.configuration.content.active != undefined) {
            configActive = device.configuration.content.active!;
        }
        if (device.desiredconfiguration && device.desiredconfiguration.content && device.desiredconfiguration.content.active != undefined) {
            configActive = device.desiredconfiguration.content.active!;
        }
        return configActive;
    }

    driverstatus(i: number): string | undefined {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) return undefined;
        if (!device.transducer) return undefined;
        if (!device.transducer.content) return undefined;
        if (!device.transducer.content.waveform) return undefined;
        if (device.transducer.content.waveform.length == 0) return undefined;
        if (i < 0 || i > device.transducer.content.waveform.length - 1) return undefined;
        const selection: DeviceWaveform =  device.transducer.content.waveform[i];
        return `${selection.driver}/${selection.cut}/${selection.calibration} (${selection.isCalibrationManual})`;
    }

    capitalize(val: string) {
        return capitalize(val);
    }
    
    batteryText(batMv: number, showMv = false) {
        const max = 4200;
        const min = 3350;
        const bat = batMv > max ? max : batMv < min ? min : batMv;
        let str = `${Math.round((bat - min) / (max - min) * 100)}%`;
        if (showMv) {
            str += ` [${batMv}mv]`
        }
        return str;
    }

    hasFixSingle(device: DeviceModel): boolean {
        return deviceHasFixSingle(device);
    }

    roleFromString(str: string): UserRole {
        switch(str) {
            case "admin":
                return UserRole.ADMIN;
            case "owner":
                return UserRole.OWNER;
            case "member":
                return UserRole.MEMBER;
            case "guest":
            default:
                return UserRole.GUEST;
        } 
    }

    toCurrentTime(time: number): string {
        return new Date(time).toISOString();
    }

    fromZeroTimeStr(time: number): string {
        const result: {days: number, hours: number, minutes: number} = activatedTime(time);
        let string = `${result.hours.toString().padStart(2, '0')}h:${result.minutes.toString().padStart(2, '0')}m [${time}]`;
        if (result.days > 0) {
            string = `${result.days}d, ` + string;
        }
        return string;
    }
    
    setQuery(key: string, value: string) {
        this.$store.commit("global/addToQuery", {key: key, value: value});
        this.updatePageQuery();
    }

    goAtBase() {
        goTo({type: GotoMessageType.GOTO_GO, path: 0});
    }

    goBack() {
        goTo({type: GotoMessageType.GOTO_GO, path: -1});
    }

    goto(path: RawLocation, noLoading = false) {
        goTo({type: GotoMessageType.GOTO_PUSH, path: path, noLoading: noLoading});
    }

    strOrDefault(value: string | undefined = undefined) {
        if (!value) return this.$t("not_available");
        if (value.trim().length > 0) return value;
        return this.$t("not_available");
    }

    removeFromQuery(key: string) {
        this.$store.commit("global/removeFromQuery", key);
        this.updatePageQuery();
    }

    updatePageQuery() {
        this.$router.push({query: this.$store.state.global.query});
    }

    wrapOrganisations(organisations: OrganisationModel[]) {
        const results: any[] = [];
  
        results.push({org_id: "all", name: this.$t("all")});
        for (let i = 0; i < organisations.length; i++) {
          results.push(organisations[i]);
        }
  
        return results;
    }

    isLicenseAboutToExpire(license: DeviceLicense | undefined): boolean  {
        if (!license) {
            return true;
        }
        const nowDate = new Date();
        const futureDate = new Date(license.expiry);
        const date: {value: number, unit: UnitDateType} = betweenDaysConversion(nowDate, futureDate);
        return date.unit == UnitDateType.DAY && date.value <= 14;
    }

    licenseValidityDaysText (license: DeviceLicense | undefined): string {
        if (!license) {
          return "";
        }
    
        if (this.hasLicenseExpired(license)) {
            return "";
        }
    
        const nowDate = new Date();
        const futureDate = new Date(license.expiry);
    
        const date: {value: number, unit: UnitDateType} = betweenDaysConversion(nowDate, futureDate);
        return `${date.value} ${this.$t(date.unit)}`;
    }

    untilLicenseIsValidText (license: DeviceLicense | undefined): string  {
        if (!license) {
            return "N/A";
        }
        
        if (this.hasLicenseExpired(license)) {
            return "";
        }
        
        return localIsoDateString(license.expiry);
    }

    hasLicenseExpired(license: DeviceLicense | undefined): boolean {
        if (!license) {
            return true;
        }
    
        const now = new Date().getTime();
        return now > license.expiry;
    }

    dateFormatStr(timestamp: number) {
        return dateStrFormat(timestamp);
    }

    dateFormatStrWithTime(timestamp: number) {
        return dateStrFormatWithTime(timestamp);
    }

    lastUpdateSinceText(ts: number): string {
        return localIsoDateString(ts);
    }

    getPosition(device: DeviceModel): any {
        return getDevicePosition(device);
    }

    isLocationValid(userconnection: DeviceProperties<DeviceUserConnection>) {
        if (!userconnection || userconnection && userconnection.content.location.trim().length === 0) {
            return false;
        }
        return true;
    }

    lastUpdateAgoText(ts: number): string {
        const nowDate = new Date();
        const tsDate = new Date(ts);
        const date: {value: number, unit: UnitDateType} = betweenDaysConversion(tsDate, nowDate, true, true);
        return `${date.value} ${this.$t(date.unit)}`;
    }

    lastUpdateLengthType(device: DeviceModel | undefined | null): LastUpdateLengthType {
        if (!device) return LastUpdateLengthType.NONE;
        
        if (device.local?.content.storage ?? false) {
          return LastUpdateLengthType.STORAGE;
        }
        const nowDate = new Date();
        const previousDate: Date = new Date(device.ts);
  
        const result: {value: number, unit: UnitDateType} = betweenDaysConversion(previousDate, nowDate, true);
        if (result.unit == UnitDateType.MINUTE || (result.unit == UnitDateType.HOUR && result.value == 1)) {
          return LastUpdateLengthType.GOOD;
        }
        if (result.unit == UnitDateType.HOUR || (result.unit == UnitDateType.DAY && result.value <= 20)) {
          return LastUpdateLengthType.POOR;
        }
  
        return LastUpdateLengthType.DISCONNECTED;
    }

    deviceIcon(device: DeviceModel | undefined | null) {
        const lastUpdateLengthType: LastUpdateLengthType = this.lastUpdateLengthType(device);

        if (lastUpdateLengthType == LastUpdateLengthType.NONE) {
            return BUOY_DEFAULT_ICON;
        }
        if (lastUpdateLengthType == LastUpdateLengthType.STORAGE) {
            return BUOY_STORAGE_ICON;
        }

        const treatment: boolean = device?.transducer?.content.activated ?? false;

        switch(lastUpdateLengthType) {
            case LastUpdateLengthType.POOR:
                return treatment ? BUOY_ON_POOR_ICON : BUOY_OFF_POOR_ICON;
            case LastUpdateLengthType.GOOD:
                return treatment ? BUOY_ON_GOOD_ICON : BUOY_OFF_GOOD_ICON;
            case LastUpdateLengthType.DISCONNECTED:
            default:
                return treatment ? BUOY_ON_DISCONNECTED_ICON : BUOY_OFF_DISCONNECTED_ICON;
        }
    }

    isDisplayedOnMap(device: DeviceModel): boolean {
        return isDisplayedOnMap(device, this.$store.state.organisation_devices.showInStorage);
    }

}