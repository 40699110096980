

import { Component, Prop } from 'vue-property-decorator';
import RootView from './RootView';

@Component
export default class TreatmentTag extends RootView 
{
    @Prop({default: true}) inline!: boolean;

    get defaultClass() {
        if (this.inline) {
            return "d-inline-block";
        }
        return "w-100";
    }

    get bgWidth() {
        if (this.isMobile) {
            return "w-100";
        }
        return "w-85";
    }

    get treatmentOnColor() {
        if (this.hasLicenseExpired(this.chooseDevice?.license.content)) {
            return "alert-danger";
        }
        return "alert-success";
    }

    get isTreatmentError() {
        return this.isTreatmentOn && ( !this.isTransducerConnected || this.isTransducerPairing || this.isTransducerLicenseExpired);
    }

    get treatmentOnText() {
        if (this.isTransducerLicenseExpired) {
            if (this.isMobile) {
                return "device_status_license_tag_expired_short";
            }
            return "device_status_license_tag_expired";
        }
        return "device_status_enabled";
    }

    get treatmentErrorText() {
        if (!this.isTransducerConnected) {
            return "device_status_disconnected_sm";
        }
        if (this.isTransducerPairing) {
            return "device_status_pairing";
        }

        if (this.isMobile) {
            return "device_status_license_tag_expired_short";
        }
        return "device_status_license_tag_expired";
    }
}
