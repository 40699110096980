
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import DeviceModel from "@/models/DeviceModel";
import { MODAL_TREATMENT } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class TreatmentModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_TREATMENT
        };
    }

    override process(): Promise<any> {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) {
            return Promise.resolve(false);
        }

        const state = !device.configuration?.content.active;
        return this.$store.dispatch("device/toggle", {
            content: state
        });
    }

}

