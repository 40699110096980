
import { dayToString } from '@/Utils';
import RootView from '@/components/RootView';
import { DeviceLicense } from '@/models/DeviceModel';
import Component from 'vue-class-component';

@Component({

})
export default class DeviceDetailsAdminLicensesView extends RootView {
    dayToString(value: number): string {
        return dayToString(value);
    }
}
