
import { Vue, Prop, Component } from "vue-property-decorator";
import RootModal, { ModalSetup, ModalType } from "@/components/RootModal";
import ErrorList from "@/components/ErrorList.vue";
import { localIsoDateWithoutDaytimeString } from "@/Utils";
import { MODAL_ADMIN_GEN_LIC } from "@/data";
import { toast, ToastType } from "@/Toast";
import DialogPopupModal from "@/components/DialogPopupModal.vue";

@Component({
    components: {
        DialogPopupModal,
        ErrorList
    }
})
export default class AdminGenerateLicenseModalView extends RootModal {

    name = "";
    date = "";
    done = false;
    result = "";

    rules = {
        name: (value: string): any => value.trim().length > 3 || "Name should be minimum more than 3 characters."
    }

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_GEN_LIC,
            type: ModalType.PROCESS_AND_SHOW
        }
    }

    reset() {
        this.done = false;
        this.name = "";
        this.result = "";
        this.date = localIsoDateWithoutDaytimeString(Date.now());
        this.$refs.form.resetValidation();
    }

    onClipboardClicked() {
        navigator.clipboard.writeText(`${this.result}`);
        toast({message: "Copied to clipboard", type: ToastType.TOAST_INFO});
    }
    
    override validations(): Promise<boolean> {
        this.$refs.form.validate();
        if (this.name.trim().length == 0) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    override async process(): Promise<any> {
        this.result = await this.$store.dispatch("admin/generateLicense", {
            name: this.name,
            date: this.date
        });
        this.done = true;
    }
}

