import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import OrganisationModel from "@/models/OrganisationModel";
import { network } from "@/network";
import { authModule } from "..";

import { InternalRole } from "./AuthModule";
import { SearchItemModel } from "@/models/SearchItemModel";
import { wrapSearchItems } from "@/Utils";

const rawJsonToModel = (raws: any[]): OrganisationModel[] => {
    const results: OrganisationModel[] = [];
    for (let i = 0; i < raws.length; i++) {
        results.push({
            org_id: raws[i].pk.replace("ORG#",""),
            name: raws[i].name,
            phone: raws[i].phone,
            website: raws[i].website,
            email: raws[i].email,
            address: raws[i].address
        });
    }
    return results;
}

const shouldUpdateMemberships = (organisations: OrganisationModel[]): boolean => {
    if (!authModule.user) {
        return false;
    } 

    if (organisations.length != authModule.user.memberships.size) {
        return true;
    }

    for (let i = 0; i < organisations.length; i++) {
        if (!authModule.user.memberships.has(organisations[i].org_id)) {
            return true;
        }
    }

    return false;
};

@Module
export default class OrganisationsModule extends VuexModule {

    organisations: OrganisationModel[] = [];
    searchedOrganisations: SearchItemModel<OrganisationModel>[] = [];
    
    // only for organisation view page
    organisation?: OrganisationModel;
    
    @Mutation
    clear() {
        this.organisations = [];
        this.searchedOrganisations = [];
        this.organisation = undefined;
    }

    @Mutation
    setOrganisations(organisations: OrganisationModel[]) {
        this.organisations = organisations;
    }

    @Mutation
    setSearchedOrganisations(organisations: OrganisationModel[]) {
        this.searchedOrganisations = wrapSearchItems(organisations);
    }


    @Mutation
    setOrganisation(organisation: OrganisationModel) {
        this.organisation = organisation;
    }

    @Mutation
    changeOrganisationInfo(data: {organisation: OrganisationModel}) {
        if (!this.organisation) return;
        this.organisation!.name = data.organisation.name;
        this.organisation!.address = data.organisation.address;
        this.organisation!.phone = data.organisation.phone;
        this.organisation!.email = data.organisation.email;
        this.organisation!.website = data.organisation.website;
    }

    @Action
    async fetchOrganisations(payload: {admin: boolean, skip: boolean}) {
        let models: OrganisationModel[] = authModule.user!.organisations!;
        let raws;

        if (payload && payload.admin && authModule.user?.internals.includes(InternalRole.ADMIN)) {
            raws = JSON.parse((await network().get("/d/admin/organisations")).data).body;
        } else {
            raws = JSON.parse((await network().get(`/op/user/organisations`)).data);
        }

        if (raws) {
            if (!Array.isArray(raws)) {
                raws = [raws];
            }
        }

        models = rawJsonToModel(raws);

        if ((!payload || (payload && !payload.skip)) && shouldUpdateMemberships(models)) {
            authModule.updateToken();
        }

        this.setOrganisations(models);
        this.setSearchedOrganisations(models);
        return true;
    }

    /* 
        Organisation management 
    */
    @Action
    async setMainOrganisationById(data: {id: string}) {
        for (let i = 0; i < this.organisations.length; i++) {
            if (this.organisations[i].org_id == data.id) {
                await this.setMainOrganisation({organisation: this.organisations[i]});
                break;
            }
        }
    }

    @Action
    async setMainOrganisation(data: {organisation: OrganisationModel}) {
        if (data.organisation == null) {
            return;
        }
        this.setOrganisation(data.organisation);
    }

    @Action
    async updateOrganisation(data: {organisation: OrganisationModel}) {
        await network().put(`/op/user/organisations/${this.organisation?.org_id}`, JSON.stringify(data.organisation));
        this.changeOrganisationInfo(data);
    }
}   