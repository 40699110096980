
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component({
    components: {}
})
export default class SummaryDetailsLayout extends RootView {
    get sectionClass(): string {
        if (this.isMobile) {
            return "pe-0";
        }
        return "h-100";
    }
} 

