
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import RegisterBar from "@/components/RegisterBar.vue";
import { MODAL_ADMIN_CREATE_EMPTY } from "@/data";
import { toast, ToastType } from "@/Toast";
import DeviceModel, { SimplifiedDevice } from "@/models/DeviceModel";
import { SearchItemModel } from "@/models/SearchItemModel";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        RegisterBar
    }
})
export default class AdminCreateEmptyDevicesModalView extends RootModal {
    ids: SimplifiedDevice[] = [];

    override setup(): ModalSetup {
        return {
          name: MODAL_ADMIN_CREATE_EMPTY
        }
    }

    reset() {
        this.ids = [];
        
        let devices: SearchItemModel<DeviceModel>[] = this.$store.state.admin.searchedDevices;
        for (let i = 0; i < devices.length; i++) {
          if (devices[i].checked) {
            this.ids.push({
              id: devices[i].model.device_id,
              sn: devices[i].model.info?.content.serialnumber,
              label: devices[i].model.local?.content.label
            });
          }
        }
    }

    override async validations(): Promise<boolean> {
        if (this.ids.length == 0) {
          toast({
            message: "Please register a device before proceeding",
            type: ToastType.TOAST_ERROR
          });
          return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    override async process(): Promise<any> {
        return this.$store.dispatch("admin/createEmptyDevices", this.ids);
    }
}
