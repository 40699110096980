
import { Component } from 'vue-property-decorator';
import RootView from './RootView';

@Component
export default class TransducerTag extends RootView {

    get armStatusClass(): string {
        if (!this.chooseDevice == null) {
            return "Unknown";
        }

        if (!this.chooseDevice?.transducer) {
            return "Unknown";
        }
        
        if (!this.chooseDevice.transducer?.content.connected) {
            return "alert-secondary";
        }

        return "alert-info";
    }   

    get armStatusString(): string {
        if (!this.chooseDevice == null) {
            return "Unknown";
        }

        if (!this.chooseDevice?.transducer) {
            return "Unknown";
        }
        
        if (!this.chooseDevice.transducer.content.connected) {
            return "Disconnected";
        }

        if (!this.chooseDevice.transducer?.content.paired) {
            return "Connected - Pairing..";
        }

        return "Connected - Paired";
    }
    
}
