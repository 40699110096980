
import { Vue, Prop, Component, Watch } from "vue-property-decorator";

import Branding from "@/components/Branding.vue";

@Component({
    components: {
        Branding
    }
})
export default class AppLoadingView extends Vue {
    @Prop({default: false}) trigger!: boolean;
    visibility = true;

    @Watch('trigger')
    onChange(oldValue: boolean, newValue: boolean) {
        if (!oldValue && newValue) {
            setTimeout(() => {
                this.visibility = false;
            }, 500)
        }
    }
}
