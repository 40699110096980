

import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import DeviceOptionsDropdown from "@/components/DeviceOptionsDropdown.vue";
import TreatmentTag from "@/components/TreatmentTag.vue";
import TransducerTag from "@/components/TransducerTag.vue";
import TagsElements from "@/components/TagsElements.vue";
import LastUpdateInfo from '@/components/LastUpdateInfo.vue';
import DeviceInfoLicenseSheet from '@/components/DeviceInfoLicenseSheet.vue';
import LicenseBlock from "@/components/LicenseBlock.vue";

import { modal } from "@/Toast";
import { MODAL_TREATMENT, MODAL_STORAGE, MODAL_EDIT_DEVICE_LABEL } from "@/data";

@Component({
    components: {
        DeviceOptionsDropdown,
        TreatmentTag,
        TransducerTag,
        TagsElements,
        LastUpdateInfo,
        DeviceInfoLicenseSheet,
        LicenseBlock
    }
})
export default class DeviceInfoView extends RootView {
    @Prop({default: false}) dashboard!: boolean

    get padding() {
        return this.dashboard ? 'px-5 pt-3 pb-3' : 'p-3';
    }

    onOptionClicked(option: string) {
        switch(option) {
            case "edit":
                modal(MODAL_EDIT_DEVICE_LABEL);
                break;
            case "toggle":
                modal(MODAL_TREATMENT);
                break;
            case "storage":
                modal(MODAL_STORAGE);
                break;
            default:
                break;
        }
    }
} 
