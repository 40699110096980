
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_ADMIN_UPDATE_COORDINATES } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
  components: {
    DialogPopupModal
  }
})
export default class AdminUpdateCoordinatesModalView extends RootModal {
    desiredCoordinateLatitude = "";
    desiredCoordinateLongitude = "";

    override setup(): ModalSetup {
      return {
        name: MODAL_ADMIN_UPDATE_COORDINATES
      }
    }

    override process(): Promise<any> {
      return this.$store.dispatch("admin/updateCoordinates", {options: {coordinates: {
        latitude: this.desiredCoordinateLatitude,
        longitude: this.desiredCoordinateLongitude
      }}, selected: this.selectedDevices});
    }
}
