import DeviceModel from "@/models/DeviceModel";
import { SearchItemModel } from "@/models/SearchItemModel";
import { searchDevices } from "@/search/AdminDeviceSearch";
import { wrapSearchItems } from "@/Utils";
import { SYSTEM_TAG_IGNORE_LICENSE } from "@/data";
import { VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import { adminModule } from "..";
import OrganisationModel from "@/models/OrganisationModel";

@Module
export default class AdminExpiredLicensesModule extends VuexModule {

    searchedDevices: SearchItemModel<DeviceModel>[] = [];
    searchedOrganisation?: OrganisationModel;
    showIgnored = false;

    @Mutation
    setSearchedOrganisations(organisation: OrganisationModel) {
        this.searchedOrganisation = organisation;
    }
    
    @Mutation 
    setSearchedDevices(devices: SearchItemModel<DeviceModel>[]) {
        this.searchedDevices = devices;
    }

    @Mutation
    setSearchedDevicesChecked(value: boolean) {
        for (let i = 0; i < this.searchedDevices.length; i++) {
            this.searchedDevices[i].checked = value
        }
    }
    
    @Action
    load() {
        const result = searchDevices(
            adminModule.devices, {
                label: "",
                sn: "",
                imei: "",
                tags: [],
                orgs: this.searchedOrganisation?.org_id ?? "ALL",
                transducer_disconnected: false,
                license_expired: true,
                last_update_type: "ALL",
                fw: "",
                treatment_type: "ALL",
                ignore: !this.showIgnored ? [SYSTEM_TAG_IGNORE_LICENSE] : []
            }
        );

        result.sort((a: DeviceModel, b: DeviceModel): number => {
            if (a.ts > b.ts) return -1;
            return 1;
        })

        const data = wrapSearchItems(result);
        this.setSearchedDevices(data);
        return data;
    }
}