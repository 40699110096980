
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import { MODAL_STORAGE_REMOVE } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class StorageRemoveModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_STORAGE_REMOVE
        };
    }

    override process(): Promise<any> {
        const state = (!(this.inStorage ?? true));
        return this.$store.dispatch("device/updateLocal", {
            type: "storage",
            content: state 
        });
    }
}

