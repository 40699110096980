
import { Vue, Component, Prop } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import RootModal, { ModalSetup } from "@/components/RootModal";
import { MODAL_STORAGE } from "@/data";

@Component({
    components: {
        DialogPopupModal
    }
})
export default class StorageModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_STORAGE
        };
    }

    override process(): Promise<any> {
        const state = (!(this.inStorage ?? false));
        return this.$store.dispatch("device/updateLocal", {
            type: "storage",
            content: state 
        });
    }
}
