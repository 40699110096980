
import { Component, Vue } from "vue-property-decorator";

import LoginBox from "@/components/LoginBox.vue";
import BoxInfo from "@/components/BoxInfo.vue";
import LanguageChooser from "@/components/LanguageChooser.vue";
import ErrorList from "@/components/ErrorList.vue";
import PasswordField from "@/components/PasswordField.vue";
import RootView from "@/components/RootView";

import { validatePassword } from "@/Utils";

@Component({
    components: {
        LoginBox,
        BoxInfo,
        LanguageChooser,
        ErrorList,
        PasswordField
    }
})
export default class ResetPasswordView extends RootView {
    STATE_INIT = "init";
    STATE_END = "end";
    
    state = this.STATE_INIT;
    errors: string[] = [];
    password = "";
    passwordConfirm = "";

    mounted() {
        this.state = this.STATE_INIT;
        this.errors = [];
        this.password = "";
        this.passwordConfirm = "";
    }

    confirm() {
        this.errors = validatePassword(this.password, this.passwordConfirm);

        if (this.errors.length > 0) {
            return;
        }

        this.$store.dispatch("auth/resetPassword", {
            token: this.$route.params["token"],
            password: this.password.trim()
        }).then(() => {
            this.state = this.STATE_END;
        }).catch(() => {
            this.errors = ["password_change_fail"]
        });
    }

    cancel() {
        this.goto({path: "/login"});
    }

}

