
import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class AdminListLayout extends RootView {
    @Prop({default: () => []}) headers?: string[]
    @Prop({default: true}) showHeader?: boolean;
    @Prop({default: "justify-content-center" }) alignHeader?: string;
}
