
import { Component, Prop } from "vue-property-decorator";
import RootView from "@/components/RootView"
import TimestampString from "@/components/TimestampString.vue";

@Component({
    components: {
        TimestampString,
    }
})
export default class LicenseTag extends RootView {
    @Prop({default: false}) noBreak!: boolean;

    get licenseExpiryClass(): string {
        if (this.showTextLicenseRequest) {
            return "alert-warning";
        }

        return "alert-danger";
    }

    get showTextLicenseRequest(): boolean {
        return this.chooseDevice != null && this.chooseDevice.licenserequest && !this.licenseRequestAboutToExpire;
    }

    get licenseRequestAboutToExpire(): boolean {
        if (this.chooseDevice == null) return false;
        return this.chooseDevice.license && 
            this.chooseDevice.licenserequest && 
            this.chooseDevice.licenserequest.content.expiry <= Date.now();
    }

}
