

import { Component, Vue } from 'vue-property-decorator';

import { Tab } from "@/Utils";
import {toast, ToastType} from "@/Toast";
import RootView from "@/components/RootView";

import DeviceDetailsAdminTimestampsView from '@/views/DeviceDetailsAdminTimestampsView.vue';
import DeviceDetailsAdminLicensesView from '@/views/DeviceDetailsAdminLicensesView.vue';
import DeviceDetailsAdminJobsView from '@/views/DeviceDetailsAdminJobsView.vue';
import DeviceDetailsAdminEventsView from '@/views/DeviceDetailsAdminEventsView.vue';
import DeviceInfoView from "@/views/DeviceInfoView.vue";

import DeviceMap from "@/components/DeviceMap.vue";
import BackButton from "@/components/BackButton.vue";
import TreatmentButton from "@/components/TreatmentButton.vue";
import AdminDeviceCommands from '@/components/AdminDeviceCommands.vue';
import PopupModal from "@/components/PopupModal.vue";
import TabBar from '@/components/TabBar.vue';
import StorageButton from '@/components/StorageButton.vue';
import { DeviceSelected } from '@/models/DeviceSelection';

@Component({
    components: {
        DeviceMap,
        BackButton,
        TreatmentButton,
        AdminDeviceCommands,
        PopupModal,
        DeviceDetailsAdminLicensesView,
        DeviceDetailsAdminTimestampsView,
        DeviceDetailsAdminJobsView,
        DeviceDetailsAdminEventsView,
        DeviceInfoView,
        TabBar,
        StorageButton
    }
})
export default class DeviceDetailsView extends RootView {
    
    
    currentAdminTab = "timestamps";

    $refs!: {
        "updates": HTMLElement
    }

    get tabs(): Tab[] {
        return [{
            id: 'timestamps',
            label: 'Timestamps'
        }, {
            id: "licenses",
            label: 'Licenses'
        }, {
            id: "jobs",
            label: "Jobs"
        }, {
            id: "events",
            label: "Events"
        }];
    }

    onCompleteAdminCommand(device: DeviceSelected[]) {
        if (device[0].success == undefined || (device[0].success != undefined && device[0].success)) {
            toast({
                message: "Operation done with success.",
                type: ToastType.TOAST_SUCCESS
            });
        } else {
            toast({
                message: "Failed operation.",
                type: ToastType.TOAST_ERROR
            })
        }
    }

    scrollToUpdates() {
        this.$refs.updates.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    onTabChanged(id: string) {
        this.currentAdminTab = id;
    }

}
