import { VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import { fileUpload, network } from "@/network";
import FirmwareModel, { FirmwareUploadType } from "@/models/FirmwareModel";
import { FileUpload, fileReader, getExtendedVersionNumber, wrapSearchItems  } from "@/Utils";
import { SearchItemModel } from "@/models/SearchItemModel";
import { searchFirmwares } from "@/search/FirmwareSearch";

const fileKey = (type: string): string => {
    switch(type) {
        case "elf":
            return "zephyr.elf";
        case "hex": 
            return "merged.hex"
        default:
        case "bin":
            return "app_update.bin";
    }
}

const fileTypeToUploadType = (type: string): FirmwareUploadType => {
    switch (type) {
        case "elf":
            return FirmwareUploadType.ELF;
        case "hex":
            return FirmwareUploadType.HEX;
        default:
            return FirmwareUploadType.BIN;
    }
}

export const sortFirmwares = (data: FirmwareModel[] | FirmwareModel): FirmwareModel[] => {

    let result: FirmwareModel[];
    if (!Array.isArray(data)) {
        result = [data];
        return result;
    } 

    result = data;
    result.sort((a: FirmwareModel, b: FirmwareModel) => {
        if (getExtendedVersionNumber(a.version) > getExtendedVersionNumber(b.version)) {
            return -1;
        }
        return 1;
    });

    let latestIndex = 0;
    let found = false;
    let latestFw: FirmwareModel | undefined = undefined;
    for (let i = 0; i < result.length && !found; i++) {
        if (result[i].latest != undefined && result[i].latest) {
            found = true;
            latestFw = result[i];
            latestIndex = i;
        }
    }

    if (latestIndex > 0 && found && latestFw) {
        const firstHalf = result.slice(0, latestIndex - 1);
        const secondHalf = result.slice(latestIndex + 1);
        result = [
            latestFw,
            ...firstHalf,
            ...secondHalf
        ];
    }

    return result;
}

@Module
export default class FirmwareModule extends VuexModule {

    firmwares: FirmwareModel[] = [];
    searchedFirmwares: SearchItemModel<FirmwareModel>[] = [];

    searchedFWVersion = "";
    searchedFWName = "";

    @Mutation
    clear() {
        this.firmwares = [];
        this.searchedFirmwares = [];
        this.searchedFWVersion = "";
        this.searchedFWName = "";
    }

    @Mutation
    setFirmwares(firmwares: FirmwareModel[]) {
        this.firmwares = firmwares;
    }

    @Mutation
    addFirmware(firmware: FirmwareModel) {
        this.firmwares.push(firmware);
    }

    @Mutation
    setSearchedFirmwares(firmwares: SearchItemModel<FirmwareModel>[]) {
        this.searchedFirmwares = firmwares;
    }

    @Mutation
    updateFirmware(firmware: FirmwareModel) {
        for (let i = 0; i < this.firmwares.length; i++) {
            if (this.firmwares[i].firmware_id == firmware.firmware_id) {
                this.firmwares[i].description = firmware.description,
                this.firmwares[i].name = firmware.name,
                this.firmwares[i].version = firmware.version;
                break;
            }
        }
    }

    @Mutation
    mutateLatest(version: string) {
        for (let i = 0; i < this.firmwares.length; i++) {
            if (this.firmwares[i].version == version) {
                this.firmwares[i].latest = true;
                return;
            } else if (this.firmwares[i].latest != undefined && this.firmwares[i].latest) {
                this.firmwares[i].latest = false;
            }
        }
        this.setSearchedFirmwares(wrapSearchItems(searchFirmwares(sortFirmwares(this.firmwares), {
            name: this.searchedFWName,
            version: this.searchedFWVersion
        })));
    }

    @Action 
    async setLatest(firmware: FirmwareModel) {
        await network().patch(`/op/admin/firmwares/${firmware.firmware_id}`);
        this.mutateLatest(firmware.version);
    }

    @Action
    async fetchFirmwares() {
        const data: any =  JSON.parse((await network().get("/d/admin/firmwares")).data).body;
        this.setFirmwares(sortFirmwares(data));
        this.setSearchedFirmwares(wrapSearchItems(this.firmwares));
    }

    @Action 
    async search() {
        this.setSearchedFirmwares(wrapSearchItems(searchFirmwares(this.firmwares, {
            name: this.searchedFWName,
            version: this.searchedFWVersion
        })));
    }

    @Action
    async createFirmware(payload: {latest: boolean, version: string, name: string, description: string, files: FileUpload[]}) {
        const firmware: FirmwareModel = {
            firmware_id: "",
            name: payload.name,
            path: "",
            files: [], 
            release: Date.now(),
            version: payload.version,
            description: payload.description,
        };

        try {
            for (let i = 0; i < payload.files.length; i++) {

                const result = await network().post("/op/admin/firmwares/path", JSON.stringify({
                    path: `${payload.version}/${fileKey((payload.files[i].type as any).id)}`,
                }));

                const data = JSON.parse(result.data);
                const fwKey = data.path;
                const type: FirmwareUploadType = fileTypeToUploadType((payload.files[i].type as any).id);
                
                if (type == FirmwareUploadType.BIN) {
                    firmware.path = fwKey;
                }
                
                firmware.files.push({
                    path: fwKey,
                    type: type
                });
    
                const buffer: ArrayBuffer | string | null = await fileReader(payload.files[i].file);
                await fileUpload().put(fwKey, buffer,  {
                        headers: { 'Content-Type': 'application/octet-stream' }
                });
            }
    
            const upload: any = firmware as any;
            upload.latest = payload.latest;
            console.log(upload);
            const result: any = await network().post("/op/admin/firmwares", JSON.stringify(upload));
            const data = JSON.parse(result.data);
            console.log(data);
            firmware.firmware_id = data.id;
            this.addFirmware(firmware);
        } catch (exception) {
            console.log(exception);
            return false;
        }
        
        return true;
    }

}