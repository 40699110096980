
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import Editbar from "@/components/Editbar.vue";
import RootModal, { ModalSetup } from "@/components/RootModal";
import { MODAL_EDIT_DEVICE_LABEL } from "@/data";

@Component({
    components: {
        DialogPopupModal,
        Editbar
    }
})
export default class EditDeviceLabelModalView extends RootModal {
    inputLabel = "";

    override setup(): ModalSetup {
        return {
            name: MODAL_EDIT_DEVICE_LABEL
        };
    }

    onShow() {
        this.inputLabel = this.label ?? "";
    }

    override process(): Promise<any> {
        return this.$store.dispatch("device/updateLocal", {
            type: "label",
            content: this.inputLabel.trim(),
        });
    }
}
