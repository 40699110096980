import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { network } from "@/network"
import UserModel from "@/models/UserModel";
import { authModule } from ".."

@Module
export default class UserModule extends VuexModule {

    user?: UserModel

    @Mutation
    clear() {
        this.user = undefined;
    }
    
    @Mutation
    setUser(user: UserModel) {
        this.user = user;
    }

    @Action
    async getUser() {
        const responseData: any = JSON.parse((await network().get(`/d/user/users/${authModule.user?.id}`)).data);
        this.setUser(responseData.body);
    }

    @Action 
    changePassword(payload: {old_password: string, new_password: string}) {
        return network().put("/op/user/password", JSON.stringify({
            old_password: payload.old_password,
            new_password: payload.new_password,
            access_token: authModule.accessToken
        }));
    }

    @Action
    async edit(data: {phone: string, name: string, website: string}) {
        
        await network().put("/op/user/self", JSON.stringify({
            phone: data.phone,
            name: data.name,
            website: data.website
        }));

        this.setUser({
            // eslint-disable-next-line 
            usr_id: this.user?.usr_id!,
            phone: data.phone,
            name: data.name,
            website: data.website,
            // eslint-disable-next-line
            email: this.user?.email!
        });

        return true;
    }
}