
import { Component, Vue, Prop } from "vue-property-decorator"
import RootView from "@/components/RootView";
import { ToastType, modal, toast } from "@/Toast";
import { MODAL_TREATMENT, MODAL_TREATMENT_CANCEL } from "@/data";

@Component
export default class TreatmentButton extends RootView {
    @Prop({default: false}) disabled?: boolean;
    
    onToggleCancelClick() {
        if (!this.isCloudConnected) {
            toast({
                message: this.$t("toast_admin_cloud_disconnected").toString(),
                type: ToastType.TOAST_ERROR
            });
            return;
        }
        modal(MODAL_TREATMENT_CANCEL);
    }

    onToggleClick() {
        if (!this.isCloudConnected) {
            toast({
                message: this.$t("toast_admin_cloud_disconnected").toString(),
                type: ToastType.TOAST_ERROR
            });
            return;
        }
        modal(MODAL_TREATMENT);
    }   
}
