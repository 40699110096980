
import RootView from '@/components/RootView';
import Component from 'vue-class-component';
import JobStatus from '@/components/JobStatus.vue';
import JobModel from '@/models/JobModel';
import { JobStatusRange, getJobStatusFromStr } from '@/Utils';
import { modal } from '@/Toast';
import { MODAL_ADMIN_CANCEL_JOB } from '@/data';

@Component({
    components: {
        JobStatus,
    }
})
export default class DeviceDetailsAdminJobsView extends RootView {

    getFw(path: string): string {
        return path.substring(0, path.indexOf("/"));
    }

    viewJob(job: JobModel) {
        window.open(`${process.env.VUE_APP_SHADOW_URL}/job/${job.iot_ref}`, "_blank");
    }

    showCancelJobModel(job: JobModel) {
        modal(MODAL_ADMIN_CANCEL_JOB, job);
    }

    canShowCancelButton(job: JobModel): boolean
    {
        const jobStatus: JobStatusRange = getJobStatusFromStr(job.status!);
        let result: boolean;
        switch (jobStatus) {
            case JobStatusRange.QUEUED:
            case JobStatusRange.IN_PROGRESS:
                result = true;
                break;
            default:
                result = false;
                break;
        }

        return result;
    }
}
