
import { Vue, Prop, Component } from "vue-property-decorator";
import TimestampString from "@/components/TimestampString.vue";

@Component({
    components: {
        TimestampString
    }
})
export default class AdminUpdateRow extends Vue {
    @Prop({default: ""}) title!: string; 
    @Prop({default: 0}) dbTimestamp!: number;
    @Prop({default: 0}) shadowTimestamp!: number;
}
