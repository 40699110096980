
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import { MODAL_ADMIN_ASSIGN_ORG } from "@/data";
import RootModal, {ModalSetup} from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        SelectDropdown
    }
})
export default class AdminAssignOrganisationModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_ASSIGN_ORG,
        }
    }

    onAdminAssignOrgModalOpened() {
      this.$refs.assignOrgDropdown.clear();
    }

    override process(): Promise<any> {
        return this.$store.dispatch("admin/assignOrg", {options: {org_id: this.$refs.assignOrgDropdown.value.org_id}, selected: this.selectedDevices});
    }

}
