
import { Component, Vue } from "vue-property-decorator";
import {isStringEmpty} from "@/Utils";
import Editbar from "./Editbar.vue";
import { SimplifiedDevice } from "../models/DeviceModel";
import { isValidImei, isValidSerialNumber } from "../Utils";

@Component({
    components: {
        Editbar
    }
})
export default class RegisterBar extends Vue {
    imei = "";
    sn = "";
    label = "";
    registries: SimplifiedDevice[] = [];
    error = "";
    showError = false;
    keepLabel = false;

    mounted() {
        this.sn = "";
        this.registries = [];
        this.error = "";
        this.showError = false;
    }

    get getImeiLabel() {
        return `Imei (${this.imei.length} / 15)`;
    }
    
    get getErrorStr() {
        switch(this.error) {
            case "format_sn":
                return "Enter a proper serial number (EMF1201-XXXX)";
            case "format_imei":
                return "Enter a valid IMEI (15 decimal number)"
            case "empty":
            default:
                return "Do not enter an empty string.";
        }
    }

    

    onRegisterButtonSubmit() {
        let imei: string = this.imei.trim();
        let label: string = this.label.trim();
        let serialnumber: string = this.sn.trim();

        if (isStringEmpty(serialnumber) || isStringEmpty(imei)) {
            this.error = "empty";
            this.showError = true;
            return;
        }

        if (!isValidSerialNumber(serialnumber)) {
            this.error = "format_sn";
            this.showError = true;
            return;
        }

        if (!isValidImei(imei)) {
            this.error = "format_imei";
            this.showError = true;
            return;
        }

        this.error = "";
        this.showError = false;
        
        let found = false;

        for (let x = 0; x < this.registries.length && !found; x++) {
            if (this.registries[x].id == imei || this.registries[x].sn == serialnumber) {
                found = true;
            }
        }

        if (!found) {
            this.registries.push({
                id: imei,
                sn: serialnumber,
                label: label
            });
            this.$emit('input', this.registries);
        }
        
        this.imei = "";
        this.sn = "";

        if (!this.keepLabel) {
            this.label = "";
        } 
    }

    onDeleteSnClicked(data: {id: string, sn: string}) {
        this.registries = this.registries.filter(e => e.id !== data.id);
        this.$emit('input', this.registries);
    }
}
