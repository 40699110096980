
import { Vue, Prop, Component } from 'vue-property-decorator';
import RootView from "@/components/RootView";
import { modal } from '@/Toast';
import { MODAL_STORAGE, MODAL_STORAGE_REMOVE } from '@/data';
@Component
export default class StorageButton extends RootView {   
    
    showModal() {
        modal(MODAL_STORAGE);
    }

    showModalRemove() {
        modal(MODAL_STORAGE_REMOVE);
    }
}
