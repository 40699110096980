
import { Component } from "vue-property-decorator";
import DialogPopupModal from "@/components/DialogPopupModal.vue";
import ChipSearch from "@/components/ChipSearch.vue";
import { MODAL_ADMIN_UPDATE_TAGS } from "@/data";
import RootModal, { ModalSetup } from "@/components/RootModal";

@Component({
    components: {
        DialogPopupModal,
        ChipSearch
    }
})
export default class AdminUpdateTagsModalView extends RootModal {

    setDeviceTags: string[] = [];
    modalTagSwitch = false;

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_UPDATE_TAGS
        };
    }

    onAdminTagsModalOpened() {
      this.setDeviceTags = [];
      this.modalTagSwitch = true;
    }

    override process(): Promise<any> {
        if (this.isOnDevicePage) {
            return this.$store.dispatch("device/setTags", {options: {interaction: this.modalTagSwitch, tags: this.setDeviceTags}, selected: this.selectedDevices });
        }
        return this.$store.dispatch("admin/setTags", {options: {interaction: this.modalTagSwitch, tags: this.setDeviceTags}, selected: this.selectedDevices });
    }

}
