import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { network } from "@/network"
import DeviceModel, { getDevicePosition, isDisplayedOnMap } from "@/models/DeviceModel"
import { searchDevices } from "@/search/DeviceSearch";
import { SearchItemModel } from "@/models/SearchItemModel";
import { genUUID, wrapSearchItems } from "@/Utils";
import OrganisationModel from "@/models/OrganisationModel";
import { deviceModule } from "..";

const handleTags = (data: any) => {
    if (!data.local || (data.local && !data.local.content)) {
        return data;
    }

    const tags: string =  data.local.content.tags ?? "";
    let tagsData: string[] = [];

    if (tags.trim() != '') {
        tagsData = tags.split(",")
    }

    data.local.content.tags = tagsData;
    return data;
}

const identifyUnit = (data: any) => {
    data.device_id = data.pk.replace("DEV#", "").trim();
    data.org_id = data.sk.replace("ORG#", "").trim();
    return data; 
}

@Module
export default class OrganisationDevicesModule extends VuexModule {

    devices: DeviceModel[] = [];
    loaded = false;

    showInStorage = false;
    searchedDevices: SearchItemModel<DeviceModel>[] = [];

    searchedDevicesMap: DeviceModel[] = [];
    searchedDeviceName = "";
    searchedTreatmentType?: any = "ALL";
    searchedOrganisation?: OrganisationModel;

    @Mutation
    clear() {
        this.loaded = false;
        this.devices = [];
        this.searchedDevices= [];
        this.searchedDeviceName = "";
        this.searchedTreatmentType = "ALL";
        this.searchedOrganisation = undefined;
        this.showInStorage = false;
    }

    @Mutation
    setSearchedTreatmentType(treatmentType: any) {
        this.searchedTreatmentType = treatmentType;
    }

    @Mutation
    setSearchedOrganisations(organisation: OrganisationModel) {
        this.searchedOrganisation = organisation;
    }


    @Action
    async fetchDevicesFromOrganisation(data: {org_id: string}) {
        const responseData: any = JSON.parse((await network().get(`/d/user/organisations/${data.org_id}/devices`)).data);
        const result: DeviceModel[] = [];

        if (!responseData.body) {
            return;
        }

        if (Array.isArray(responseData.body) ) {
            for(let i = 0; i < responseData.body.length; i++) {
                result.push(identifyUnit(handleTags(responseData.body[i])));
            }
        } else {
            result.push(identifyUnit(handleTags(responseData.body)));
        }
        
        this.devices.push(...result);
    }

    @Mutation
    setSearchedDevices(devices: DeviceModel[]) {
        const mapFilteredDevices = [];
        for (let i = 0; i < devices.length; i++) {
            if (isDisplayedOnMap(devices[i], this.showInStorage)) {
                mapFilteredDevices.push(devices[i]);
            }
        }
        this.searchedDevicesMap = mapFilteredDevices;
        this.searchedDevices = wrapSearchItems(devices);
        this.loaded = true;
    }
    
    @Action
    search(): boolean {
        this.setSearchedDevices(
            searchDevices(this.devices, {
                label: this.searchedDeviceName,
                orgs: this.searchedOrganisation?.org_id ?? "ALL",
                treatment_type: this.searchedTreatmentType?.value ?? "ALL"
            }).sort((a: DeviceModel, b: DeviceModel) => {
                const firstInStorage = a.local?.content.storage ?? false;
                const secondInStorage = b.local?.content.storage ?? false;
                if (firstInStorage && !secondInStorage) {
                    return 1;
                }
                if (!firstInStorage && secondInStorage) {
                    return -1;
                }
                
                const firstTs = a.ts;
                const secondTs = b.ts;

                if (firstTs < secondTs) {
                    return 1;
                }
                return -1;
            })
        );


        const device = deviceModule.device;
        if (!device) return false;
        let found = false;
        for (let i = 0; i < this.searchedDevicesMap.length && !found; i++) {
            if (this.searchedDevicesMap[i].device_id == device.device_id) {
                found = true;
            }
        }

        if (!found) {
            deviceModule.setDevice(undefined);
            return false;
        }

        return true;
    }
    
}   