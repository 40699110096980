
import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Checkbox extends Vue {
    @Prop({default: false}) value?: boolean;
    
    get checked(): boolean {
        if (this.value) {
            return this.value!;
        }
        return false;
    }

    set checked(value: any) {
        this.$emit('input', !this.value);
        this.$emit('change', !this.value);
    }
}

