
import RootView from '@/components/RootView';
import AdminUpdateRow from '@/components/AdminUpdateRow.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        AdminUpdateRow
    }
})
export default class DeviceDetailsAdminTimestampsView extends RootView {
}
