
import { Component } from "vue-property-decorator";
import TimestampString from "@/components/TimestampString.vue";
import RootView from "@/components/RootView"

@Component({
    components: {
        TimestampString
    }
})
export default class LastUpdateInfo extends  RootView {}
