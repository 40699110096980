import {Map, PanOptions, LatLngExpression} from "leaflet";

declare module 'leaflet' {
    interface Map {
        panToOffset(latlng: LatLngExpression, offset: number[], options: PanOptions): void;
    }
}

Map.prototype.panToOffset = function (latlng: LatLngExpression, offset: number[], options: PanOptions) {
    const x = this.latLngToContainerPoint(latlng).x - offset[0]
    const y = this.latLngToContainerPoint(latlng).y - offset[1]
    const point = this.containerPointToLatLng([x, y])
    return this.panTo(point, options);
}