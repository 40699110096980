
import {Vue, Component, Prop} from "vue-property-decorator";
import Dropdown from '@/components/Dropdown.vue';
import LangModel from '@/models/LangModel';

@Component({
    components: {
        Dropdown
    }
})
export default class LanguageChooser extends Vue {
   
   @Prop({default: []}) items: LangModel[] | undefined;
   current = "";
   
   changeLanguage(item: LangModel) {
        this.$root.$i18n.locale = item.id;
        this.current = item.tag;
   }

   mounted() {
        this.current = this.items![0].tag;
   }
}
