import { emptyArray, emptyString, falseBoolean, searchItems, specificString } from "@/search/Search";
import DeviceModel from "@/models/DeviceModel";

export interface AdminDeviceSearchFilters {
    label: string,
    sn: string,
    imei: string,
    tags: string[],
    orgs: string,
    license_expired: boolean,
    transducer_disconnected: boolean,
    last_update_type: string,
    treatment_type: string,
    fw: string,
    ignore: string[]
}

export const searchDevices = searchItems<DeviceModel, AdminDeviceSearchFilters>(new Map([
    ["label", {
        default: emptyString,
        validation: (label: string, device: DeviceModel): boolean => {

            if (label.trim() == "") {
                return false;
            }
        
            if (!device.local) {
                return true;
            }
        
            return !device.local.content.label.toLowerCase().includes(label.toLowerCase());
        }
    }],
    ["sn", {
        default: emptyString,
        validation: (serialnumber: string, device: DeviceModel): boolean => {
            return serialnumber.trim() != "" && (!device.info?.content.serialnumber?.toLowerCase().includes(serialnumber.toLowerCase()) ?? true);
        }
    }],
    ["imei", {
        default: emptyString,
        validation: (imei: string, device: DeviceModel): boolean => {
            return imei.trim() != "" && !device.device_id.includes(imei);
        }
    }],
    ["ignore", {
        default: emptyArray,
        validation: (tags: string[], device: DeviceModel): boolean => {
            if (tags.length == 0) return false;
            if (device.local == null) return false;
            return tags.some(value => device.local!.content.tags.includes(value));
        }
    }],
    ["tags", {
        default: emptyArray,
        validation: (tags: string[], device: DeviceModel): boolean => {
            return tags.length > 0 && (device.local == null || (device.local != null && !tags.some(value => device.local!.content.tags.includes(value))));
        }
    }],
    ["orgs", {
        default: specificString("ALL"),
        validation: (org_id: string, device: DeviceModel): boolean => {
            return org_id != "ALL" && device.org_id != org_id;
        }
    }],
    ["license_expired", {
        default: falseBoolean,
        validation:  (licenseExpiredOnly: boolean, device: DeviceModel): boolean => {
            const timestamp: number = Date.now()
            if (!licenseExpiredOnly) {
                return false;
            }
            const licenseExpired: boolean = device.license == undefined || (device.license != null  
                                && timestamp >= device.license.content.expiry);
            if (licenseExpiredOnly && !licenseExpired) return true;        
            if (device.licenserequest === undefined) return false;
            return device.licenserequest.content.expiry >= timestamp;
        }
    }],
    ["transducer_disconnected", {
        default: falseBoolean,
        validation: (transducerDisconnectedOnly: boolean, device: DeviceModel): boolean => {
            return device.transducer != null && transducerDisconnectedOnly && device.transducer?.content.connected;
        }
    }],
    ["last_update_type", {
        default: specificString("ALL"),
        validation: (update_type: string, device: DeviceModel): boolean => {
            const updateType = update_type.toUpperCase().trim();
            const deviceUpdateType = device.src.toUpperCase().trim();
            if (updateType == "ALL") {
                return false;
            }
            return deviceUpdateType != updateType;
        }
    }],
    ["fw", {
        default: emptyString,
        validation: (fw: string, device: DeviceModel): boolean => {
            return fw.trim() != "" && !(device.info && device.info.content.appV.includes(fw));
        }
    }],
    ["treatment_type", {
        default: specificString("ALL"),
        validation: (treatment_type: string, device: DeviceModel): boolean => {
            const treatmentType = treatment_type.toUpperCase();
            if (treatmentType == "ALL") {
                return false;
            }

            if (!device.transducer || !device.configuration) {
                return true;
            }

            switch(treatmentType) {
                case "TREATMENT_ON":
                    return !(device.transducer.content.activated && device.configuration.content.active);
                case "TREATMENT_OFF":
                    return !(!device.transducer.content.activated && !device.configuration.content.active);
                default: 
                    return !((device.transducer.content.activated && !device.configuration.content.active) 
                            || (!device.transducer.content.activated && device.configuration.content.active));
            }
        }
    }
    ]
])); 