
import { Vue, Prop, Component } from "vue-property-decorator";

import AdminListLayout from "@/components/AdminListLayout.vue";
import Searchbar from "@/components/Searchbar.vue";
import PopupModal from "@/components/PopupModal.vue";
import OrganisationModel from "@/models/OrganisationModel";
import PlusButton from "@/components/PlusButton.vue";
import MissingData from "@/components/MissingData.vue";
import ViewButton from "@/components/ViewButton.vue";

import { MODAL_ADMIN_CREATE_ORG } from "@/data";
import { modal } from "@/Toast";
import RootView from "@/components/RootView";

@Component({
    components: {
        AdminListLayout,
        Searchbar,
        PopupModal,
        PlusButton,
        MissingData,
        ViewButton
    }
})
export default class AdminOrganisationsView extends RootView {

    onOrganisationButtonClicked(organisation: OrganisationModel) {
        this.goto({ path: `/organisation/${organisation.org_id}`});
    }

    onCreateButtonClicked() {
        modal(MODAL_ADMIN_CREATE_ORG);
    }

    onLabelSearch() {
        this.$store.dispatch("organisations/search");
    }
}
