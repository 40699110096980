
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

/* MODALS */
import AdminViewFirmwareModalView from '@/views/modals/AdminViewFirmwareModalView.vue';
import AdminSetStorageModeModalView from '@/views/modals/AdminSetStorageModeModalView.vue';
import AdminAssignFirmwareLatestModalView from '@/views/modals/AdminAssignFirmwareLatestModalView.vue';
import AdminAssignOrganisationModalView from '@/views/modals/AdminAssignOrganisationModalView.vue';
import AdminCancelFirmwareJobModalView from '@/views/modals/AdminCancelFirmwareJobModalView.vue';
import AdminCreateEmptyDevicesModalView from '@/views/modals/AdminCreateEmptyDevicesModalView.vue';
import AdminCreateFirmwareModalView from '@/views/modals/AdminCreateFirmwareModalView.vue';
import AdminCreateOrganisationModalView from '@/views/modals/AdminCreateOrganisationModalView.vue';
import AdminDisableModalView from '@/views/modals/AdminDisableModalView.vue';
import AdminOperationSuccessModalView from '@/views/modals/AdminOperationSuccessModalView.vue';
import AdminUpdateCoordinatesModalView from '@/views/modals/AdminUpdateCoordinatesModalView.vue';
import AdminUpdateFirmwareModalView from '@/views/modals/AdminUpdateFirmwareModalView.vue';
import AdminUpdateLicenseModalView from '@/views/modals/AdminUpdateLicenseModalView.vue';
import AdminUpdateTagsModalView from '@/views/modals/AdminUpdateTagsModalView.vue';
import AdminGenerateLicenseModalView from "@/views/modals/AdminGenerateLicenseModalView.vue";
import AdminIgnoreDeviceLicenseModalView from "@/views/modals/AdminIgnoreDeviceLicenseModalView.vue";
import AdminReinstateDeviceLicenseModalView from "@/views/modals/AdminReinstateDeviceLicenseModalView.vue";

import EditPasswordModalView from '@/views/modals/EditPasswordModalView.vue';
import EditProfileModalView from '@/views/modals/EditProfileModalView.vue';
import LogoutModalView from '@/views/modals/LogoutModalView.vue';

import SignupModalView from '@/views/modals/SignupModalView.vue';
import StorageModalView from "@/views/modals/StorageModalView.vue";
import StorageRemoveModalView from '@/views/modals/StorageRemoveModalView.vue';
import TreatmentCancelModalView from '@/views/modals/TreatmentCancelModalView.vue';
import TreatmentModalView from '@/views/modals/TreatmentModalView.vue';
import UserInvitationFailedModalView from '@/views/modals/UserInvitationFailedModalView.vue';
import UserInvitationModalView from '@/views/modals/UserInvitationModalView.vue';
import EditDeviceLabelModalView from '@/views/modals/EditDeviceLabelModalView.vue';

import EditOrganisationUserRoleModalView from '@/views/modals/EditOrganisationUserRoleModalView.vue';
import InviteOrganisationModalView from '@/views/modals/InviteOrganisationModalView.vue';
import EditOrganisationModalView from '@/views/modals/EditOrganisationModalView.vue';
import KickOrganisationUserModalView from '@/views/modals/KickOrganisationUserModalView.vue';

@Component({
    components: {
        AdminViewFirmwareModalView,
        AdminSetStorageModeModalView,
        AdminAssignFirmwareLatestModalView,
        AdminAssignOrganisationModalView,
        AdminCancelFirmwareJobModalView,
        AdminCreateEmptyDevicesModalView,
        AdminCreateFirmwareModalView,
        AdminCreateOrganisationModalView,
        AdminDisableModalView,
        AdminOperationSuccessModalView,
        AdminUpdateCoordinatesModalView,
        AdminUpdateFirmwareModalView,
        AdminUpdateLicenseModalView,
        AdminUpdateTagsModalView,
        AdminGenerateLicenseModalView,
        AdminIgnoreDeviceLicenseModalView,
        AdminReinstateDeviceLicenseModalView,

        EditPasswordModalView,
        EditProfileModalView,
        LogoutModalView,
        EditOrganisationModalView,
        EditOrganisationUserRoleModalView,
        InviteOrganisationModalView,
        SignupModalView,
        StorageModalView,
        StorageRemoveModalView,
        TreatmentCancelModalView,
        TreatmentModalView,
        UserInvitationFailedModalView,
        UserInvitationModalView,
        EditDeviceLabelModalView,
        KickOrganisationUserModalView
    }
})
export default class Modals extends RootView {}
