
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class LicenseBlock extends RootView {

    @Prop({default: true}) inline!: boolean;

    get licenseColor(): string {
        if (this.isTransducerLicenseExpired) {
            return "text-danger";
        } else if (this.isLicenseGoingToExpire) {
            return "text-warning";
        }
        return "text-success";
    }

    get extraPadding() {
        if (!this.inline) {
            return "pe-3";
        }
        return "";
    }
    get isLicenseGoingToExpire() {
        return this.isLicenseAboutToExpire(this.chooseDevice?.license.content);
    }

    get licenseText() {
        if (this.isTransducerLicenseExpired) {
            return this.$t("device_status_license_expired");
        }
        return this.licenseValidityDaysText(this.chooseDevice?.license.content);
    }

    get blockWidth(): string {
        if (this.inline) {
            return "width: 72px; !important";
        }
        return "width: 16px; !important"
    }

    get iconStyle() {
        if (this.inline) {
            return "";
        }
        return "font-size: 20px;"
    }
}
