
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import DeviceInfoView from "@/views/DeviceInfoView.vue";
import TimestampString from "@/components/TimestampString.vue";
import OrganisationSearchDropdown from "@/components/OrganisationSearchDropdown.vue";
import { LastUpdateLengthType } from "@/data";


import DISCONNECT_SIGNAL_ICON from '@/assets/DISCONNECTED-SIGNAL.svg';
import GOOD_SIGNAL_ICON from '@/assets/GOOD-SIGNAL.svg';
import POOR_SIGNAL_ICON from '@/assets/POOR-SIGNAL.svg';
import STORAGE_ICON from '@/assets/STORAGE-SIGNAL.svg';

@Component({
    components: {
        TimestampString,
        DeviceInfoView,
        OrganisationSearchDropdown
    }
})
export default class DeviceSummaryHomeView extends RootView {
    onClose() {
        this.$emit("close")
    }

    get paddingSize() {
      if (this.isMobile) {
        return "p-1";
      }
      return "p-3";
    }

    get timeTextColor(): string {
      const lastUpdateLengthType = this.lastUpdateLengthType(this.chooseDevice);
      switch (lastUpdateLengthType) {
        case LastUpdateLengthType.POOR:
          return "text-warning";
        case LastUpdateLengthType.GOOD:
          return "text-success";
        case LastUpdateLengthType.DISCONNECTED:
          return "text-danger";
        case LastUpdateLengthType.STORAGE:
        case LastUpdateLengthType.NONE:
        default:
          return "text-secondary";
      }
    }
    get displayIcon() {
      const lastUpdateLengthType = this.lastUpdateLengthType(this.chooseDevice);
      switch (lastUpdateLengthType) {
        case LastUpdateLengthType.POOR:
          return POOR_SIGNAL_ICON;
        case LastUpdateLengthType.GOOD:
          return GOOD_SIGNAL_ICON
        case LastUpdateLengthType.DISCONNECTED:
          return DISCONNECT_SIGNAL_ICON
        case LastUpdateLengthType.STORAGE:
        case LastUpdateLengthType.NONE:
        default:
          return STORAGE_ICON;
      }
    }
}
