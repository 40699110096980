
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LoadingBox extends Vue {
    @Prop({default: 156}) size!: number;

    get width(): string {
        return `${this.size}px`;
    }
}
