import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import HomeView from '@/views/HomeView.vue';
import ProfileView from '@/views/ProfileView.vue';
import OrganisationDetailsView from '@/views/OrganisationDetailsView.vue';
import FAQView from '@/views/FAQView.vue';
import LoginView from '@/views/LoginView.vue';
import DeviceDetailsView from "@/views/DeviceDetailsView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import AdminView from "@/views/AdminView.vue";
import AdminFirmwareView from "@/views/AdminFirmwareView.vue";
import AdminOrganisationsView from "@/views/AdminOrganisationsView.vue";
import AdminExpiredLicenseView from '@/views/AdminExpiredLicenseView.vue';
import InvitationView from '@/views/InvitationView.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';

Vue.use(VueRouter)

import store, { authModule, organisationModule } from '@/store'
import { InternalRole, UserRole } from '@/store/modules/AuthModule'
import { hideLoading } from '@/Toast'

const skipAuth = (to: any, names: string[]): boolean => {
  for (let i = 0; i < names.length; i++) {
    if (to.name == names[i]) {
      return true;
    }
  }
  return false;
}

const execute = (callback: ((params: any, query: any) => Promise<MiddlewareResponse>) | undefined = undefined, to:any, next: any) => {
  if (callback) {
    // eslint-disable-next-line
    new Promise<MiddlewareResponse>(async (resolve, reject) => {
      const result: MiddlewareResponse = await callback(to.params, to.query);
      resolve(result);
    }).then((data: MiddlewareResponse) => {
      if (data.redirect) {
        next(data.redirect)
      } else {
        next();
      }
    })
  } else {
    next();
  }
}

export interface MiddlewareResponse {
  redirect?: string
}

const middleware = (callback: ((params: any, query: any) => Promise<MiddlewareResponse>) | undefined = undefined): (to: any, from: any, next: any) => any => {
  return (to: any, from: any, next: any) => {
    if (skipAuth(to, ["Login", "Invite"])) {
      execute(callback, to, next);
    } else if (to.name != "Login" && store.getters["auth/isAuthenticated"]) {
      execute(callback, to, next);
    } else {
      store.dispatch("auth/getCurrentUser").then((result: boolean) => {
        if (result) {
          if (to.name == "Entry") {
            if (authModule.user?.internals.includes(InternalRole.ADMIN)) {
              next({path: "/admin"});
            } else {
              next({path: "/dashboard"});
            }
          } else {
            execute(callback, to, next);
          }
        } else {
          hideLoading();
          store.commit("device/clear");
          store.commit("firmwares/clear");
          store.commit("admin/clear");
          store.commit("organisation_devices/clear");
          store.commit("organisation_users/clear");
          store.commit("organisations/clear");

          if (!(router as any).history._startLocation.includes("/invite/")) {
            store.commit("invite/clear");
          }
          
          next({path: "/login"});

        }
      })
    }
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Entry',
    beforeEnter: middleware()
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    beforeEnter: middleware()
  },
  {
    path: '/reset/:token',
    name: "Reset",
    component: ResetPasswordView,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: HomeView,
    beforeEnter: middleware(async (): Promise<MiddlewareResponse> => {

      if ((store.state as any).invite.hasInvitation && !(store.state as any).invite.recentlyDone) {
        await store.dispatch("invite/confirm");
      }
      
      await store.dispatch("organisations/fetchOrganisations")
      const promiseDevices: Promise<any>[] = [];

      store.commit("organisation_devices/clear");

      for (let i = 0; i < organisationModule.organisations.length; i++) {
        promiseDevices.push(store.dispatch("organisation_devices/fetchDevicesFromOrganisation", {
          org_id: organisationModule.organisations[i].org_id
        }));
      }

      Promise.all(promiseDevices).then(() => {
        store.dispatch("organisation_devices/search");
      });

      
      return {}
    })
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    beforeEnter: middleware(async (): Promise<MiddlewareResponse> => {
      await store.dispatch("user/getUser");
      await store.dispatch("organisations/fetchOrganisations");
      return {};
    })
  },
  {
    path: '/organisation/:id',
    name: 'Organisation',
    component: OrganisationDetailsView,
    beforeEnter: middleware(async (params: any) : Promise<MiddlewareResponse> => {
      const orgId = params.id;

      await store.dispatch("organisations/fetchOrganisations", {admin: true});
      await store.dispatch("organisations/setMainOrganisationById", {
        id: orgId
      });
      
      const role: UserRole = (store.state as any).auth.user.memberships.get(orgId);

      if ((role == UserRole.ADMIN || role == UserRole.OWNER) || (store.state as any).auth.user.internals.includes("ADMIN")) {
          store.dispatch("organisation_users/getUsers", {org_id: orgId});
      }

      return {};
    }),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQView,
    beforeEnter: middleware()
  },
  {
    path: "/organisation/:orgId/devices/:deviceId",
    name: "Device",
    component: DeviceDetailsView,
    beforeEnter: middleware(async (params: any): Promise<MiddlewareResponse> => {
      store.commit("device/clear");
      store.dispatch("admin/fetchOrganisations");
      store.dispatch("admin/fetchFirmwares");
      
      await store.dispatch("device/fetchDevice", {
        orgId: params.orgId,
        deviceId: params.deviceId
      });

      if (authModule?.user?.internals.includes(InternalRole.ADMIN)) {
        store.dispatch("device/fetchTimestamps");
        store.dispatch("device/fetchLicenseRequests");
        store.dispatch("device/fetchJobs");
        store.dispatch("device/fetchEvents");
      }
    
      return {};
    })
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    beforeEnter: middleware(async (params: any, query: any): Promise<MiddlewareResponse> => {
       if(! (store.state as any).auth.user.internals.includes("ADMIN")) {
        return {
          redirect: "/dashboard"
        };
       }

       await Promise.all([
        store.dispatch("admin/fetchOrganisations"),
        store.dispatch("admin/fetchDevices"),
        store.dispatch("admin/fetchFirmwares")
       ]);

       await store.dispatch("admin_expired_licenses/load");

       store.commit("admin/setQuery", query);

       return {};
    })
  },
  {
    path: "/admin/firmwares",
    name: "Firmwares",
    component: AdminFirmwareView,
    beforeEnter: middleware(async (params: any): Promise<MiddlewareResponse> => {
      if(! (store.state as any).auth.user.internals.includes("ADMIN")) {
        return {
          redirect: "/dashboard"
        };
       }

       await store.dispatch("firmwares/fetchFirmwares");
       return {};
    })
  },
  {
    path: "/admin/organisations",
    name: "Organisations",
    component: AdminOrganisationsView,
    beforeEnter: middleware(async (params: any): Promise<MiddlewareResponse> => {
      if(! (store.state as any).auth.user.internals.includes("ADMIN")) {
        return {
          redirect: "/"
        };
       }

       await store.dispatch("organisations/fetchOrganisations", {admin: true});
       return {};
    })
  },
  {
    path: "/admin/licenses",
    name: "Licenses",
    component: AdminExpiredLicenseView,
    beforeEnter: middleware(async (params: any): Promise<MiddlewareResponse> => {
      if(! (store.state as any).auth.user.internals.includes("ADMIN")) {
        return {
          redirect: "/dashboard"
        };
       }

       await Promise.all([
        store.dispatch("admin/fetchOrganisations"),
        store.dispatch("admin/fetchDevices"),
        store.dispatch("admin/fetchFirmwares"),
       ]);

       await store.dispatch("admin_expired_licenses/load");

       return {};
    })
  },
  {
    path: "/invite/:token",
    name: "Invite",
    component: InvitationView,
    beforeEnter: middleware(async (params: any): Promise<MiddlewareResponse> => {
      await store.dispatch("auth/getCurrentUser");
      await store.dispatch("invite/check", {token: params.token});
      if (!(store.state as any).invite.hasInvitation || (store.state as any).invite.status == "pending") {
        return {
          redirect: "/dashboard"
        };
      }
      return {};
    })
  },
  {
    path: "*", 
    component: PageNotFoundView,
    beforeEnter: middleware()
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
