

import { Component, Watch } from 'vue-property-decorator';
import L, { LatLngTuple, map } from 'leaflet';
import { promiseWithTimeoutBool } from '@/Utils';

import DeviceModel from '@/models/DeviceModel';
import EmptyOrganisationView from "@/components/EmptyOrganisationView.vue"
import DeviceMap from "@/components/DeviceMap.vue"
import MapPopup from '@/views/MapPopupView';
import RootView from '@/components/RootView';

import DeviceSummaryHomeView  from "@/views/DeviceSummaryHomeView.vue";
import DeviceFooterHomeView from "@/views/DeviceFooterHomeView.vue";
import DeviceSearchHomeView from "@/views/DeviceSearchHomeView.vue";

import { MODAL_USER_INVITATION, MODAL_USER_INVITATION_FAILED } from "@/data";
import { modal } from '@/Toast';

const MOBILE_ZOOM = 15;

@Component({
  components: {
    DeviceSummaryHomeView,
    EmptyOrganisationView,
    DeviceMap,
    DeviceFooterHomeView,
    DeviceSearchHomeView,
  },
})
export default class HomeView extends RootView {
  
    sidebarVisible = false;
    labelSearch = "";
    index = 0;
    popup: L.Popup | undefined = undefined;

    showDevicesBar = true;
    showDeviceSheet = false;
    showDeviceFooter = false;

    $refs!:{
      map: DeviceMap
    }

    closePopup(): Promise<boolean> {
      if (this.popup == undefined) return Promise.resolve(false);
      if (!this.popup.isOpen) return Promise.resolve(false);
      this.popup.close();
      return promiseWithTimeoutBool({
        promise: new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.popup!.on("remove", () => {
              resolve(true);
            });
        })
      });
    }

    mounted() {
      this.$refs.map.setToggleOffset(true);

      if (this.$store.state.invite.hasInvitation) {
        setTimeout(() => {
          if (this.$store.state.invite.recentlyDone) {
            modal(MODAL_USER_INVITATION);
          } else {
            modal(MODAL_USER_INVITATION_FAILED);
          }
        }, 1500);
      }

      window.addEventListener("keydown", (event) => {
        if (event.keyCode == 39) {
          this.onRightTooltipClicked();
        }

        if (event.keyCode == 37) {
          this.onLeftTooltipClicked();
        }
      });
    }

    async onLeftTooltipClicked() {
      if (this.index <= 0) {
        this.index = 0;
        return;
      }
      
      await this.closePopup();
      this.index--;
      this.$store.commit("device/setDevice", this.$store.state.organisation_devices.searchedDevicesMap[this.index]);

      if (this.getPosition(this.chooseDevice!) == null) {
        this.showDeviceFooter = false;
        this.showDeviceSheet = true;
        return;
      }
      
      this.$refs.map.select(this.chooseDevice!);
      await this.$refs.map.moveTo({position: this.getPosition(this.chooseDevice!,), zoom: MOBILE_ZOOM});
      this.showDevicePopup(this.chooseDevice!);
    }

    async onRightTooltipClicked() {
      if (this.index >= this.$store.state.organisation_devices.searchedDevicesMap.length - 1) {
        this.index = this.$store.state.organisation_devices.searchedDevicesMap.length - 1;
        return;
      }
      await this.closePopup();

      this.index++;
      this.$store.commit("device/setDevice", this.$store.state.organisation_devices.searchedDevicesMap[this.index]);

      if (this.getPosition(this.chooseDevice!) == null) {
        this.showDeviceFooter = false;
        this.showDeviceSheet = true;
        return;
      }

      this.$refs.map.select(this.chooseDevice!);
      await this.$refs.map.moveTo({position: this.getPosition(this.chooseDevice!), zoom: MOBILE_ZOOM});
      this.showDevicePopup(this.chooseDevice!);
    }

    mobileHideDevice() {
      this.$store.commit("device/setDevice", undefined);
    }

    toggleDeviceSheet() {
      this.showDeviceSheet = false;

      if (this.getPosition(this.chooseDevice!) != null) {
        this.showDeviceFooter = true;
      } 
    }

    onDeviceDetailsButtonClicked(device: DeviceModel) {
      this.goto({ path: `/organisation/${device.org_id}/devices/${device.device_id}`});
    }

    async onDeviceListItemClicked(payload: {device: DeviceModel, index: number}) {
      const device = payload.device;
      this.index = payload.index;
      event?.stopPropagation();
      this.$store.commit("device/setDevice", device);

      if (!this.isDisplayedOnMap(payload.device)) {
        this.showDeviceFooter = false;
        this.showDeviceSheet = true;
        this.closePopup();
        return;
      }

      this.$refs.map.select(device);
      await this.$refs.map.moveTo({position: this.getPosition(payload.device), zoom: MOBILE_ZOOM});
      this.toggleDeviceFooter();

      if (this.isMobile) {
        return;
      }

      this.showDevicePopup(device);
    }

    hideTooltip() {
      this.closePopup();
      this.$store.commit("device/setDevice", undefined);
      this.showDeviceFooter = false;
      this.index = 0;
      this.$refs.map.clear();
    }

    toggleDeviceFooter() {
      if (!this.showDeviceSheet) {
        this.showDeviceFooter = true;
      }
    }

    async onSearch() {
      this.index = 0;
      await this.$store.dispatch("organisation_devices/search");
      if (!(this.$store.state.organisation_devices.searchedDevices.length > 0)) {
        this.$store.commit("device/setDevice", undefined);
        this.closePopup();
      } 
    }

    async onMarkerClicked(payload: {e: any, device: DeviceModel, index: number, latlng: any}) {
      const device: DeviceModel = payload.device;
      const e: any = payload.e; 
      
      let current: any = undefined;
      for(let i = 0; i < this.$refs.map.$refs.marker.length; i++) {
        current = this.$refs.map.$refs.marker[i];
        if (current.latlng == e.latlng) {
          break;
        }
      }

      if (!current) return;
      current = current.mapObject;
      this.$store.commit("device/setDevice", device);
      this.index = payload.index;

      if (!this.isMobile) {
        this.toggleDeviceFooter();
      }
      
      await this.$refs.map.moveTo({position: this.getPosition(payload.device), zoom: MOBILE_ZOOM});

      if (!this.isMobile) {
        this.showDevicePopup(device);
      }
    }

    @Watch('$store.state.device.device')
    onDeviceChange(device: any) {
      if (!device) {
        this.showDeviceFooter = false;
        this.showDeviceSheet = false;
        this.popup?.close();
      }
    }

    @Watch('$store.state.organisation_devices.loaded')
    onDevicesLoaded(val: boolean, oldVal: boolean) {
      if (oldVal) return;
      this.$refs.map.setCenter(this.$store.state.organisation_devices.devices[0]);
    }

    showDevicePopup(device: DeviceModel) {
      const latLng: LatLngTuple = this.getPosition(device);
      let error: string | undefined = undefined;

      let licenseText = this.licenseValidityDaysText(device.license.content);
      if (this.isTreatmentOnForDevice(device)) {
          if (this.hasLicenseExpired(device.license.content)) {
            error = "licenseexpired";
          } else if (this.isTransducerPairingForDevice(device)) {
            error = "pairing";
          } else if (!this.isTransducerConnectedForDevice(device)) {
            error = "disconnected";
          }
      }

      const license_success = "filter: invert(35%) sepia(93%) saturate(403%) hue-rotate(100deg) brightness(97%) contrast(85%);";
      const license_warning = "filter: invert(75%) sepia(27%) saturate(2314%) hue-rotate(352deg) brightness(106%) contrast(104%);";
      const license_danger = "filter: invert(31%) sepia(98%) saturate(1233%) hue-rotate(327deg) brightness(86%) contrast(102%);";

      let iconLicenseColor = license_success;
      if (this.hasLicenseExpired(device.license.content)) {
        iconLicenseColor = license_danger;
        licenseText = this.$t("device_status_license_expired").toString();
      } else if (this.isLicenseAboutToExpire(device.license.content)) {
        iconLicenseColor = license_warning;
      }

      this.popup = L.popup({
        maxWidth: 500,
        minWidth: 300,
        maxHeight: 500,
      })
        .setLatLng(latLng)
        .setContent(MapPopup({
          device_id: device.device_id,
          serialnumber: device.info.content.serialnumber,
          temperature: device.transducer.content.temperature,
          battery: this.batteryText(device.transducer.content.battery),
          buoybattery: this.batteryText(device.sensors.content.battery),
          lastUpdateLengthType: this.lastUpdateLengthType(device),
          active: device.transducer?.content.activated ?? false,
          treatmentErrors: error,
          inTransition: this.isTreatmentInTransitionForDevice(device),
          toward: this.treatmentDesiredStateForDevice(device),
          connected: device.transducer?.content.connected ?? false,
          paired: device.transducer?.content.paired ?? false,
          hasLicenseUpdatePending: this.hasLicenseUpdatePendingForDevice(device),
          licenseAboutToExpire: this.isLicenseAboutToExpire(device.license.content),
          licenseExpired: this.hasLicenseExpired(device.license.content),
          position: {
            latitude: latLng[0],
            longitude: latLng[1]
          },
          label: device.local?.content.label ?? "",
          tags: device.local?.content.tags ?? [],
          inWater: device.transducer.content.inWater,
          inStorage: device.local?.content.storage ?? false, 
          iconLicenseColor: iconLicenseColor,
          strings: {
            "last_update_text": `${this.$t("ago", [this.lastUpdateAgoText(device.ts ?? 0)])} (${this.$t(`update_src_${device.src.toLowerCase()}`)})`,
            "in_storage": this.$t("device_status_storage").toString(),
            "status_on": this.$t("device_status_on").toString(),
            "status_off": this.$t("device_status_off").toString(),
            "disconnected": this.$t("device_status_disconnected_sm").toString(),
            "pairing": this.$t("device_status_pairing").toString(), 
            "no_label": this.$t("no_label").toString(),
            "temperature": this.$t("details_summary_temperature").toString(),
            "battery": this.$t("details_summary_transducerbattery").toString(),
            "buoybattery": this.$t("details_summary_buoybattery").toString(),
            "activation": this.$t("details_summary_enabled").toString(),
            "activation_on": this.$t("details_summary_enabled_true").toString(),
            "activation_off": this.$t("details_summary_enabled_false").toString(),
            "treatment_on": this.$t("device_status_enabled").toString().toUpperCase(),
            "treatment_on_sm": this.$t("device_status_on").toString().toUpperCase(),
            "treatment_off": this.$t("device_status_offline").toString().toUpperCase(),
            "treatment_transition_on": this.$t("device_status_transition_on").toString().toUpperCase(),
            "treatment_transition_off": this.$t("device_status_transition_off").toString().toUpperCase(),
            "unavailable": this.$t("details_actions_unavailable_btn").toString(),
            "details_btn": this.$t("map_buttons_details").toString(),
            "error_treatment_licenseexpired": this.$t("device_status_license_tag_expired").toString().toUpperCase(),
            "error_treatment_pairing": this.$t("device_status_pairing").toString().toUpperCase(),
            "error_treatment_disconnected": this.$t("device_status_disconnected_sm").toString().toUpperCase(),
            "license_text": licenseText,
            "new_license_ready": this.$t("device_status_license_new_ready").toString(),
          }
        }));

        this.popup.openOn(this.$refs.map.$refs.map.mapObject);

        setTimeout(() => {
          document.getElementById("popup-grow")?.addEventListener("click", (e: any) => {
            this.showDeviceFooter = false;
            this.$store.commit("device/setDevice", device);
            this.showDeviceSheet = true;
          })
        }, 500);
       
    }

    @Watch("showDevicesBar")
    onDeviceBarChanged(val: boolean) {
      if (!this.$refs.map) return;
      if (!this.$refs.map.$refs.map) return;
      this.$refs.map.setToggleOffset(val);
    }

    onShowInStorageUpdate() {
      this.$store.dispatch("organisation_devices/search");
    }

    get hasNoDeviceInStorage() {
      if (!this.$store.state.device.device) return true;
      if (!this.$store.state.device.device.local) return true;
      return !this.$store.state.device.device.local.content.storage;
    }

}
